import React, { Component } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { SaluteModal } from '../../stateless_components/modals/SaluteModal';
import { ViolationForm } from '../Violation/ViolationForm';
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';
import { fontSize } from '../../Style/typography';
import { colors } from '../../Style/colors';
import { API } from '../../services/api';

const t = key => I18n.t(`inspections.inspections.form.${key}`);

const StyledEditButton = styled.button`
  cursor: pointer;
  &:hover {
    color: ${colors.grayDark} !important;
    background-color: ${colors.grayBg} !important;
  }
`;

class ViolationBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      newViolationData: undefined,
      violationData: undefined,
      findingsFetched: false,
    };
    this.modalRef = React.createRef();
  }

  openModalWithNewViolationData = newViolationData => {
    this.setState({ newViolationData, modalOpen: true });
  };

  openModal = () => {
    if (this.props.newViolation) {
      this.setState({ modalOpen: true });
    } else {
      this.fetchFindingsAndOpenModal();
    }
  };

  closeModal = () => {
    this.setState({ newViolationData: undefined, modalOpen: false });
  };

  scrollToModalTop = () => {
    this.modalRef.current.scrollTop();
  };

  onViolationChange = (newViolationData = undefined) => {
    this.setState({ ...this.state, newViolationData: undefined, modalOpen: false }, () => {
      setTimeout(() => this.props.refreshList(newViolationData), 500);
    });
  };

  fetchFindingsAndOpenModal = () => {
    API.findings.index({ violation_id: this.props.violation.id }).then(response => {
      const { ok, data } = response;
      if (ok) {
        const attrsWhitelist = ['id', 'status', 'regulatory_description'];
        const findingsAttributes = data.data.map(findingData => {
          const newFinding = {};
          attrsWhitelist.forEach(key => (newFinding[key] = findingData.attributes[key]));
          newFinding.creator_id = this.props.current_user.id;
          return newFinding;
        });

        this.setState({
          modalOpen: true,
          findingsFetched: true,
          violationData: { ...this.props.violation, inspection_findings_attributes: findingsAttributes },
        });
      }
    });
  };

  renderViolationModal = () => {
    const { newViolation, values } = this.props;
    const { modalOpen, newViolationData, violationData } = this.state;
    return (
      <SaluteModal
        isOpen={modalOpen}
        onCloseClick={this.closeModal}
        ref={this.modalRef}
        title={t(`modals.violation.${newViolation ? 'add.title' : 'edit.title'}`)}
        wrapperStyle={{ maxWidth: '1000px' }}
      >
        <ViolationForm
          {...this.props}
          inspection={values}
          closeModal={this.closeModal}
          onViolationChange={this.onViolationChange}
          onValidationError={this.scrollToModalTop}
          violation={newViolation ? newViolationData : violationData}
        />
      </SaluteModal>
    );
  };

  renderAddFirstViolationButton = () => (
    <PrimaryButton
      onPress={() => {
        this.openModal();
      }}
    >
      {t('buttons.add_new_violation')}
    </PrimaryButton>
  );

  renderAddViolationButton = () => (
    <ButtonLink
      fontSizeVariant="textSm"
      onClick={() => {
        this.openModal();
      }}
    >
      {t('buttons.add_new_violation')}
    </ButtonLink>
  );

  renderEditViolationButton = () => (
    <StyledEditButton
      type="button"
      className="btn mr-3"
      style={{ fontSize: fontSize.textSm }}
      onClick={() => {
        this.openModal();
      }}
    >
      <span className="saluteicons saluteicons-pencil" style={{ marginRight: '3px' }} />
      {t('buttons.edit_violation')}
    </StyledEditButton>
  );

  render() {
    const { newViolation, isFirstViolation } = this.props;
    const { findingsFetched } = this.state;
    return (
      <>
        {newViolation && isFirstViolation && this.renderAddFirstViolationButton()}
        {newViolation && !isFirstViolation && this.renderAddViolationButton()}
        {!newViolation && this.renderEditViolationButton()}
        {(newViolation || findingsFetched) && this.renderViolationModal()}
      </>
    );
  }
}

export default ViolationBtn;
