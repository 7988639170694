/** Style */
import styled from 'styled-components';
import { fontSize } from '../../../../Style/typography';
import { colors } from '../../../../Style/colors';
import { breakpoints, breakpointsForMaxWidth } from '../../../../salute_portal/assets/styles/grid';

export const FilesFieldContainer = styled.div`
  width: 100%;
`;

export const StyledAttachmentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  padding-bottom: ${props => (props.descriptionIsPresent ? '3px' : '10px')};
  width: 100%;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledAttachmentNameSpan = styled.span`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  padding-right: 20px;
  font-weight: bold;
`;

export const DescriptionLinkButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  color: ${colors.download};
  cursor: pointer;
  min-width: 100px;
  height: 30px;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    text-align: right;
  }
`;

export const StyledAttachmentLink = styled.a`
  color: ${colors.download};
  min-width: 80px;
  margin-left: 10px;
  height: 30px;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledRemoveAttachmentButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  margin-left: 15px;
  color: ${colors.delete};
  cursor: pointer;
  height: 30px;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledAttachmentDescriptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  padding-bottom: 10px;
  width: 100%;
`;

export const StyledAttachmentsTable = styled.div`
  font-size: ${fontSize.textXsm};
  flex-direction: column;
  width: 100%;
`;

export const StyledMockUploadButton = styled.div`
  padding: 0.25rem 1rem;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  &:focus,
  &:hover {
    background: ${colors.inputBorderColor};
  }
`;

export const StyledMockUploadButtonTitle = styled.span`
  padding-left: 10px;
`;

export const Row = styled.div`
  padding: 0;
`;

export const Label = styled.label.attrs({ htmlFor: props => props.inputId })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  @media only screen and (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    text-align: right;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

export const Required = styled.span`
  margin-left: 4px;
  color: ${colors.required};
`;

export const HintWrapper = styled.span`
  margin-left: 4px;
`;

export const Error = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.error};
`;
