import {
  wasteManifestItemsListActionCreators,
  wasteManifestCollectionCoveredListActionCreators,
  wasteManifestCollectionNotCoveredListActionCreators,
} from '../../redux/views/waste_management/wasteManifestFormRedux';
import {
  wasteManifestItemsActionCreators,
  wasteManifestCollectionActionCreators,
} from '../../redux/entities/wasteManifestItemsRedux';
import { modalSetupActionCreators } from '../../redux/views/Modals/setupRedux';
import { confirmationGuardSaga } from '../modals/confirmationGuardSaga';
import { call, put, all } from 'redux-saga/effects';

const toFlatRecordsCollection = item => item.attributes;

export function* fetchWasteManifestItemsAndCollectionCovered(api, action) {
  yield put(wasteManifestItemsListActionCreators.setPending());
  yield put(wasteManifestCollectionCoveredListActionCreators.setPending());
  const [wasteManifestItemsResponse, wasteManifestCollectionCoveredResponse] = yield all([
    call(api.wasteManagement.wasteManifestItems.index, { waste_manifest_id: action.payload.id }),
    call(api.wasteManagement.wasteCollection.index, { for_manifest: action.payload.id }),
  ]);
  yield put(wasteManifestItemsListActionCreators.clearPending());
  yield put(wasteManifestCollectionCoveredListActionCreators.clearPending());

  if (wasteManifestItemsResponse.ok && wasteManifestCollectionCoveredResponse.ok) {
    /** Save waste manifest line items associated with current manifest */
    const wasteManifestItemsResponseData = wasteManifestItemsResponse.data.map(
      toFlatRecordsCollection,
    );
    yield put(wasteManifestItemsActionCreators.save(wasteManifestItemsResponseData));
    yield put(wasteManifestItemsListActionCreators.add(wasteManifestItemsResponseData));
    /** Save waste chemical items aka waste collection associated with current manifest */
    const wasteManifestCollectionCoveredResponseData = wasteManifestCollectionCoveredResponse.data.data.map(
      toFlatRecordsCollection,
    );
    yield put(
      wasteManifestCollectionActionCreators.save(wasteManifestCollectionCoveredResponseData),
    );
    yield put(
      wasteManifestCollectionCoveredListActionCreators.add(
        wasteManifestCollectionCoveredResponseData,
      ),
    );
  } else {
    flashMessage('Unexpected error occurred, fetching the data failed.', true, 'error');
  }
}

export function* destroyWasteManifestLineItem(api, action) {
  const { confirmationMessage, implicationsMessage } = action.payload;
  const confirmed = yield call(confirmationGuardSaga, confirmationMessage, implicationsMessage);
  if (!confirmed) return;

  const { id } = action.payload;
  yield put(wasteManifestItemsListActionCreators.addItemsCurrentlyBeingRemoved(id));
  const response = yield call(api.wasteManagement.wasteManifestItems.destroy, action.payload);
  yield put(wasteManifestItemsListActionCreators.removeItemsCurrentlyBeingRemoved(id));

  if (response.ok) {
    yield put(wasteManifestItemsListActionCreators.remove(response.data));
    yield put(wasteManifestItemsActionCreators.destroy(response.data));
  } else {
    flashMessage('Failed to unlink waste manifest line item.', true, 'error');
  }
}

export function* wasteCollectionNotCoveredIndexRequest(api, action) {
  const { page } = action.payload.requestParams;
  if (page) {
    yield put(wasteManifestCollectionNotCoveredListActionCreators.changePage(page));
  }

  yield put(wasteManifestCollectionNotCoveredListActionCreators.setPending());
  const params = { not_covered: true, ...action.payload.requestParams };
  const response = yield call(api.wasteManagement.wasteCollection.index, params);
  yield put(wasteManifestCollectionNotCoveredListActionCreators.clearPending());

  if (response.ok) {
    const {
      data: { data, total_pages },
    } = response;
    const records = data.map(toFlatRecordsCollection);
    yield put(wasteManifestCollectionActionCreators.save(records));
    yield put(wasteManifestCollectionNotCoveredListActionCreators.overwrite(records, total_pages));
  } else {
    flashMessage('Unexpected error occurred, fetching the data failed.', true, 'error');
  }
}

export function* assignWasteCollectionToCurrentWasteManifest(api, action) {
  const { wasteCollectionRecords, manifestId } = action;
  const updateEffects = wasteCollectionRecords.map(id =>
    call(api.wasteManagement.wasteCollection.update, id, { waste_manifest_id: manifestId }),
  );

  yield put(wasteManifestCollectionNotCoveredListActionCreators.setSubmitting());
  const responses = yield all(updateEffects);
  yield put(wasteManifestCollectionNotCoveredListActionCreators.clearSubmitting());

  if (!responses.map(response => response.ok).includes(false)) {
    yield put(wasteManifestCollectionCoveredListActionCreators.add(wasteCollectionRecords));
    yield put(wasteManifestCollectionNotCoveredListActionCreators.remove(wasteCollectionRecords));
  } else {
    if (responses.filter(response => 'waste_generator_errors' in response.data).length > 0) {
      flashMessage(
        'At least one Waste Collection has Waste Generator different than in Waste Manifest.',
        true,
        'error',
      );
    } else if (responses.filter(response => 'date_requested' in response.data).length > 0) {
      flashMessage(
        'At least one Date Requested cannot be later than Date Collected.',
        true,
        'error',
      );
    } else {
      flashMessage(
        'Unexpected error occurred, some of the items might not be assigned.',
        true,
        'error',
      );
    }
  }
  yield put(modalSetupActionCreators.closeModal());
  yield put(wasteManifestCollectionNotCoveredListActionCreators.toggleSelectedItems(null));
}

export function* dropWasteCollectionFromCurrentWasteManifest(api, action) {
  const { confirmationMessage, implicationsMessage, wasteCollectionRecord } = action;
  const confirmed = yield call(confirmationGuardSaga, confirmationMessage, implicationsMessage);
  if (!confirmed) return;

  yield put(
    wasteManifestCollectionCoveredListActionCreators.addItemsCurrentlyBeingRemoved(
      wasteCollectionRecord,
    ),
  );
  const response = yield call(api.wasteManagement.wasteCollection.update, wasteCollectionRecord, {
    waste_manifest_id: null,
  });
  yield put(
    wasteManifestCollectionCoveredListActionCreators.removeItemsCurrentlyBeingRemoved(
      wasteCollectionRecord,
    ),
  );

  if (response.ok) {
    yield put(wasteManifestCollectionNotCoveredListActionCreators.add(response.data));
    yield put(wasteManifestCollectionCoveredListActionCreators.remove(response.data));
  } else {
    flashMessage('Failed to unlink waste collection item.', true, 'error');
  }
}
