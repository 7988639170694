/** Lib */
import 'react-dates/initialize'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker as AirbnbDateRangePicker } from 'react-dates'
/** Style */
import './custom_styles.css';
import { getConvertedMoment, momentFormatter } from '../../../../services/dateFormatUtils';

export class DateRangePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
    }
  }

  handleFocusChanged = (focusedInput) => {
    this.setState({ focusedInput })
    focusedInput === null && this.props.handleBlur && this.handleBlur.onBlur()
  }

  render() {
    const { startDate, endDate, isOutsideRange = () => false } = this.props;
    const startDateValue = getConvertedMoment(startDate, true);
    const endDateValue = getConvertedMoment(endDate, true);

    return (
      <AirbnbDateRangePicker
        startDate={startDateValue}
        endDate={endDateValue}
        startDateId="saluteStartDay"
        endDateId="saluteEndDay"
        block
        appendToBody={false}
        noBorder={true}
        small={true}
        onDatesChange={newValue => {
          const parsedStartDate = newValue.startDate
            ? momentFormatter.serverDateRangePart(newValue.startDate)
            : '';
          const parsedEndDate = newValue.endDate
            ? momentFormatter.serverDateRangePart(newValue.endDate)
            : '';
          const newValueParsed = `${parsedStartDate} - ${parsedEndDate}`;
          this.props.onDatesChange(newValueParsed);
        }}
        focusedInput={this.state.focusedInput}
        onFocusChange={this.handleFocusChanged}
        isOutsideRange={isOutsideRange}
      />
    )
  }
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  isOutsideRange: PropTypes.func,
}

/** @NOTICE
 *  1. DateRangePicker triggers onDatesChange callback when value has been selected (either start or end)
 *  that means that there will be a situation when
 *  there is only startDate selected before user select the endDate of range - it should be properly handled!
 *  2. When using it in  context of a form one must pass handleBlur to indicate when to show possible errors.
 * */
