/** Lib */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/** Style */
import { fontSize } from '../../../../Style/typography'
import { colors } from '../../../../Style/colors'


const DetailsContainer = styled.div`
  border: ${(props) => props.inDropdown ? 0 : `1px solid ${colors.inputBorderColor}`};
  padding: ${(props) => props.inDropdown ? 0 : '10px'};
  border-top: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: transparent;
`
const Detail = styled.p`
  font-size: ${fontSize.textSm};
  margin-bottom: 1px;
  color: ${colors.inputTextColor}
`
export const OptionDetails = ({ inDropdown, details }) => (
  <DetailsContainer inDropdown={inDropdown}>
    {details.map((detail, index) => <Detail key={`detail-${index}`}>{detail.label}: {detail.value}</Detail>)}
  </DetailsContainer>
)

OptionDetails.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  inDropdown: PropTypes.bool,
}
