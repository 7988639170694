import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import ViolationFindingDescription from './ViolationFindingDescription'
import InputLabelHint from './InputLabelHint'

const AddFindingButton = ({ onAddFinding, disabled }) => (
  <button
    id="add-finding-field-button"
    type="button"
    disabled={disabled}
    className="btn btn-sm btn-light"
    onClick={onAddFinding}
  >
    + Add New Finding
  </button>
)
AddFindingButton.propTypes = {
  onAddFinding: PropTypes.func,
}

class ViolationFindingsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { findings: props.findings, disabledFindings: props.disabledFindings }
  }

  handleDescriptionChange = (index, newDescription) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState)
      newState.findings[index].description = newDescription

      return newState
    })
  }

  handleAddFinding = () => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState)
      newState.findings.push({
        findingId: null,
        description: '',
      })

      return newState
    })
  }

  handleRemoveFinding = (index) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState)
      newState.findings.splice(index, 1)

      return newState
    })
  }

  render() {
    const { findings, disabledFindings } = this.state

    return (
      <div id="violation-finding-list" className="row form-group">
        <div className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">
          <label htmlFor="">Findings</label>
          &nbsp;
          <InputLabelHint
            hint="You can add multiple findings covered by this violation.<br>
            1. Provide regulatory descriptions for the findings here.<br>
            2. In the next step, you can supply more information and required corrective actions from the Findings Tab."
          />
        </div>
        <div className="col-lg-6 col-md-8 col-sm-9 col-xs-12">
          {findings.map((finding, index) => (
            <ViolationFindingDescription
              finding={finding}
              disabled={disabledFindings || finding.status === 'finalized'}
              index={index}
              key={index}
              onDescriptionChange={this.handleDescriptionChange}
              onAddFinding={this.handleAddFinding}
              onRemoveFinding={this.handleRemoveFinding}
            />))}
          <div className="row mt-1">
            <div className="col-lg-4 col-sm-9 col-md-8 col-xs-12">
              <AddFindingButton disabled={disabledFindings} onAddFinding={this.handleAddFinding} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ViolationFindingsList.propTypes = {
  findings: PropTypes.array,
  disabledFindings: PropTypes.bool
}

export default ViolationFindingsList
