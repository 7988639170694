import React, { useState } from 'react';
import { TextInput } from '../TextInput';
import { ModalFooter } from '../../../modals/ModalFooter';
import { ButtonLink } from '../../../buttons/ButtonLink';
import { PrimaryButton } from '../../../buttons/PrimaryButton';

export const DescriptionModalContent = props => {
  const { id, description, closeModal, addDescriptionToAttachment } = props;
  const [descriptionValue, setDescriptionValue] = useState(description);

  return (
    <form>
      <TextInput
        id={`description-${id}-text-input`}
        placeholder={I18n.t('file_uploader.description_placeholder')}
        value={descriptionValue}
        onChange={e => {
          setDescriptionValue(e.target.value);
        }}
      />
      <ModalFooter>
        <ButtonLink secondary onClick={closeModal} fontSizeVariant="textMd">
          {I18n.t('file_uploader.cancel')}
        </ButtonLink>
        <div className="ml-md-3 mb-sm-3" />
        <PrimaryButton
          onPress={async e => {
            e.preventDefault();
            await addDescriptionToAttachment(descriptionValue);
            closeModal();
          }}
        >
          {I18n.t('file_uploader.save')}
        </PrimaryButton>
      </ModalFooter>
    </form>
  );
};
