import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  confirmationMessage: 'Are you sure that you want to perform this action?',
  implicationsMessage: 'You will not be able to revert this action',
}

const { Creators, Types } = createActions({
  setupConfirmationInfo: ['confirmationMessage', 'implicationsMessage'],
  resetConfirmationInfo: null,
  accept: null,
  reject: null,
}, { prefix: 'CONFIRMATION_GUARD_MODAL/' })

export const confirmationGuardActionCreators = Creators
export const confirmationGuardActionTypes = Types

const setupConfirmationInfoHandler = (state, { confirmationMessage, implicationsMessage }) => ({
  ...state, confirmationMessage, implicationsMessage,
})

const resetConfirmationInfoHandler = () => INITIAL_STATE

export const confirmationGuardReducer = createReducer(INITIAL_STATE, {
  [Types.SETUP_CONFIRMATION_INFO]: setupConfirmationInfoHandler,
  [Types.RESET_CONFIRMATION_INFO]: resetConfirmationInfoHandler,
})
