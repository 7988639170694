import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import Icon from '@material-ui/core/Icon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const tooltipIconStyle = {
  fontSize: '1rem',
  paddingLeft: 4,
  pointerEvents: 'auto',
};

const InputLabelHint = ({ hint }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        title={hint}
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        disableFocusListener
        disableTouchListener
      >
        <Icon
          onClick={handleTooltipOpen}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          style={tooltipIconStyle}
        >
          <i className="fa far fa-question-circle fa-sm" />
        </Icon>
      </Tooltip>
    </ClickAwayListener>
  );
};

InputLabelHint.propTypes = {
  hint: PropTypes.string.isRequired,
};

export default InputLabelHint;
