/** min_width: X
 * Bootstrap 4.1 29.06.2018
 * */
export const breakpoints = {
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
};
export const breakpointsForMaxWidth = {
  small: '575px',
  medium: '767px',
  large: '991px',
  extraLarge: '1199px',
};
