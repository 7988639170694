import { takeLatest, takeEvery, all } from 'redux-saga/effects'
import { API } from '../services/api'
import {
  fetchWasteManifestItemsAndCollectionCovered,
  destroyWasteManifestLineItem,
  assignWasteCollectionToCurrentWasteManifest,
  dropWasteCollectionFromCurrentWasteManifest,
  wasteCollectionNotCoveredIndexRequest,
} from './waste_management/wasteManifestFormSaga'
import {
  wasteManifestItemsListActionTypes,
  wasteManifestCollectionNotCoveredListActionTypes,
  wasteManifestActionRequestsTypes,
} from '../redux/views/waste_management/wasteManifestFormRedux'

export const rootSaga = function* () {
  yield all([
    takeLatest(wasteManifestActionRequestsTypes.FETCH_TABS_DATA_FOR_WASTE_MANIFEST_FORM, fetchWasteManifestItemsAndCollectionCovered, API),
    takeEvery(wasteManifestItemsListActionTypes.DESTROY_REQUEST, destroyWasteManifestLineItem, API),

    takeLatest(wasteManifestCollectionNotCoveredListActionTypes.INDEX_REQUEST, wasteCollectionNotCoveredIndexRequest, API),

    takeLatest(wasteManifestActionRequestsTypes.ASSIGN_WASTE_COLLECTION_TO_CURRENT_MANIFEST_REQUEST, assignWasteCollectionToCurrentWasteManifest, API),
    takeLatest(wasteManifestActionRequestsTypes.DROP_WASTE_COLLECTION_FROM_CURRENT_MANIFEST_REQUEST, dropWasteCollectionFromCurrentWasteManifest, API),
  ])
}
