export class FiltersConfig {
  constructor(initialWasteGeneratorId) {
    this.initialWasteGeneratorId = initialWasteGeneratorId;
  }

  getFiltersInitialValues = () => {
    return {
      with_identifier: [''],
      with_start_date: '',
      with_waste_generator: [this.initialWasteGeneratorId],
      with_waste_caf_id: [''],
      with_waste_chemical_name_id: [''],
    };
  };

  getFiltersDefaultValues = () => {
    return {
      with_identifier: [''],
      with_start_date: '',
      with_waste_generator: [''],
      with_waste_caf_id: [''],
      with_waste_chemical_name_id: [''],
    };
  };
}
