import React, { PureComponent } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';
import { colors } from '../../../Style/colors';
import { SaluteModal } from '../../modals/SaluteModal';
import { AddLinksModalContent } from '../../other/AddLinksModalContent';
import { store } from '../../../redux/store';

const t = key => I18n.t(`links_field.${key}`);

const MainContainer = styled.div`
  width: 100%;
`;

const LinkRow = styled.div`
  display: flex;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
`;

const LinkRowTextContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const LinkName = styled.span`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
`;

const LinkUrl = styled.a`
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
`;

const RemoveLinkButtonContainer = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const RemoveLinkButton = styled.button`
  background: transparent;
  border: 0;
  padding-left: 7px;
  color: ${colors.delete};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const AddLinkButton = styled.button`
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 0;
  background: transparent;
  border: 0;
  color: ${colors.link};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export class LinksInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openAddLinkModal: false,
    };
  }

  prepareUrlForDisplay = url => {
    if (url.match(/^https?:\/\/.*/)) {
      return url.replace(/^https?:\/\//, '');
    }
    return url;
  };

  defaultOnChange = event => {
    const { value, arrayHelpers, arrayHelpersMethod } = event;
    arrayHelpers[arrayHelpersMethod](value);
  };

  onChange = (event, additionalOnChange) => {
    // eslint-disable-next-line no-unused-expressions
    additionalOnChange
      ? additionalOnChange(event, this.defaultOnChange)
      : this.defaultOnChange(event);
  };

  onRemove = (arrayHelpers, index, additionalOnChange) => {
    const event = {
      value: index,
      arrayHelpers,
      arrayHelpersMethod: 'remove',
    };
    this.onChange(event, additionalOnChange);
  };

  openAddLinkModal = () => {
    this.setState({ openAddLinkModal: true });
  };

  closeAddLinkModal = () => {
    this.setState({ openAddLinkModal: false });
  };

  produceOnPush = (arrayHelpers, additionalOnChange) => value => {
    const event = {
      value,
      arrayHelpers,
      arrayHelpersMethod: 'push',
    };
    this.onChange(event, additionalOnChange);
  };

  getValidLink = link => {
    if (link.startsWith('https://') || link.startsWith('http://')) {
      return link;
    }
    return `https://${link}`;
  };

  styleModalOnModal = () => ({ maxWidth: '700px', margin: '3rem auto' });

  render() {
    const { disabled, onChange: additionalOnChange, name, value, modalOnModal } = this.props;

    return (
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <MainContainer>
            {value.map(({ url, name: linkName }, index) => (
              <LinkRow>
                <LinkRowTextContainer>
                  <LinkName>{linkName}&nbsp;</LinkName>
                  <LinkUrl target="_blank" href={this.getValidLink(url)}>
                    ({this.prepareUrlForDisplay(url)})
                  </LinkUrl>
                </LinkRowTextContainer>
                <RemoveLinkButtonContainer>
                  <RemoveLinkButton
                    type="button"
                    hidden={disabled}
                    onClick={() => this.onRemove(arrayHelpers, index, additionalOnChange)}
                  >
                    {t('remove')}
                  </RemoveLinkButton>
                </RemoveLinkButtonContainer>
              </LinkRow>
            ))}
            <AddLinkButton type="button" hidden={disabled} onClick={() => this.openAddLinkModal()}>
              {t('add')}
            </AddLinkButton>
            <SaluteModal
              title={t('add_modal.title')}
              isOpen={this.state.openAddLinkModal}
              onCloseClick={this.closeAddLinkModal}
              // Passing a store object is required to render modal on a modal. See the link below
              // https://stackoverflow.com/questions/36211739/invariant-violation-could-not-find-store-in-either-the-context-or-props-of-c
              store={store}
              wrapperStyle={modalOnModal ? this.styleModalOnModal() : {}}
            >
              <AddLinksModalContent
                {...this.props}
                onPush={this.produceOnPush(arrayHelpers, additionalOnChange)}
                hide={this.closeAddLinkModal}
              />
            </SaluteModal>
          </MainContainer>
        )}
      />
    );
  }
}
