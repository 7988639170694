import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

const PromptWrapper = styled.div`
  padding-left: 29px;
  padding-top: 10px;
`;

const AdditionalMessageWrapper = styled.li`
  padding-left: 29px;
`;

const conditionallySlicedMessages = additionalMessages => {
  const additionalMessagesLimit = 5;

  if (additionalMessages.length > additionalMessagesLimit) {
    const resultMessages = additionalMessages.slice(0, additionalMessagesLimit);
    resultMessages.push(I18n.t('flash_message.sliced_error_messages_ending'));
    return resultMessages;
  }
  return additionalMessages;
};

export const ErrorMessageContent = ({ mainMessage, additionalMessages = null }) => (
  <>
    <div>{mainMessage}</div>
    {!isEmpty(additionalMessages) && (
      <>
        <PromptWrapper>{I18n.t('flash_message.additional_error_messages_prompt')}</PromptWrapper>
        {Array.isArray(additionalMessages) ? (
          conditionallySlicedMessages(additionalMessages).map(additionalMessage => (
            <AdditionalMessageWrapper key={additionalMessage}>
              {additionalMessage}
            </AdditionalMessageWrapper>
          ))
        ) : (
          <AdditionalMessageWrapper>{additionalMessages}</AdditionalMessageWrapper>
        )}
      </>
    )}
  </>
);
