import React from 'react'
import PropTypes from 'prop-types'
import InputLabelHint from './InputLabelHint'

const HiddenFindingIdentifier = ({ findingId, index, disabled }) => (
  <input
    type="hidden"
    disabled={disabled}
    name={`violation[inspection_findings_attributes][${index}][id]`}
    id={`violation_inspection_findings_attributes_${index}_id`}
    value={findingId}
  />
)
HiddenFindingIdentifier.propTypes = {
  findingId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

const RemoveFindingButton = ({ onRemoveFinding, index }) => (
  <button
    id="remove-finding-field-button"
    type="button"
    className="btn"
    style={{ background: 'transparent' }}
    onClick={() => onRemoveFinding(index)}
  >
    <i className="fa fa-sm fa-times-circle" />
  </button>
)
RemoveFindingButton.propTypes = {
  onRemoveFinding: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool
}

const DisabledFindingButton = () => (
  <button type="button" className="btn" style={{ background: 'transparent' }} disabled>
    <InputLabelHint hint="This Finding has already been saved.<br>To update or remove it, visit the Findings tab." />
  </button>
)

const FindingButton = ({ findingId, index, onRemoveFinding, disabled }) => {
  let button
  if (findingId) {
    button = <DisabledFindingButton />
  } else {
    button = <RemoveFindingButton index={index} onRemoveFinding={onRemoveFinding} />
  }
  return button
}
FindingButton.propTypes = {
  findingId: PropTypes.number,
  onRemoveFinding: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool
}

const ViolationFindingDescription = (props) => {
  const { index, onDescriptionChange, onRemoveFinding, disabled } = props
  const { findingId, description } = props.finding
  return (
    <div className="row align-items-center mb-2">
      <div className="col pr-0">
        {findingId && <HiddenFindingIdentifier findingId={findingId} index={index} disabled={disabled} />}
        <textarea
          disabled={disabled}
          name={`violation[inspection_findings_attributes][${index}][regulatory_description]`}
          id={`violation_inspection_findings_attributes_${index}_regulatory_description`}
          value={description}
          onChange={(e) => onDescriptionChange(index, e.target.value)}
          className="textarea-default"
          cols="30"
          rows="3"
        />
      </div>
      <div className="col-auto pl-0">
        <FindingButton disabled={disabled} findingId={findingId} index={index} onRemoveFinding={onRemoveFinding} />
      </div>
    </div>
  )
}
ViolationFindingDescription.propTypes = {
  finding: PropTypes.shape({
    findingId: PropTypes.number,
    description: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onRemoveFinding: PropTypes.func.isRequired,
  disabledFindings: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default ViolationFindingDescription
