/** Lib */
import React from 'react';
import PropTypes from 'prop-types';
/** Field elements */
import { InputLabel } from '../elements/InputLabel';
import { NumericInput } from '../elements/NumericInput';
import { TextInput } from '../elements/TextInput';
import { FieldWrapper, INPUT_TYPES } from '../elements/FieldWrapper';
import { TextArea } from '../elements/TextArea';
import { RadioButtonsGroup } from '../elements/RadioButtonsGroup';
import { SaluteAsyncSelector } from '../elements/SaluteAsyncSelector';
import { SaluteMultipleAsyncSelector } from '../elements/SaluteMultipleAsyncSelector';
import { DatePicker } from '../elements/DateInputs/DatePicker';
import { DateTimePicker } from '../elements/DateInputs/DateTimePicker';
import { DateRangePicker } from '../elements/DateInputs/DateRangePicker';
import { FilesUploadInput } from '../elements/FileUploadInputs/FilesUploadInput';
import { SaluteCurrencyInput } from '../elements/SaluteCurrencyInput';
import { LinksInput } from '../elements/LinksInput';
import { CheckboxInput } from '../elements/CheckboxInput';
import { HtmlPresenter } from '../elements/HtmlPresenter';

/** @description General Input field component - its responsibility is to render
 * field wrapper with an input field according to provided type. It passes down
 * all props to input component. Might be useful when implementing page with
 * multiple different inputs - it will reduce number of input fields imports.
 * Additionally, because we want to render form according configuration object in future,
 * there will be a need to select input component at some point and this component
 * will take care of this task.
 * */
export const SaluteInputField = ({
  type,
  inputProps,
  labelProps,
  error,
  fieldWidth,
  children,
  childrenWrapperClassName,
}) => (
  <FieldWrapper
    childrenWrapperClassName={childrenWrapperClassName}
    fieldWidth={fieldWidth}
    error={error}
    themeFor={type}
    labelComponent={<InputLabel {...labelProps} />}
    inputComponent={selectInputComponent(type, inputProps)}
  >
    {children}
  </FieldWrapper>
);

const selectInputComponent = (type, inputProps) => {
  switch (type) {
    case INPUT_TYPES.TEXT_AREA:
      return <TextArea {...inputProps} />;
    case INPUT_TYPES.TEXT_FIELD:
      return <TextInput {...inputProps} />;
    case INPUT_TYPES.RADIO_BUTTONS:
      return <RadioButtonsGroup {...inputProps} />;
    case INPUT_TYPES.ASYNC_SELECT:
      return <SaluteAsyncSelector {...inputProps} />;
    case INPUT_TYPES.DATE_PICKER:
      return <DatePicker {...inputProps} />;
    case INPUT_TYPES.DATETIME_PICKER:
      return <DateTimePicker {...inputProps} />;
    case INPUT_TYPES.DATE_RANGE_PICKER:
      return <DateRangePicker {...inputProps} />;
    case INPUT_TYPES.NUMERIC:
      return <NumericInput {...inputProps} />;
    case INPUT_TYPES.FILES_UPLOAD:
      return <FilesUploadInput {...inputProps} />;
    case INPUT_TYPES.CURRENCY:
      return <SaluteCurrencyInput {...inputProps} />;
    case INPUT_TYPES.MULTIPLE_ASYNC_SELECT:
      return <SaluteMultipleAsyncSelector {...inputProps} />;
    case INPUT_TYPES.LINKS:
      return <LinksInput {...inputProps} />;
    case INPUT_TYPES.CHECKBOX:
      return <CheckboxInput {...inputProps} />;
    case INPUT_TYPES.HTML_PRESENTER:
      return <HtmlPresenter {...inputProps} />;
  }
};

SaluteInputField.propTypes = {
  type: PropTypes.number.isRequired,
  labelProps: PropTypes.object.isRequired,
  inputProps: PropTypes.object.isRequired,
  error: PropTypes.string,
  fieldWidth: PropTypes.number,
  children: PropTypes.node,
};
