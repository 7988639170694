/** Lib */
import React, { Component } from 'react'
import { withFormik } from 'formik'
import { connect } from 'react-redux'
import _ from 'lodash'

/** Input components */
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField'
import { TextInput } from '../../stateless_components/inputs/elements/TextInput'
import { NumericInput } from '../../stateless_components/inputs/elements/NumericInput'
import { SaluteAsyncSelector } from '../../stateless_components/inputs/elements/SaluteAsyncSelector/index'
/** NonInput components */
import { InputLabel } from '../../stateless_components/inputs/elements/InputLabel'
import { ErrorMessage } from '../../stateless_components/inputs/elements/ErrorMessage'
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton'
import { ModalFooter } from '../../stateless_components/modals/ModalFooter'
/** Services */
import { API } from '../../services/api'
import { getFormikErrors, validateForm } from '../../services/formHelper'
/** Constants */
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper'
import validationSchema from './ValidationSchema'
/** Action Creators */
import { wasteManifestItemsListActionCreators } from '../../redux/views/waste_management/wasteManifestFormRedux'
import { wasteManifestItemsActionCreators } from '../../redux/entities/wasteManifestItemsRedux'
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';

/** == Container ======================================================================= */
class WasteManifestItemFormPartial extends Component {
  render() {
    const {
      handleChange,
      values,
      errors,
      setFieldValue,
      handleSubmit,
      isSubmitting,
      handleBlur,
      dirty,
      touched,
      setFieldTouched,
      onCancellPress,
      initialFormObject,
    } = this.props

    return (
      <div>
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{
            label: 'Waste Profile',
            required: true,
          }}
          inputProps={{
            setFieldTouched,
            value: values.waste_type_id,
            name: 'waste_type_id',
            initialSelection: initialFormObject.waste_type && initialFormObject.waste_type.summary,
            apiPath: 'wasteManagement.wasteTypes.index',
            handleChange: (value) => setFieldValue('waste_type_id', value),
          }}
          error={getFormikErrors('waste_type_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{
            label: 'DOT UN',
            required: true,
          }}
          inputProps={{
            handleBlur,
            value: values.dotun,
            name: 'dotun',
            onChange: handleChange,
          }}
          error={getFormikErrors('dotun', touched, errors)}
          type={INPUT_TYPES.TEXT_FIELD}
        />
        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-xs-12 col-sm-3 col-md-4 col-lg-3 d-flex justify-content-lg-end align-items-center" style={{ paddingRight: '0' }}>
            <InputLabel label="Container Count" required />
          </div>
          <div className="col-xs-12 col-sm-9 col-md-3 col-lg-4">
            <NumericInput
              handleBlur={handleBlur}
              min={0}
              value={values.container_count}
              name="container_count"
              onChange={handleChange}
            />
            <ErrorMessage>{getFormikErrors('container_count', touched, errors)}</ErrorMessage>
          </div>
          <div className="col-sm-3 col-md-1 d-flex align-items-center">
            <InputLabel label="of" required />
          </div>
          <div className="col-sm-9 col-md-4">
            <TextInput
              handleBlur={handleBlur}
              value={values.container_type}
              name="container_type"
              onChange={handleChange}
            />
            <ErrorMessage>{getFormikErrors('container_type', touched, errors)}</ErrorMessage>
          </div>
        </div>


        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-xs-12 col-sm-3 col-md-4 col-lg-3 d-flex justify-content-lg-end align-items-center" style={{ paddingRight: '0' }}>
            <InputLabel label="Manifest Quantity" required />
          </div>
          <div className="col-xs-12 col-sm-9 col-md-3 col-lg-4">
            <NumericInput
              handleBlur={handleBlur}
              min={0}
              value={values.total_qty}
              name="total_qty"
              onChange={handleChange}
            />
            <ErrorMessage>{getFormikErrors('total_qty', touched, errors)}</ErrorMessage>
          </div>
          <div className="col-sm-3 col-md-1 d-flex align-items-center">
            <InputLabel label="of" required />
          </div>
          <div className="col-sm-9 col-md-4">
            <SaluteAsyncSelector
              setFieldTouched={setFieldTouched}
              value={values.total_um_id}
              initialSelection={initialFormObject.total_um && initialFormObject.total_um.summary}
              name="total_um_id"
              apiPath="wasteManagement.ums.index"
              handleChange={(value) => setFieldValue('total_um_id', value)}
            />
            <ErrorMessage>{getFormikErrors('total_um_id', touched, errors)}</ErrorMessage>
          </div>
        </div>

        <div className="row" style={{ marginTop: '0.5rem' }}>
          <div className="col-xs-12 col-sm-3 col-md-4 col-lg-3 d-flex justify-content-lg-end align-items-center" style={{ paddingRight: '0' }}>
            <InputLabel label="Actual Quantity" required />
          </div>
          <div className="col-xs-12 col-sm-9 col-md-3 col-lg-4">
            <NumericInput
              handleBlur={handleBlur}
              min={0}
              value={values.actual_qty}
              name="actual_qty"
              onChange={handleChange}
            />
            <ErrorMessage>{getFormikErrors('actual_qty', touched, errors)}</ErrorMessage>
          </div>
          <div className="col-sm-3 col-md-1 d-flex align-items-center">
            <InputLabel label="of" required />
          </div>
          <div className="col-sm-9 col-md-4">
            <SaluteAsyncSelector
              setFieldTouched={setFieldTouched}
              value={values.actual_um_id}
              initialSelection={initialFormObject.actual_um && initialFormObject.actual_um.summary}
              name="actual_um_id"
              apiPath="wasteManagement.ums.index"
              handleChange={(value) => setFieldValue('actual_um_id', value)}
            />
            <ErrorMessage>{getFormikErrors('actual_um_id', touched, errors)}</ErrorMessage>
          </div>
        </div>
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{
            label: 'Waste Codes',
            required: true,
          }}
          inputProps={{
            handleBlur,
            value: values.epa_codes,
            name: 'epa_codes',
            onChange: handleChange,
          }}
          error={getFormikErrors('epa_codes', touched, errors)}
          type={INPUT_TYPES.TEXT_FIELD}
        />
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{
            label: 'HWMM Code',
            required: true,
          }}
          inputProps={{
            setFieldTouched,
            value: values.waste_system_code_id,
            initialSelection: initialFormObject.waste_system_code && initialFormObject.waste_system_code.summary,
            name: 'waste_system_code_id',
            apiPath: 'wasteManagement.wasteSystemCodes.index',
            handleChange: (value) => setFieldValue('waste_system_code_id', value),
          }}
          error={getFormikErrors('waste_system_code_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{
            label: 'Tax Code',
            required: true,
          }}
          inputProps={{
            setFieldTouched,
            value: values.waste_nys_tax_code_id,
            initialSelection: initialFormObject && initialFormObject.waste_nys_tax_code && initialFormObject.waste_nys_tax_code.summary,
            name: 'waste_nys_tax_code_id',
            apiPath: 'wasteManagement.wasteNysTaxCodes.index',
            handleChange: (value) => setFieldValue('waste_nys_tax_code_id', value),
          }}
          error={getFormikErrors('waste_nys_tax_code_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          fieldWidth={FIELD_WIDTHS.WIDE}
          labelProps={{ label: 'Comments' }}
          inputProps={{
            handleBlur,
            value: values.comments,
            name: 'comments',
            onChange: handleChange,
          }}
          error={getFormikErrors('comments', touched, errors)}
          type={INPUT_TYPES.TEXT_AREA}
        />
        <ModalFooter>
          <ButtonLink fontSizeVariant="textMd" secondary onClick={onCancellPress}>Cancel</ButtonLink>
          <div className="ml-md-3 mb-sm-3"></div>
          <PrimaryButton onPress={handleSubmit} isPending={isSubmitting} disabled={!dirty}>Save</PrimaryButton>
        </ModalFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveWasteManifestItemRecord: (record) => {
    dispatch(wasteManifestItemsActionCreators.save(record))
  },
  addWasteManifestItemToList: (record) => {
    dispatch(wasteManifestItemsListActionCreators.add(record))
  },
})

export const WasteManifestItemForm = connect(null, mapDispatchToProps)(withFormik({
  mapPropsToValues: ({ initialFormObject = {} }) => ({
    id: initialFormObject.id,
    wasteManifestId: initialFormObject.waste_manifest_id,
    waste_type_id: initialFormObject.waste_type_id,
    dotun: initialFormObject.dotun,
    epa_codes: initialFormObject.epa_codes,
    waste_system_code_id: initialFormObject.waste_system_code && initialFormObject.waste_system_code.id,
    comments: initialFormObject.comments,
    container_count: initialFormObject.container_count,
    container_type: initialFormObject.container_type,
    total_qty: initialFormObject.total_qty,
    total_um_id: initialFormObject.total_um_id,
    actual_qty: initialFormObject.actual_qty,
    actual_um_id: initialFormObject.actual_um_id,
    waste_nys_tax_code_id: initialFormObject.waste_nys_tax_code && initialFormObject.waste_nys_tax_code.id,
    salute_organization_id: initialFormObject.salute_organization_id,
  }),

  validate: (values) => {
    return validateForm(values, validationSchema)
  },

  handleSubmit: (values, rest) => {
    const {
      setSubmitting, setErrors, setFieldValue, props,
    } = rest
    setSubmitting(true)
    let asyncAction

    if (values.id) {
      asyncAction = API.wasteManagement.wasteManifestItems.update
    } else {
      asyncAction = API.wasteManagement.wasteManifestItems.create
    }
    return asyncAction(values)
      .then((response) => {
        if (response.ok) {
          const { attributes } = response.data
          const { id } = attributes
          setFieldValue('id', id)
          props.saveWasteManifestItemRecord(attributes)
          props.addWasteManifestItemToList(attributes)
          props.onSuccess(response.data)
          flashMessage('The waste manifest item was successfully saved.', true, 'success')
        } else {
          flashMessage('Unable to save waste manifest item.', true, 'error')
          const errors = _.mapValues(response.data, (value) => value && value.join(' '))
          setErrors(errors)
        }

        setSubmitting(false)
      })
  },
})(WasteManifestItemFormPartial))
