import { transformNaNValue } from '../../services/formHelper'
import { YupValidator } from '../../validators/YupValidator'

const validationSchema = YupValidator.object().shape({
  dotun: YupValidator.string().required(I18n.t('validations.required')),
  epa_codes: YupValidator.string().required(I18n.t('validations.required')),
  container_type: YupValidator.string().required(I18n.t('validations.required')),
  total_qty: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive'))
    .integer(I18n.t('validations.out_of_range')),
  actual_qty: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive'))
    .integer(I18n.t('validations.out_of_range')),
  container_count: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive'))
    .integer(I18n.t('validations.out_of_range')),
  waste_type_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  waste_system_code_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  total_um_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  actual_um_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  waste_nys_tax_code_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
})

export default validationSchema
