import React, { Component } from 'react';
import styled from 'styled-components';
import { ListEmptyPlaceholder } from '../../stateless_components/placeholders/ListEmptyPlaceholder';
import ViolationListItem from './ViolationListItem';
import ViolationBtn from './ViolationBtn';
import { API } from '../../services/api';
import { SaluteSpinner } from '../../stateless_components/salute_spinner';
import { colors } from '../../Style/colors';

const Top = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: flex-end;
`;

const ListContainer = styled.div`
  border-bottom 1px solid ${colors.sectionSeparatorColor};
`;

export default class ViolationsList extends Component {
  state = { violations: [], dataFetched: false };

  componentDidMount = () => {
    this.fetchViolations();
  };

  componentDidUpdate = () => {
    if (!this.state.dataFetched) {
      this.fetchViolations();
    }
  };

  newViolationBtn = React.createRef();

  fetchViolations = (cb = () => {}) => {
    const inspectionId = this.props.values.id;
    if (!inspectionId) {
      return;
    }
    API.violations.index({ inspection_id: inspectionId }).then(response => {
      const {
        data: { data },
        ok,
      } = response;
      if (ok) {
        this.props.violationsListUpdatedCb(data.length);
        this.setState(
          {
            dataFetched: true,
            violations: data.map(item => item.attributes),
          },
          cb,
        );
      }
    });
  };

  refreshList = (newViolationData = undefined) => {
    this.fetchViolations(() => {
      if (newViolationData) {
        this.newViolationBtn.current.openModalWithNewViolationData(newViolationData);
      }
    });
  };

  renderList = () => {
    const {
      setFieldValue,
      admin_access,
      current_person,
      enums,
      customInvestigableTypesNames,
      values: { activeViolation },
    } = this.props;
    const findingsModuleProps = {
      setActiveRelatedEntity: activeRelatedEntity =>
        setFieldValue('activeViolation', activeRelatedEntity),
      activeRelatedEntity: activeViolation,
      refreshList: this.refreshList,
      adminAccess: admin_access,
      relatedEntityAttribute: 'violation_id',
      relatedEntityName: 'Violation',
      findingStatuses: enums.inspection_finding_status,
      customInvestigableTypesNames,
      defaults: {
        creator_id: current_person.id,
        violation_id: activeViolation.id,
        investigable: activeViolation.investigable,
        investigable_type: activeViolation.investigable_type,
        investigable_id: activeViolation.investigable_id,
        responsible_person_id: activeViolation.responsible_ehs_person_id,
      },
    };

    return (
      <>
        <Top>
          <ViolationBtn
            {...this.props}
            refreshList={this.refreshList}
            newViolation
            ref={this.newViolationBtn}
          />
        </Top>
        <ListContainer>
          {this.state.violations.map((violation, index) => {
            return (
              <ViolationListItem
                {...this.props}
                key={`violation-${index}`}
                violation={violation}
                refreshList={this.refreshList}
                findingProps={findingsModuleProps}
              />
            );
          })}
        </ListContainer>
      </>
    );
  };

  renderEmptyList = () => (
    <ListEmptyPlaceholder
      message={I18n.t('inspections.inspections.form.hints.no_violations_added_yet')}
    >
      <ViolationBtn {...this.props} newViolation isFirstViolation refreshList={this.refreshList} />
    </ListEmptyPlaceholder>
  );

  renderLoading = () => (
    <div style={{ paddingTop: '10px' }}>
      <SaluteSpinner style={{ margin: 'auto' }} name="circle" />
    </div>
  );

  renderListPlaceholder = () => (
    <ListEmptyPlaceholder
      message={I18n.t('inspections.inspections.form.hints.save_to_add_violations')}
    />
  );

  render() {
    const { violations, dataFetched } = this.state;
    if (this.props.values.id) {
      if (!dataFetched) {
        return this.renderLoading();
      } else if (!violations || violations.length === 0) {
        return this.renderEmptyList();
      }
      return this.renderList();
    }
    return this.renderListPlaceholder();
  }
}
