import { createReducer, createActions } from 'reduxsauce'
import _ from 'lodash'

/** @description:
 * Higher order function that given a resource name
 * returns actionCreators, actionTypes and reducers that support following actions:
 * - saving, updating single record or collection - save action
 * - removing single record or collection - destroy action
 * - triggering asynchronous requests: show, index
 * - keeping track of records ids that are being removed
 * */

export const reduxListsManagementBuilder = (resourceName) => {
  const INITIAL_STATE = {
    ids: [],
    itemsCurrentlyBeingRemoved: [],
    itemsSelected: [],
    isPending: false,
    isSubmitting: false,
    errors: null,
    page: null,
    totalPagesCount: null,
  }
  const prefix = `${resourceName}/`

  const { Types, Creators } = createActions({
    add: ['records', 'totalPagesCount'],
    overwrite: ['records', 'totalPagesCount'],
    remove: ['records'],
    clear: null,
    setPending: null,
    clearPending: null,
    setSubmitting: null,
    clearSubmitting: null,
    setErrors: ['errors'],
    addItemsCurrentlyBeingRemoved: ['records'],
    removeItemsCurrentlyBeingRemoved: ['records'],
    indexRequest: ['payload'],
    destroyRequest: ['payload'],
    toggleSelectedItems: ['ids'],
    changePage: ['page'],
  }, { prefix })

  const clearHandler = (_) => INITIAL_STATE

  const toId = (record) => typeof record === 'object' ? record.id : record

  const addHandler = (state, { records, totalPagesCount }) => {
    const newIds = _.flattenDeep([records]).map(toId)
    const newState = _.cloneDeep(state)
    newState.ids = _.union(newState.ids, newIds)
    if (totalPagesCount) {
      newState.totalPagesCount = totalPagesCount
    }

    return newState
  }

  const removeHandler = (state, { records }) => {
    const idsToBeRemoved = _.flattenDeep([records]).map(toId)
    const newState = _.cloneDeep(state)

    newState.ids = _.difference(newState.ids, idsToBeRemoved)

    return newState
  }

  const setPendingHandler = (state) => {
    const newState = _.cloneDeep(state)
    newState.isPending = true

    return newState
  }

  const clearPendingHandler = (state) => {
    const newState = _.cloneDeep(state)
    newState.isPending = false

    return newState
  }

  const setSubmittingHandler = (state) => {
    const newState = _.cloneDeep(state)
    newState.isSubmitting = true

    return newState
  }

  const clearSubmittingHandler = (state) => {
    const newState = _.cloneDeep(state)
    newState.isSubmitting = false

    return newState
  }

  const setErrorsHandler = (state, { errors }) => {
    const newState = _.cloneDeep(state)
    newState.errors = errors

    return newState
  }

  const addItemsCurrentlyBeingRemovedHandler = (state, { records }) => {
    const ids = _.flattenDeep([records]).map(toId)
    const newState = _.cloneDeep(state)
    newState.itemsCurrentlyBeingRemoved = _.union(newState.itemsCurrentlyBeingRemoved, ids)

    return newState
  }

  const removeItemsCurrentlyBeingRemovedHandler = (state, { records }) => {
    const ids = _.flattenDeep([records]).map(toId)
    const newState = _.cloneDeep(state)
    newState.itemsCurrentlyBeingRemoved = _.difference(newState.itemsCurrentlyBeingRemoved, ids)

    return newState
  }

  const toggleSelectedItemsHandler = (state, { ids }) => {
    const newState = _.cloneDeep(state)

    if (ids === null) {
      newState.itemsSelected = newState.itemsSelected.length === 0 ? [...newState.ids] : []
      return newState
    }

    if (ids.length === 0) {
      newState.itemsSelected = []
      return newState
    }

    const idsCollection = _.flattenDeep([ids]).map(toId)
    _.each(idsCollection, (id) => {
      if (newState.itemsSelected.includes((id))) {
        newState.itemsSelected = newState.itemsSelected.filter((item) => item !== id)
      } else {
        newState.itemsSelected = [...newState.itemsSelected, id]
      }
    })

    return newState
  }

  const changePageHandler = (state, { page }) => {
    const newState = _.cloneDeep(state)
    newState.page = page

    return newState
  }

  const overwriteHandler = (state, { records, totalPagesCount }) => {
    const newState = _.cloneDeep(state)
    const newIds = _.flattenDeep([records]).map(toId)
    newState.ids = newIds
    const totalPages = parseInt(totalPagesCount, 10)
    if ((typeof totalPages === 'number') && totalPages > 0) {
      newState.totalPagesCount = totalPagesCount
    }

    return newState
  }

  const Reducer = createReducer(INITIAL_STATE, {
    [Types.ADD]: addHandler,
    [Types.REMOVE]: removeHandler,
    [Types.CLEAR]: clearHandler,
    [Types.SET_ERRORS]: setErrorsHandler,
    [Types.SET_PENDING]: setPendingHandler,
    [Types.CLEAR_PENDING]: clearPendingHandler,
    [Types.SET_SUBMITTING]: setSubmittingHandler,
    [Types.CLEAR_SUBMITTING]: clearSubmittingHandler,
    [Types.ADD_ITEMS_CURRENTLY_BEING_REMOVED]: addItemsCurrentlyBeingRemovedHandler,
    [Types.REMOVE_ITEMS_CURRENTLY_BEING_REMOVED]: removeItemsCurrentlyBeingRemovedHandler,
    [Types.TOGGLE_SELECTED_ITEMS]: toggleSelectedItemsHandler,
    [Types.CHANGE_PAGE]: changePageHandler,
    [Types.OVERWRITE]: overwriteHandler,
  })

  return {
    Types,
    Creators,
    Reducer,
  }
}
