import { combineReducers } from 'redux'
import { wasteManifestReducer } from './wasteManifestRedux'
import {
  wasteManifestItemsReducer,
  wasteManifestCollectionReducer,
} from './wasteManifestItemsRedux'

export const entitiesReducer = combineReducers({
  wasteManifests: wasteManifestReducer,
  wasteManifestItems: wasteManifestItemsReducer,
  wasteManifestCollection: wasteManifestCollectionReducer,
})
