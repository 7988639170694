import React from 'react';
import PropTypes from 'prop-types';

const CategoryHeader = ({ name }) => {
  return <header className="checklist-question-category">{name}</header>;
};

CategoryHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CategoryHeader;
