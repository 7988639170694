import React, { Component } from 'react';
import { SaluteSpinner } from '../stateless_components/salute_spinner/index';

export default class Comments extends Component {
  componentDidMount() {
    loadComments();
  }

  render() {
    const { apiUrl, role } = this.props;
    return (
      <>
        <div className="form-default">
          <div className="row form-group margin-bottom-0">
            <div className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label pr-0" />
            <div className="col-lg-6 col-md-8 col-sm-9 col-xs-12">
              <div className="commentable pt-2" data-api={apiUrl} data-role={role}>
                <SaluteSpinner />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
