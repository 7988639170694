import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { entitiesReducer } from './entities/index'
import { viewsReducer } from './views/index'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from '../sagas/index'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  entities: entitiesReducer,
  views: viewsReducer,
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export { store }
