/** Lib */
import React, { Component } from 'react';
import HtdAsyncPaginate from 'htd-react-select-async-paginate';
import PropTypes from 'prop-types';
import _ from 'lodash';
/** Component */
import { OptionDetails } from './OptionDetails';
import { OptionHeader } from './OptionHeader';
import { OptionContainer } from './OptionContainer';
/** Style */
import { colors } from '../../../../Style/colors';
import 'react-select/dist/react-select.css';
/** Services */
import { API } from '../../../../services/api';

/** @Note: This should match PAGE_SIZE defined in Api::ApiHelper */
const PAGE_SIZE = 25;

/**@description Async selector for fetching and rendering options for select.
 * It uses HtdAsyncPaginate component which is a tiny fork of react-select-async-paginate with quick bugfix for this lib
 * It depends on react-select and thus it accepts all props for this library.
 * For the sake of backward compatibility with option being a value returned by some presenter on the server -
 * when rendering an option it tries to render markup in text key which was required by select2
 * Nevertheless, one can arbitrary customize rendering options.
 * */
export class SaluteAsyncSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      selection: this.selectOptions(props),
    };
    // to be run only if initial selection is id
    if (Number.isInteger(props.initialSelection) && !!props.intitialSelectionApiPath) {
      const requestDataFunction = _.get(API, props.intitialSelectionApiPath);
      requestDataFunction(props.initialSelection).then(this.getSelectionByIdCallback(
        selection => {
          if (this.props.shouldTriggerSetSelectedRecordOnInit) {
            this.triggerSetSelectedRecord(selection, true);
          }
        }).bind(this),
      );
    } else if (props.shouldTriggerSetSelectedRecordOnInit) {
      this.triggerSetSelectedRecord(this.state.selection);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.propsValueDidUpdate();
    }
  }

  propsValueDidUpdate() {
    const { selection } = this.state;
    const { value, intitialSelectionApiPath } = this.props;
    if (!(typeof selection === 'object' && value === _.get(selection, 'id'))) {
      if (Number.isInteger(value) && !!intitialSelectionApiPath) {
        const requestDataFunction = _.get(API, intitialSelectionApiPath);
        requestDataFunction(value).then(this.getSelectionByIdCallback().bind(this));
      } else {
        this.setState({ selection: value });
      }
    }
  }

  selectOptions = props => {
    return (
      (props.options &&
        props.options().options.find(option => {
          return option.value === props.value;
        })) ||
      props.initialSelection
    );
  };

  getSelectionByIdCallback = (cb = () => {}) => result => {
    const { mapInitialSelectionFromApi } = this.props;
    const selection = mapInitialSelectionFromApi
      ? mapInitialSelectionFromApi(result.data)
      : result.data;
    this.setState({ selection });
    cb(selection);
  };

  focusField = () => {
    this.setState({
      isFocused: true,
    });
  };

  blurField = e => {
    this.props.setFieldTouched && this.props.setFieldTouched(this.props.name);
    this.setState({
      isFocused: false,
    });
  };

  _defaultMapResult = item => ({
    value: item.id,
    id: item.id,
    label: item.text || item.header,
    header: item.header,
    details: item.details,
  });

  loadOptions = (search, loadedOptions) => {
    const defaultParams = {
      q: search,
      page: parseInt(loadedOptions.length / PAGE_SIZE) + 1,
    };
    const { apiPath, requestParams = {}, mapResult } = this.props;

    const params = _.merge(defaultParams, requestParams);
    const requestDataFunction = _.get(API, apiPath);

    const transformResponse = mapResult || this._defaultMapResult;

    return requestDataFunction(params).then(response => {
      return {
        options: response.data.results.map(transformResponse),
        hasMore: response.data.pagination.more,
      };
    });
  };

  setStyle = () => {
    let styles = {};

    if (this.props.disabled) {
      styles = {
        ...styles,
        backgroundColor: colors.disabled,
      };
    }

    if (this.state.isFocused) {
      styles = {
        ...styles,
        borderColor: colors.outline,
        borderWidth: 1,
        boxShadow: `0.005em 0.005em 0.005em ${colors.outline} inset, 0.005em 0.005em 0.005em ${colors.outline}`,
        outline: 0,
      };
    }

    if (this.state.selection && this.state.selection.details) {
      styles = {
        ...styles,
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      };
    }

    return styles;
  };

  renderOption = option => (
    <OptionContainer>
      <OptionHeader>{option.header || option.label}</OptionHeader>
      {option.details && <OptionDetails details={option.details} inDropdown />}
    </OptionContainer>
  );

  renderValue = option => <OptionHeader>{option.header || option.label}</OptionHeader>;

  onChange = value => {
    const { handleChange } = this.props;

    this.setState({ selection: value });
    handleChange(value === null ? null : value.value);
    this.triggerSetSelectedRecord(value);
  };

  triggerSetSelectedRecord = (value, triggerredOnInit = false) => {
    value && this.props.setSelectedRecord && this.props.setSelectedRecord(value, triggerredOnInit);
  };

  render() {
    const { selection } = this.state;

    const containerStyle = {
      background: 'white',
    };
    return (
      <div style={containerStyle}>
        <HtdAsyncPaginate
          key={this.props.apiPath + JSON.stringify(this.props.requestParams)}
          valueRenderer={this.renderValue}
          openOnFocus={true}
          autoBlur={true}
          optionRenderer={this.renderOption}
          onChange={this.onChange}
          value={this.state.selection}
          onFocus={this.focusField}
          onBlur={this.blurField}
          style={this.setStyle()}
          name={this.props.name}
          disabled={this.props.disabled || false}
          loadOptions={this.props.options || this.loadOptions}
          className={`${this.props.name}_selector`}
        />
        {selection && selection.details && <OptionDetails details={selection.details} />}
      </div>
    );
  }
}

SaluteAsyncSelector.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  apiPath: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  mapResult: PropTypes.func,
  mapInitialSelectionFromApi: PropTypes.func,
  customOptionComponent: PropTypes.func,
  customValueComponent: PropTypes.func,
  requestParams: PropTypes.object,
  options: PropTypes.func,
  disabled: PropTypes.bool,
  setSelectedRecord: PropTypes.func,
  shouldTriggerSetSelectedRecordOnInit: PropTypes.bool,
};
/** + all props react-select accepts*/
