/** Lib */
import React from 'react';
import { connect } from 'react-redux';
/** Components */
import { Filterable } from '../../../stateless_components/salute_table/filters/Filterable';
import { SaluteTable } from '../../../stateless_components/salute_table/SaluteTable';
import { DateRangePicker } from '../../../stateless_components/inputs/elements/DateInputs/DateRangePicker';
import { PrimaryButton } from '../../../stateless_components/buttons/PrimaryButton';
import { TextInput } from '../../../stateless_components/inputs/elements/TextInput';
import { SaluteAsyncSelector } from '../../../stateless_components/inputs/elements/SaluteAsyncSelector/index';
import { SaluteTableHeader } from '../../../stateless_components/salute_table/filters/SaluteTableHeader';
import { ModalFooter } from '../../../stateless_components/modals/ModalFooter';
/** Action creators */
import {
  wasteManifestActionRequestsActionCreators,
  wasteManifestCollectionNotCoveredListActionCreators,
} from '../../../redux/views/waste_management/wasteManifestFormRedux';
/** Selectors */
import { wasteManifestCollectionNotCoveredSelector } from '../../../selectors/wasteManagementSelectors';
/** Style */
import { colors } from '../../../Style/colors';
import '../../../stateless_components/salute_table/filters/header.css';
/** Config */
import { FiltersConfig } from './FiltersConfig';
import { ButtonLink } from '../../../stateless_components/buttons/ButtonLink';
import { dateFormatter, dateFormatValidator } from '../../../services/dateFormatUtils';

class ManageWasteCollectionModalPartial extends Filterable {
  constructor(props) {
    var filtersConfig = new FiltersConfig(props.initialWasteGeneratorId);
    super(props, filtersConfig.getFiltersInitialValues(), filtersConfig.getFiltersDefaultValues());
  }

  render() {
    let fromDate = null;
    let toDate = null;

    if (this.state.filters.with_start_date) {
      const dates = this.state.filters.with_start_date.split(' - ');
      fromDate = dates[0];
      toDate = dates[1];
    }

    const cols = [
      {
        Header: () => (
          <SaluteTableHeader
            filterName="with_identifier"
            filterValue={this.state.filters.with_identifier[0]}
            onSubmit={this.search} // TODO change name of this func to i.e applyAllFilters
            onClear={() => this.clearFilter('with_identifier')}
            filterBodyInput={
              <TextInput
                onChange={event =>
                  this.setFilterableState('filters')('with_identifier', true)(event.target.value)
                }
                value={this.state.filters.with_identifier[0]}
              />
            }
          />
        ),
        accessor: 'identifier',
      },
      {
        Header: () => (
          <SaluteTableHeader
            filterName="with_start_date"
            filterValue={this.state.filters.with_start_date}
            onSubmit={this.search}
            onClear={() => this.clearFilter('with_start_date')}
            filterBodyInput={
              <DateRangePicker
                onDatesChange={this.setFilterableState('filters')('with_start_date')}
                startDate={fromDate}
                endDate={toDate}
              />
            }
          />
        ),
        accessor: 'start_date',
        Cell: props => dateFormatter.date(props.value),
      },
      {
        Header: () => (
          <SaluteTableHeader
            filterName="with_waste_generator"
            filterValue={this.state.filters.with_waste_generator[0]}
            onSubmit={this.search}
            onClear={() => this.clearFilter('with_waste_generator')}
            filterBodyInput={
              <SaluteAsyncSelector
                handleChange={this.setFilterableState('filters')('with_waste_generator', true)}
                setSelectedRecord={this.setFilterableState('filterDetails')('with_waste_generator')}
                name="with_waste_generator"
                initialSelection={this.state.filters.with_waste_generator[0]}
                intitialSelectionApiPath="wasteManagement.wasteGenerators.show"
                apiPath="wasteManagement.wasteGenerators.index"
              />
            }
          />
        ),
        id: 'generator',
        accessor: item => item.waste_generator && item.waste_generator.generator_full_name,
      },
      {
        Header: () => (
          <SaluteTableHeader
            filterName="with_waste_caf_id"
            filterValue={this.state.filters.with_waste_caf_id[0]}
            onSubmit={this.search}
            onClear={() => this.clearFilter('with_waste_caf_id')}
            filterBodyInput={
              <SaluteAsyncSelector
                handleChange={this.setFilterableState('filters')('with_waste_caf_id', true)}
                setSelectedRecord={this.setFilterableState('filterDetails')('with_waste_caf_id')}
                name="with_waste_caf_id"
                apiPath="wasteManagement.wasteCafs.index"
                requestParams={{ active: true }}
              />
            }
          />
        ),
        id: 'hwcaf',
        accessor: item => item.hwcaf && item.hwcaf.caf_name,
      },
      {
        Header: () => (
          <SaluteTableHeader
            filterName="with_waste_chemical_name_id"
            filterValue={this.state.filters.with_waste_chemical_name_id[0]}
            onSubmit={this.search}
            onClear={() => this.clearFilter('with_waste_chemical_name_id')}
            filterBodyInput={
              <SaluteAsyncSelector
                handleChange={this.setFilterableState('filters')(
                  'with_waste_chemical_name_id',
                  true,
                )}
                setSelectedRecord={this.setFilterableState('filterDetails')(
                  'with_waste_chemical_name_id',
                )}
                name="with_waste_chemical_name_id"
                apiPath="wasteManagement.wasteChemicalNames.index"
              />
            }
          />
        ),
        id: 'chem_name',
        accessor: item => item.waste_chemical_name && item.waste_chemical_name.chemical_name,
      },
    ];
    return (
      <div>
        <i className="fas fa-search" style={{ color: colors.brand }}></i> Search Waste Collections
        <div className="col-2 text-right">{this.clearAllFiltersButton()}</div>
        <div className="mt-3 mb-3">
          <TextInput
            value={this.state.smartSearchQuery}
            placeholder="Search here..."
            onChange={this.onSmartSearchInputChange}
          />
        </div>
        <SaluteTable
          toggleRowSelected={this.props.toggleItemsSelected}
          selected={this.props.selectedItems}
          toggleSelectAll={() => this.props.toggleItemsSelected(null)}
          data={this.props.wasteManifestCollectionNotCovered}
          columns={cols}
          pageSize={this.state.pageSize}
          onPageSizeChange={pageSize => {
            this.setState({ pageSize, isFetching: true }, () => {
              this.props.toggleItemsSelected([]);
              this.props.fetchWasteNotCoveredCollection(
                0,
                this.state.smartSearchQuery,
                this.state.filters,
                this.state.pageSize,
              );
              this.setState({ isFetching: false });
            });
          }}
          onPageSelected={page => {
            this.props.toggleItemsSelected([]);
            this.props.fetchWasteNotCoveredCollection(
              page,
              this.state.smartSearchQuery,
              this.state.filters,
              this.state.pageSize,
            );
          }}
          isFetching={this.props.isFetching}
          pagination={this.props.pagination}
          withRowSelector
        />
        <ModalFooter>
          <ButtonLink fontSizeVariant="textMd" secondary onClick={this.props.onCancelPress}>
            Cancel
          </ButtonLink>
          <div className="ml-md-3 mb-sm-3"></div>
          <PrimaryButton
            disabled={this.props.selectedItems.length === 0}
            onPress={() =>
              this.props.assignWasteCollectionToCurrentManifest(
                this.props.wasteManifestId,
                this.props.selectedItems,
              )
            }
            isPending={this.props.isSubmitting}
            noFadeIn
          >
            Save
          </PrimaryButton>
        </ModalFooter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  wasteManifestCollectionNotCovered: wasteManifestCollectionNotCoveredSelector.allRecords(state),
  isFetching: wasteManifestCollectionNotCoveredSelector.isFetching(state),
  selectedItems: wasteManifestCollectionNotCoveredSelector.selectedIds(state),
  pagination: wasteManifestCollectionNotCoveredSelector.paginationInfo(state),
  isSubmitting: wasteManifestCollectionNotCoveredSelector.isSubmitting(state),
});

const isDateRangeFilterValueValid = date => {
  if (['', null, undefined].includes(date)) return true;

  if (typeof date === 'string') {
    const [from = '', to = ''] = date.split(' - ');
    return dateFormatValidator.isDate(from, false) && dateFormatValidator.isDate(to, false);
  }
};

const mapDispatchToProps = dispatch => ({
  toggleItemsSelected: x =>
    dispatch(wasteManifestCollectionNotCoveredListActionCreators.toggleSelectedItems(x)),
  fetchWasteNotCoveredCollection: (page, smartSearchTerm, filterrificParams, pageSize) => {
    if (!isDateRangeFilterValueValid(filterrificParams.with_start_date)) return;

    const requestParams = {
      page,
      filterrific: filterrificParams,
      smart_search: smartSearchTerm,
      limit: pageSize,
    };

    dispatch(wasteManifestCollectionNotCoveredListActionCreators.indexRequest({ requestParams }));
  },
  assignWasteCollectionToCurrentManifest: (manifestId, wasteCollectionRecords) => {
    dispatch(
      wasteManifestActionRequestsActionCreators.assignWasteCollectionToCurrentManifestRequest(
        manifestId,
        wasteCollectionRecords,
      ),
    );
  },
});

export const ManageWasteCollectionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageWasteCollectionModalPartial);
