import React from 'react';
import styled from 'styled-components';
import InputLabelHint from '../InputLabelHint';
import { colors } from '../../../../assets/styles/colors';
import { fontSize } from '../../../../assets/styles/typography';
import { breakpoints, breakpointsForMaxWidth } from '../../../../assets/styles/grid';

const Row = styled.div`
  padding: 0;
`;

const Label = styled.label.attrs({ htmlFor: props => props.inputId })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  @media only screen and (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    text-align: right;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

const Required = styled.span`
  margin-left: 4px;
  color: ${colors.required};
`;

const HintWrapper = styled.span`
  margin-left: 4px;
`;

const AdditionalContent = styled.div`
  margin-left: 16px;
  font-size: ${fontSize.textSm};
  @media only screen and (max-width: ${breakpointsForMaxWidth.large}) {
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 8px;
  }
`;

const Error = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.error};
`;

const Warning = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.gray};
`;

export const DefaultFormRow = props => {
  const {
    name,
    label,
    hint,
    required,
    error,
    warning,
    children,
    additionalContent,
    additionalContentInset,
    hidden,
    invisible,
    inputId,
    bottomMargin,
  } = props;
  return (
    <div className="w-100" hidden={hidden} style={{ visibility: invisible ? 'hidden' : 'visible' }}>
      <Row className="w-100 row mx-0">
        <Label
          name={name}
          className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex pl-0"
          inputId={inputId}
        >
          {label}
          {hint && (
            <HintWrapper>
              <InputLabelHint hint={hint} />
            </HintWrapper>
          )}
          {required && <Required>*</Required>}
        </Label>
        <div className="col-12 col-sm-8 col-md-8 col-lg-4 d-flex px-0">
          {children}
          {additionalContentInset && additionalContent && (
            <div className="pl-2 d-flex justify-content-center">{additionalContent}</div>
          )}
        </div>
        {additionalContent && !additionalContentInset && (
          <AdditionalContent className="col-12 col-lg-4 d-flex px-0">
            <div className="d-flex align-items-center">{additionalContent}</div>
          </AdditionalContent>
        )}
      </Row>
      <Row className="w-100 row mx-0">
        <div className="col-12 col-sm-4 col-md-4 col-lg-3 pl-0" />
        <div className="col-12 col-sm-8 col-md-8 col-lg-4 pl-2 pr-0">
          {error && <Error className="w-100 mt-1">{error}</Error>}
          {warning && (
            <Warning className="w-100 mt-1">
              <span>
                <i className="fas fa-exclamation-triangle fa-sm mr-1" />
                {warning}
              </span>
            </Warning>
          )}
        </div>
      </Row>
      {bottomMargin ? <div className="w-100 mb-2" /> : <></>}
    </div>
  );
};
