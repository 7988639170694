import React, { Component } from 'react';
import { FieldArray } from 'formik';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import {
  FIELD_WIDTHS,
  FieldWrapper,
  INPUT_TYPES,
} from '../../stateless_components/inputs/elements/FieldWrapper';
import InputLabelHint from '../InputLabelHint';
import { InputLabel } from '../../stateless_components/inputs/elements/InputLabel';

export default class ViolationFormFindings extends Component {
  render = () => {
    const {
      handleBlur,
      handleChange,
      errors,
      touched,
      violationFinalized,
      values,
      values: { inspection_findings_attributes = [] },
    } = this.props;

    const labelHint =
      'You can add multiple findings covered by this violation.<br>' +
      '1. Provide regulatory descriptions for the findings here.<br>' +
      '2. In the next step, you can supply more information and required ' +
      'corrective actions from the Findings Tab.';
    const persistedFindingHint =
      'This Finding has already been saved.<br>To update or remove it, visit the Findings tab';
    const listEmpty = inspection_findings_attributes.length === 0;

    return (
      <FieldArray
        name="inspection_findings_attributes"
        render={arrayHelpers => (
          <>
            {inspection_findings_attributes.map((finding, index) => (
              <SaluteInputField
                key={`violation-form-finding-description-${index}`}
                labelProps={{
                  label: index === 0 ? 'Findings' : '',
                  hint: index === 0 ? labelHint : undefined,
                }}
                inputProps={{
                  disabled: violationFinalized || finding.status === 'finalized',
                  handleBlur,
                  value: finding.regulatory_description,
                  name: `inspection_findings_attributes[${index}][regulatory_description]`,
                  onChange: handleChange,
                  style: { height: '100px' },
                }}
                fieldWidth={FIELD_WIDTHS.NEVER_FULL}
                type={INPUT_TYPES.TEXT_AREA}
              >
                <div className="d-flex align-items-center h-100">
                  {finding.id && <InputLabelHint hint={persistedFindingHint} />}
                  {!finding.id && (
                    <button
                      type="button"
                      className="btn p-0"
                      style={{ background: 'transparent', marginLeft: '-2px' }}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <i className="fa fa-sm fa-times-circle" />
                    </button>
                  )}
                </div>
              </SaluteInputField>
            ))}
            <FieldWrapper
              labelComponent={
                <InputLabel
                  label={listEmpty ? 'Findings' : ''}
                  hint={listEmpty ? labelHint : undefined}
                />
              }
              inputComponent={
                <button
                  type="button"
                  disabled={violationFinalized}
                  className="btn btn-sm btn-light"
                  onClick={() => {
                    arrayHelpers.push({ regulatory_description: '' });
                  }}
                >
                  + Add New Finding
                </button>
              }
            />
          </>
        )}
      />
    );
  };
}
