const extractHour12PlusFromNeedle = needle => {
  const regex = /^((1[3-9])|(2[0-4]))/;
  const match = needle.match(regex);
  return match ? parseInt(match[0], 10) - 12 : null;
};

const extractHourFromNeedle = needle => {
  const regex = /^((0\d)|10|11|12|\d)/;
  const match = needle.match(regex);
  if (match) {
    const matchStr = match[0];
    if (['0', '00'].includes(matchStr)) return 12;
    return parseInt(matchStr, 10);
  }
  return null;
};

const extractMinuteFromNeedle = needle => {
  if (typeof needle === 'string') {
    const stripRegex = /^((.*:)|((1[3-9])|(2[0-4]))|((0\d)|10|11|12|\d))/;
    const regex = /^(([0-5]\d)|\d)/;
    const strippedNeedle = needle.replace(stripRegex, '');
    const match = strippedNeedle.match(regex);
    if (match) return parseInt(match[0], 10);
  }
  return null;
};

const extractPeriodFromNeedle = needle => {
  if (/[aA]/.test(needle)) return 'AM';
  if (/[pP]/.test(needle)) return 'PM';
  return null;
};

const convertTo24 = (hour, period) => {
  const isAm = period === 'AM';
  if (hour === 12) {
    return isAm ? 0 : 12;
  }
  return hour + (isAm ? 0 : 12);
};

const pad = value => (value.toString().length === 1 ? `0${value}` : value);

export const prepareTimeFieldOptions = (needle = '') => {
  let hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let minutes = [0, 30];
  let periods = ['AM', 'PM'];

  if (typeof needle === 'string') {
    const hour12Plus = extractHour12PlusFromNeedle(needle);
    if (hour12Plus) {
      hours = [hour12Plus];
      periods = [hour12Plus === 12 ? 'AM' : 'PM'];
    } else {
      const needleHour = extractHourFromNeedle(needle);
      if (needleHour) hours = [needleHour];
      const needlePeriod = extractPeriodFromNeedle(needle);
      if (needlePeriod) periods = [needlePeriod];
    }
    const needleMinute = extractMinuteFromNeedle(needle);
    if (needleMinute) minutes = [needleMinute];
  }

  const result = [];
  periods.forEach(period => {
    hours.forEach(hour =>
      minutes.forEach(minute =>
        result.push({
          hours: convertTo24(hour, period),
          minutes: minute,
          data: `${pad(hour)}:${pad(minute)} ${period}`,
        }),
      ),
    );
  });
  return result;
};
