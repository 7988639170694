/** Lib */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
/** Components */
import { IndeterminateCheckbox } from '../inputs/elements/IndeterminateCheckbox';
import { PaginationPanel } from './PaginationPanel';
/** Style */
import { colors } from '../../Style/colors';
import { fontSize } from '../../Style/typography';

const CHECKBOX_SELECTION_STATE = {
  NONE: 0,
  INDETERMINATE: 1,
  ALL: 2,
};

export const SaluteTable = props => {
  const headerCheckboxState = () => {
    const selectedStateCount = props.selected.length;

    switch (selectedStateCount) {
      case 0:
        return CHECKBOX_SELECTION_STATE.NONE;
      case props.data.length:
        return CHECKBOX_SELECTION_STATE.ALL;
      default:
        return CHECKBOX_SELECTION_STATE.INDETERMINATE;
    }
  };

  const checkboxColumn = () => {
    if (props.withRowSelector) {
      return [
        {
          id: 'checkbox',
          accessor: '',
          Cell: ({ original }) => (
            <div className="text-center">
              <input
                type="checkbox"
                checked={props.selected.includes(original.id)}
                onChange={() => props.toggleRowSelected(original.id)}
              />
            </div>
          ),
          Header: x => (
            <div className="text-center">
              <IndeterminateCheckbox
                checked={headerCheckboxState() === CHECKBOX_SELECTION_STATE.ALL}
                indeterminate={headerCheckboxState() === CHECKBOX_SELECTION_STATE.INDETERMINATE}
                onChange={props.toggleSelectAll}
              />
            </div>
          ),
          sortable: false,
          width: 45,
        },
      ];
    }
    return [];
  };

  //= ==================================== Checkbox column [END] ========================

  //= ==================================== Table listeners & styling [START] ============

  const theadTrProps = () => ({
    style: {
      background: colors.tableHeader,
      color: 'white',
      fontWeight: '100',
      fontSize: fontSize.textSm,
      height: '45px',
    },
  });

  const theadThProps = () => ({
    style: {
      borderColor: 'white',
      height: '100%',
      alignItems: 'center',
      alignContent: 'center',
      display: 'flex',
      fontSize: fontSize.textXsm,
      justifyContent: 'center',
    },
  });

  const tdProps = (state, rowInfo, column) => ({
    onClick: event => {
      if (isRowRedirectable(column)) {
        window.location.assign(props.url.replace(':id', rowInfo.original.id));
      }
    },
    style: {
      fontSize: fontSize.textSm,
      cursor: isRowRedirectable(column) ? 'pointer' : 'auto',
    },
  });

  const isRowRedirectable = column => props.url && column.id !== 'checkbox';

  //= ==================================== Table listeners & styling [END] ==============

  const { data, onPageSizeChange, pageSize, showPagination = false, sortable = false } = props;

  const columnDefinitions = [...checkboxColumn(), ...props.columns];
  return (
    <div>
      <ReactTable
        pageSize={data.length || 10}
        data={data}
        columns={columnDefinitions}
        showPagination={showPagination}
        className="-striped -highlight"
        getTdProps={tdProps}
        sortable={sortable}
        getTheadTrProps={theadTrProps}
        getTheadThProps={theadThProps}
      />
      {props.pagination && (
        <div className="mt-3 mb-3 d-flex justify-content-end">
          <PaginationPanel
            onPageSelected={props.onPageSelected}
            isFetching={props.isFetching}
            totalPages={props.pagination.totalPagesCount}
            page={props.pagination.page}
            pageSize={pageSize}
            setPageSize={onPageSizeChange}
          />
        </div>
      )}
    </div>
  );
};

SaluteTable.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  selected: PropTypes.array,
  isFetching: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  url: PropTypes.string,
  defaultPageSize: PropTypes.number,
  withRowSelector: PropTypes.bool,
  onPageSelected: PropTypes.func,
  toggleRowSelected: PropTypes.func,
  toggleSelectAll: PropTypes.func,
  showPagination: PropTypes.bool,
  sortable: PropTypes.bool,
};
