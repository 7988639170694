import React, { Component } from 'react';
import styled from 'styled-components';
import defaultTo from 'lodash/defaultTo';
import { fontSize, fontWeights } from '../../Style/typography';
import { colors } from '../../Style/colors';
import ViolationBtn from './ViolationBtn';
import FindingsBtn from '../Checklist/Buttons/FindingsBtn';
import { dateFormatter } from '../../services/dateFormatUtils';

const ItemContainer = styled.div`
  padding: 10px 0;
  width: 100%;
  font-size: ${fontSize.textSm};
  border-top: 1px solid ${colors.sectionSeparatorColor};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 3px 20px 0;
`;

const Title = styled.div`
  font-size: ${fontSize.textMd};
  font-weight: ${fontWeights.headings};
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
`;

const BoldedText = styled.span`
  font-weight: ${fontWeights.headings};
  white-space: pre;
`;

const ViolationDetails = styled.div`
  margin-top: 20px;
  background: ${colors.grayBg};
  display: flex;
  cursor: pointer;
`;

const ViolationDetailsTile = styled.div`
  width: 25%;
  padding: 15px 25px;
`;

const ViolationsDetailsTileValue = styled.div`
  margin-top: 10px;
`;

const IconContainer = styled.span`
  margin-right: 3px;
`;

const t = key => I18n.t(`activerecord.attributes.violation.${key}`);

export default class ViolationListItem extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  openViolationModal = () => {
    this.child.current.openModal();
  };

  render = () => {
    const {
      violation: {
        id,
        violation_number,
        status,
        due_date_expired,
        description,
        violation_type,
        responsible_individual,
        due_date,
        date_closed_official,
        findings_count,
      },
      violation,
      enums,
      findingProps,
    } = this.props;
    const statusData = enums.violation_status.find(item => item.value === status);
    const blankValue = I18n.t('blank_value');
    return (
      <ItemContainer>
        <HeaderRow>
          <Title onClick={this.openViolationModal}>{violation_number}</Title>
          <ButtonsContainer>
            <ViolationBtn ref={this.child} {...this.props} />
            <FindingsBtn
              findingsCount={findings_count}
              findingProps={findingProps}
              relatedEntity={{ ...violation, entityIdentifier: violation_number }}
            />
          </ButtonsContainer>
        </HeaderRow>
        <HeaderRow style={{ cursor: 'pointer' }} onClick={this.openViolationModal}>
          <BoldedText>{`${t('violation_type_id')}: `}</BoldedText>
          <span>{violation_type}</span>
        </HeaderRow>
        {description && <HeaderRow>{` ${description}`}</HeaderRow>}
        <ViolationDetails onClick={this.openViolationModal}>
          {[
            { label: t('status'), value: statusData ? statusData.header : blankValue },
            {
              label: t('responsible_individual'),
              value: defaultTo(responsible_individual, blankValue),
            },
            {
              label: t('list_due_date'),
              value: defaultTo(dateFormatter.date(due_date), blankValue),
              color: due_date_expired ? colors.error : '',
              icon: due_date_expired ? 'saluteicons-warning' : null,
            },
            {
              label: t('resolved_date'),
              value: defaultTo(dateFormatter.date(date_closed_official), blankValue),
              color: date_closed_official ? colors.success : '',
              icon: date_closed_official ? 'saluteicons-check' : null,
            },
          ].map((entry, index) => (
            <ViolationDetailsTile key={`violation-${id}-details-tile-${index}`}>
              <div>{entry.label}</div>
              <ViolationsDetailsTileValue>
                <div style={entry.color ? { color: entry.color } : {}}>
                  {entry.icon && <IconContainer className={`saluteicons ${entry.icon}`} />}
                  <BoldedText>{entry.value}</BoldedText>
                </div>
              </ViolationsDetailsTileValue>
            </ViolationDetailsTile>
          ))}
        </ViolationDetails>
      </ItemContainer>
    );
  };
}
