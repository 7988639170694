import { createReducer, createActions } from 'reduxsauce'

const INITIAL_STATE = {
  openedModal: null,
}

export const AVAILABLE_MODALS = {
  CONFIRMATION_GUARD: 'CONFIRMATION_GUARD',
  MANAGE_WASTE_COLLECTION: 'MANAGE_WASTE_COLLECTION',
}
const { Creators, Types } = createActions({
  openModal: ['modalId'],
  closeModal: null,
}, { prefix: 'MODALS/SETUP/' })

export const modalSetupActionCreators = Creators
export const modalSetupActionTypes = Types

const openModalHandler = (state, { modalId }) => ({
  ...state, openedModal: modalId,
})

const closeModalHandler = (state) => ({ ...state, openedModal: null })

export const modalSetupReducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL]: openModalHandler,
  [Types.CLOSE_MODAL]: closeModalHandler,
})
