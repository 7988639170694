import React from 'react';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';
import { SaluteModal } from '../../stateless_components/modals/SaluteModal';
import { TextArea } from '../../stateless_components/inputs/elements/TextArea';
import { DeletionLink } from '../../stateless_components/buttons/DeletionLink';
import { CheckboxInput } from '../../stateless_components/inputs/elements/CheckboxInput';

const modelT = internal => (key, args) =>
  I18n.t(`activerecord.attributes.inspection.${internal ? 'internal' : 'external'}.${key}`, args);
const t = (key, args = {}) => I18n.t(`inspections.inspections.form.${key}`, args);

const FinalizeModal = ({ handleSubmit, setFieldValue, disabled, internal, values }) => (
  <SaluteModal
    isOpen
    title=""
    onCloseClick={() => {
      setFieldValue('openModal', '');
    }}
  >
    <div className="inspection_modal">
      <div>
        <div className="col-12 mt-4">
          <p className="warning_title">
            {t('modals.finalization.title', {
              model_name: modelT(internal)('model_name'),
            })}
          </p>
        </div>
        <div className="col-12 mt-4">
          <SendNotificationExplanation />
          <SendNotificationCheckbox
            setFieldValue={setFieldValue}
            internal={internal}
            values={values}
          />
          <SendEmailNotificationsForFindingsCheckbox
            setFieldValue={setFieldValue}
            internal={internal}
            values={values}
          />
          <SendingEmailsForFindingsExplanation values={values} internal={internal} />
        </div>
      </div>
      <div>
        <div className="text-right mt-4">
          <ButtonLink
            secondary
            fontSizeVariant="textMd"
            onClick={async () => {
              setFieldValue('openModal', '');
            }}
            disabled={disabled}
          >
            {t('modals.finalization.footer.cancel')}
          </ButtonLink>
          <PrimaryButton
            secondary
            onPress={async (...args) => {
              await setFieldValue('submit', 'finalize');
              await setFieldValue('createSimilar', true);
              await setFieldValue('openModal', '');
              handleSubmit(...args);
            }}
            disabled={disabled}
          >
            {t('modals.finalization.footer.finalize_and_create_similar', {
              model_name: modelT(internal)('model_name'),
            })}
          </PrimaryButton>
          <PrimaryButton
            className="ml-3"
            onPress={async (...args) => {
              await setFieldValue('submit', 'finalize');
              await setFieldValue('openModal', '');
              handleSubmit(...args);
            }}
            disabled={disabled}
          >
            {t('modals.finalization.footer.finalize', {
              model_name: modelT(internal)('model_name'),
            })}
          </PrimaryButton>
        </div>
      </div>
    </div>
  </SaluteModal>
);

const SaveModal = ({ handleSubmit, setFieldValue, disabled, internal, values }) => (
  <SaluteModal
    isOpen
    title=""
    onCloseClick={() => {
      setFieldValue('openModal', '');
    }}
  >
    <div className="inspection_modal">
      <div>
        <div className="col-12 mt-4">
          <p className="warning_title">
            {t('modals.save.title', {
              model_name: modelT(internal)('model_name'),
            })}
          </p>
        </div>
        <div className="col-12 mt-4">
          <SendNotificationExplanation />
          <SendNotificationCheckbox
            setFieldValue={setFieldValue}
            internal={internal}
            values={values}
          />
          <SendEmailNotificationsForFindingsCheckbox
            setFieldValue={setFieldValue}
            internal={internal}
            values={values}
          />
          <SendingEmailsForFindingsExplanation values={values} />
        </div>
      </div>
      <div>
        <div className="text-right mt-4">
          <ButtonLink
            secondary
            fontSizeVariant="textMd"
            onClick={async () => {
              setFieldValue('openModal', '');
            }}
            disabled={disabled}
          >
            {t('modals.save.footer.cancel')}
          </ButtonLink>
          <PrimaryButton
            className="ml-3"
            onPress={async (...args) => {
              await setFieldValue('submit', 'draft');
              handleSubmit(...args);
              await setFieldValue('openModal', '');
            }}
            disabled={disabled}
          >
            {t('modals.save.footer.save', {
              model_name: modelT(internal)('model_name'),
            })}
          </PrimaryButton>
        </div>
      </div>
    </div>
  </SaluteModal>
);

const ReopenModal = ({ handleSubmit, setFieldValue, disabled, values, internal }) => (
  <SaluteModal
    isOpen
    title=""
    onCloseClick={() => {
      setFieldValue('openModal', '');
    }}
  >
    <div className="inspection_modal">
      <div>
        <div className="col-12 mt-5 text-center">
          <i className="saluteicons saluteicons-warning" />
        </div>
      </div>
      <div>
        <div className="col-12 text-center mt-4">
          <p className="warning_title">
            {t('modals.reopen.title', { model_name: modelT(internal)('model_name') })}
          </p>
        </div>
      </div>
      <div className="mb-1">
        <div className="w-full text-center mt-4">
          <span className="with-asterisk">{t('modals.reopen.reason')}:</span>
        </div>
        <div className="w-full text-center mt-3">
          <TextArea
            rows="5"
            value={values.audit_comment}
            onChange={e => setFieldValue('audit_comment', e.target.value)}
          />
        </div>
      </div>
      <SendNotificationExplanation />
      <SendNotificationCheckbox setFieldValue={setFieldValue} internal={internal} values={values} />
      <div>
        <div className="text-right mt-4">
          <ButtonLink
            secondary
            fontSizeVariant="textMd"
            onClick={async () => {
              setFieldValue('openModal', '');
            }}
            disabled={disabled}
          >
            {t('modals.reopen.footer.cancel')}
          </ButtonLink>
          <PrimaryButton
            disabled={!values.audit_comment || disabled}
            onPress={async (...args) => {
              await setFieldValue('submit', 'reopen');
              await setFieldValue('openModal', '');
              handleSubmit(...args);
            }}
          >
            {t('modals.reopen.footer.reopen', { model_name: modelT(internal)('model_name') })}
          </PrimaryButton>
        </div>
      </div>
    </div>
  </SaluteModal>
);

const NotFinalizedFooter = ({
  handleSubmit,
  setFieldValue,
  disabled,
  values,
  admin_access,
  internal,
}) => {
  return (
    <div className="d-flex flex-wrap justify-content-end">
      <div className="mr-auto d-flex justify-content-start">
        {admin_access && values.id && (
          <DeleteButton
            setFieldValue={setFieldValue}
            disabled={disabled}
            handleSubmit={handleSubmit}
            internal={internal}
          />
        )}
      </div>
      <PrimaryButton
        secondary
        className="mt-3 ml-sm-3"
        onPress={() => {
          setFieldValue('openModal', 'saveAsDraft');
          setFieldValue('send_email_notifications', !values.id);
          setFieldValue('send_email_notifications_for_findings', !values.id);
        }}
        disabled={disabled}
      >
        {t('footer.save_as_draft')}
      </PrimaryButton>
      <PrimaryButton
        className="mt-3 ml-sm-3"
        onPress={() => {
          setFieldValue('openModal', 'finalize');
          setFieldValue('send_email_notifications', true);
          setFieldValue('send_email_notifications_for_findings', true);
        }}
        disabled={disabled}
      >
        {t('footer.finalize', { model_name: modelT(internal)('model_name') })}
      </PrimaryButton>
      {values.openModal === 'finalize' && (
        <FinalizeModal
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          internal={internal}
          values={values}
        />
      )}
      {values.openModal === 'saveAsDraft' && (
        <SaveModal
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          internal={internal}
          values={values}
        />
      )}
    </div>
  );
};

const FinalizedFooter = ({ handleSubmit, setFieldValue, disabled, values, internal }) => {
  return (
    <div className="d-flex flex-wrap justify-content-end">
      <PrimaryButton
        secondary
        className="mt-3 ml-sm-3"
        onPress={() => {
          window.location.href = `/inspections/new?ref_inspection_id=${values.id}&internal=${internal}`;
        }}
        disabled={disabled}
      >
        {t('footer.create_similar', { model_name: modelT(internal)('model_name') })}
      </PrimaryButton>
      <PrimaryButton
        className="mt-3 ml-sm-3"
        onPress={async () => {
          setFieldValue('send_email_notifications', true);
          await setFieldValue('openModal', 'reopen');
        }}
        disabled={disabled}
      >
        {t('footer.reopen')}
      </PrimaryButton>
      {values.openModal === 'reopen' && (
        <ReopenModal
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          disabled={disabled}
          values={values}
          internal={internal}
        />
      )}
    </div>
  );
};

const DeleteButton = ({ setFieldValue, disabled, handleSubmit, internal }) => (
  <DeletionLink
    disabled={disabled}
    modelName={modelT(internal)('model_name')}
    onDelete={async () => {
      await setFieldValue('submit', 'delete');
      handleSubmit();
    }}
  />
);

const SendNotificationExplanation = () => (
  <p
    style={{ fontSize: '14px' }}
    dangerouslySetInnerHTML={{
      __html: t('hints.saving_modal'),
    }}
  />
);

const SendNotificationCheckbox = ({ setFieldValue, values, internal }) => (
  <label
    className="d-flex flex-row align-items-center"
    htmlFor="inspection_send_email_notifications"
  >
    <CheckboxInput
      id="inspection_send_email_notifications"
      name="send_email_notifications"
      checked={values.send_email_notifications}
      onChange={() => setFieldValue('send_email_notifications', !values.send_email_notifications)}
    />
    <span className="margin-left-10">{modelT(internal)('send_email_notifications')}</span>
  </label>
);

const SendEmailNotificationsForFindingsCheckbox = ({ setFieldValue, values, internal }) => (
  <label
    className="d-flex flex-row align-items-center"
    htmlFor="inspection_send_email_notifications_for_findings"
  >
    <CheckboxInput
      id="inspection_send_email_notifications_for_findings"
      name="send_email_notifications_for_findings"
      checked={values.send_email_notifications_for_findings}
      onChange={() =>
        setFieldValue(
          'send_email_notifications_for_findings',
          !values.send_email_notifications_for_findings,
        )
      }
    />
    <span className="margin-left-10">
      {modelT(internal)('send_email_notifications_for_findings')}
    </span>
  </label>
);

const SendingEmailsForFindingsExplanation = ({ values }) => (
  <div className="form-warning" style={{ marginTop: '-15px' }}>
    {values.send_email_notifications_for_findings
      ? t('send_email_notifications_for_findings_explanation')
      : t('not_send_email_notifications_for_findings_explanation')}
  </div>
);

export const InspectionFooter = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  internal,
  admin_access,
  isLoadingQuestions,
}) => {
  if (values.status === 'finalized') {
    return (
      <FinalizedFooter
        values={values}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        disabled={isSubmitting || isLoadingQuestions}
        internal={internal}
      />
    );
  }
  return (
    <NotFinalizedFooter
      internal={internal}
      handleSubmit={handleSubmit}
      setFieldValue={setFieldValue}
      disabled={isSubmitting || isLoadingQuestions}
      values={values}
      admin_access={admin_access}
    />
  );
};
