import { createReducer, createActions } from 'reduxsauce'
import _ from 'lodash'

/** @description:
 * Higher order function that given a resource name
 * returns actionCreators, actionTypes and reducers that support following actions:
 * - saving, updating single record or collection - save action
 * - removing single record or collection - destroy action
 * - triggering asynchronous requests: show, index
 * */

export const reduxEntitiesManagementBuilder = (resourceName) => {
  const INITIAL_STATE = {}
  const prefix = `${resourceName}/`
  const { Types, Creators } = createActions({
    save: ['data'],
    destroy: ['data'],
    clear: null,
    indexRequest: null,
    showRequest: null,
  }, { prefix })

  const clearHandler = (_) => INITIAL_STATE

  const saveHandler = (state, { data }) => {
    const dataToBeSaved = _.flattenDeep([data])
    let newState = _.cloneDeep(state)
    newState = dataToBeSaved.reduce((acc, current) => {
      acc[current.id] = current
      return acc
    }, newState)

    return newState
  }

  const destroyHandler = (state, { data }) => {
    const dataToBeRemoved = _.flattenDeep([data])
    let newState = _.cloneDeep(state)

    newState = dataToBeRemoved.reduce((acc, current) => {
      delete acc[current.id]
      return acc
    }, newState)

    return newState
  }

  const Reducer = createReducer(INITIAL_STATE, {
    [Types.SAVE]: saveHandler,
    [Types.DESTROY]: destroyHandler,
    [Types.CLEAR]: clearHandler,
  })

  return { Types, Creators, Reducer }
}
