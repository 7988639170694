import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonLink } from './ButtonLink';
import { SaluteModal } from '../modals/SaluteModal';
import {PrimaryButton} from "./PrimaryButton";

const WarningTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

const Icon = styled.i`
  font-size: 65px;
  color: #cad0e3;
  margin: auto;
`;

export const DeletionLink = ({ onDelete, modelName, disabled }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <ButtonLink
        onClick={e => {
          e.preventDefault();
          setModalOpen(true);
        }}
        secondary
        fontSizeVariant="textMd"
        className="text-danger"
        disabled={disabled}
      >
        <i className="fas fa-trash mr-2" />
        {`Delete ${modelName}`}
      </ButtonLink>
      <SaluteModal
        isOpen={modalOpen}
        onCloseClick={() => {
          setModalOpen(false);
        }}
      >
        <div className="text-center mt-5">
          <Icon className="saluteicons saluteicons-warning" />
        </div>
        <WarningTitle className="mt-4">
          {I18n.t('delete_resource_modal.warning', { model_name: modelName })}
        </WarningTitle>
        <div className="text-right mt-4">
          <ButtonLink
            secondary
            onClick={() => setModalOpen(false)}
            fontSizeVariant="textMd"
            disabled={disabled}
          >
            {I18n.t('shared_modal.cancel')}
          </ButtonLink>
          <PrimaryButton onPress={onDelete} disabled={disabled}>
            {I18n.t('delete_resource_modal.confirmation', { model_name: modelName })}
          </PrimaryButton>
        </div>
      </SaluteModal>
    </>
  );
};
