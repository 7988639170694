import React from 'react';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';

const t = key => I18n.t(`inspection_findings.form.${key}`);

const NotFinalizedFooter = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  findingProps,
  onCancel,
  handleChange,
}) => {
  return (
    <>
      <div className="mt-3 d-flex flex-wrap justify-content-end">
        <div className="mr-auto d-flex justify-content-start flex-wrap">
          {findingProps.adminAccess && values.id && (
            <DeleteButton
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
        <CancelButton onCancel={onCancel} />
        <PrimaryButton
          secondary
          className="mt-3 ml-sm-3"
          onPress={async (...args) => {
            await setFieldValue('submit', 'draft');
            handleSubmit(...args);
          }}
          disabled={isSubmitting}
          style={{ minWidth: '185px' }}
        >
          {t('draft')}
        </PrimaryButton>
        <PrimaryButton
          className="mt-3 ml-sm-3"
          onPress={async (...args) => {
            await setFieldValue('submit', 'finalize');
            handleSubmit(...args);
          }}
          disabled={isSubmitting}
          style={{ minWidth: '185px' }}
        >
          {t('finalize')}
        </PrimaryButton>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <label className="mb-0" style={{ padding: '0.375rem 0.75rem' }}>
          {t('create_similar')}
          <input
            type="checkbox"
            value={values.create_similar}
            name="create_similar"
            onChange={handleChange}
            className="ml-1"
          />
        </label>
      </div>
    </>
  );
};

const FinalizedFooter = ({ handleSubmit, setFieldValue, isSubmitting, onCancel }) => {
  return (
    <div className="mt-2 d-flex flex-wrap justify-content-end">
      <CancelButton onCancel={onCancel} />

      <PrimaryButton
        onPress={async (...args) => {
          await setFieldValue('submit', 'reopen');
          handleSubmit(...args);
        }}
        disabled={isSubmitting}
        style={{ minWidth: '185px' }}
        className="mt-3 ml-sm-3"
      >
        {t('reopen')}
      </PrimaryButton>
    </div>
  );
};

const DeleteButton = ({ setFieldValue, isSubmitting, handleSubmit }) => (
  <ButtonLink
    secondary
    fontSizeVariant="textMd"
    className="mt-3 mr-sm-3 text-danger"
    onClick={async (...args) => {
      if (window.confirm('Are you sure you want to delete this Finding?')) {
        await setFieldValue('submit', 'delete');
        handleSubmit(...args);
      }
    }}
    disabled={isSubmitting}
  >
    <i className="fas fa-trash mr-2" />
    {t('delete')}
  </ButtonLink>
);

const CancelButton = ({ onCancel }) => (
  <ButtonLink secondary className="mt-3 ml-sm-3" onClick={onCancel} fontSizeVariant="textMd">
    {t('modals.cancel')}
  </ButtonLink>
);

export const FindingFooter = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  findingProps,
  closeModal,
  handleChange,
}) => {
  if (values.status === 'finalized') {
    return (
      <FinalizedFooter
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
        onCancel={closeModal}
      />
    );
  }
  return (
    <NotFinalizedFooter
      handleSubmit={handleSubmit}
      setFieldValue={setFieldValue}
      isSubmitting={isSubmitting}
      values={values}
      findingProps={findingProps}
      onCancel={closeModal}
      handleChange={handleChange}
    />
  );
};
