import { transformNaNValue } from '../../services/formHelper';
import { YupValidator } from '../../validators/YupValidator';
import { isQuestionVisible } from './helpers';

const validationSchema = YupValidator.object().shape({
  inspection_type_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  responsible_person_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  date_inspected: YupValidator.string()
    .nullable(true)
    .required(I18n.t('validations.required')),
  investigable_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  status: YupValidator.string(),
  submit: YupValidator.string(),
  internal: YupValidator.boolean(),
  people_group_id: YupValidator.number()
    .transform(transformNaNValue)
    .when('internal', {
      is: true,
      then: YupValidator.number()
        .transform(transformNaNValue)
        .required(I18n.t('validations.required')),
    }),
  inspection_checklist_questions_attributes: YupValidator.array().when('submit', {
    is: 'finalize',
    then: YupValidator.array().of(
      // validation with 'test' method is used instead of 'shape' and 'required' methods
      // combination to avoid necessity to use 'reach' method to perform this schema validation
      // (default max nesting level would be exceeded then)
      YupValidator.object().test(
        'required_answer_presence',
        I18n.t('validations.inspection.question_required_to_finalize'),
        (question, { parent: allQuestions, from }) => {
          const { required, answer } = question;
          if (!required || answer) return true;

          return !isQuestionVisible(question, allQuestions, from[1].value.hiddenDefinitionsIds);
        },
      ),
    ),
    otherwise: YupValidator.array(),
  }),
});

export default validationSchema;
