import React from 'react';
import styled from 'styled-components';
import { inputMetrics } from '../../../Style/metrics';
import { colors } from '../../../Style/colors';
import { fontSize } from '../../../Style/typography';

const StyledPresenter = styled.div`
  width: 100%;
  padding: ${inputMetrics.padding};
  font-size: ${fontSize.textMd};
  line-height: ${inputMetrics.lineHeight};
  color: ${colors.inputTextColor};
  background-color: ${colors.disabled};
  border: 1px solid ${colors.inputBorderColor};
  border-radius: ${inputMetrics.fieldRadius};
`;

const AdditionalTextWrapper = styled.div`
  margin: 2px 2px 0;
  font-size: ${fontSize.textXsm};
  color: ${colors.gray};
`;

export const HtmlPresenter = props => {
  const { value, additionalInformation } = props;

  return (
    <div>
      <StyledPresenter dangerouslySetInnerHTML={{ __html: value }} />
      {additionalInformation ? (
        <AdditionalTextWrapper>{additionalInformation}</AdditionalTextWrapper>
      ) : null}
    </div>
  );
};
