import styled from 'styled-components';

export const FormContainer = styled.div`
  background: ${props => props.background || 'white'};
  border-radius: 4px;
  border: ${props => props.border || '1px solid rgba(0, 0, 0, 0.15)'};
  padding: ${props => props.padding || '15px' };
  color: #2f3639;
  margin-top: ${props => props.marginTop || '20px' };
  position: relative;
`;
