export const newAlsoNotifiedPeopleAttributes = (currentPeopleAssignments, newPeopleIds) => {
  const uniqNewPeopleIds = Array.from(new Set(newPeopleIds))
  const newPeopleAssignments = []

  // Add new assignments or halt destroy on assignment
  uniqNewPeopleIds.forEach(personId => {
    const personAssignment = currentPeopleAssignments.find(currentPersonAssignment =>
      currentPersonAssignment.person_id === personId
    )

    if (personAssignment) {
      newPeopleAssignments.push({ ...personAssignment, _destroy: false })
    } else {
      newPeopleAssignments.push({
        person_id: personId,
        _destroy: false,
      })
    }
  })

  // Set _destroy to true if exists in DB and is not selected
  currentPeopleAssignments.forEach(currentPersonAssignment => {
    const personAssignment = uniqNewPeopleIds.find(personId => personId === currentPersonAssignment.person_id)

    if (!personAssignment && !!currentPersonAssignment.id) {
      newPeopleAssignments.push({...currentPersonAssignment, _destroy: true})
    }
  })

  return newPeopleAssignments
}

export const alsoNotifiedPeople = (alsoNotifiedPeopleAttributes) =>
  alsoNotifiedPeopleAttributes
    .filter(object => !object._destroy)
    .map(object => { return object.person_id })
