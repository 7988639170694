/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//
// ----->  PLACE YOUR IMPORTS HERE (replace this entire comment with them)  <-----
//
import 'react-dates/lib/css/_datepicker.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// 'react-rails' configuration until EOF
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
