import * as Yup from 'yup';

const countDecimalPlaces = value => {
  // eslint-disable-next-line no-restricted-globals
  if (!isFinite(value)) return true;
  let e = 1;
  let p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p += 1;
  }
  return p;
};

function precisionFunc(precision = 2, message = 'Error precision') {
  return this.test({
    message,
    name: 'precision',
    exclusive: true,
    params: { precision },
    test(value) {
      return countDecimalPlaces(value) <= precision;
    },
  });
}

Yup.addMethod(Yup.number, 'precision', precisionFunc);

function transformNaNValue() {
  return this.transform(value => (Number.isNaN(value) ? undefined : value));
}
Yup.addMethod(Yup.number, 'transformNaNValue', transformNaNValue);

export const CustomYupValidator = Yup;
export default CustomYupValidator;
