/** Lib */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/** Style */
import { OverflowSensitiveText } from '../../../../stateless_components/style_wrappers/OverflowSensitiveText'
import { fontWeights } from '../../../../Style/typography'

const StyledOptionHeader = styled.div`
  background: transparent;
  font-weight: ${fontWeights.headings};
`
export const OptionHeader = ({ children }) => {
  return (
    <StyledOptionHeader>
      <OverflowSensitiveText>{children}</OverflowSensitiveText>
    </StyledOptionHeader>
  )
}

OptionHeader.propTypes = {
  children: PropTypes.string.isRequired,
}
