
export const headerMetrics = {
  leftPadding: '10px',
  marginTop: '1rem',
  marginBottom: '1.9rem',
}

export const sectionMetrics = {
  topOffset: '1rem',
  bottomOffset: '1rem',
  underlineOffset: '8px',
}

export const inputMetrics = {
  fieldHeight: '36px',
  fieldRadius: '4px',
  padding: '12px',
  lineHeight: '1.25',
  labelElementsOffset: '4px',
}

export const errorMetrics = {
  margin: '10px 0 5px 0',
}

export const modalHeaderMetrics = {
  leftPadding: '25px',
  topPadding: '1.5rem',
  bottomPadding: '1.0rem',
}

export const modalCloseButtonMetrics = {
  top: '5px',
  right: '10px',
}

export const modalSectionMetrics = {
  sidePadding: '25px',
  topPadding: '0.5rem',
  bottomPadding: '2rem',
}

export const modalFooterMetrics = {
  sidePadding: '0',
  topPadding: '2rem',
  bottomPadding: '1rem',
}
