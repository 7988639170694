/** Lib */
import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { connect } from 'react-redux'
/** Action Creators */
import { modalSetupActionCreators } from '../../redux/views/Modals/setupRedux'
/** Style */
import { colors } from '../../Style/colors'
import { fontSize, fontWeights } from '../../Style/typography'
import { modalCloseButtonMetrics, modalHeaderMetrics } from '../../Style/metrics'


ReactModal.defaultStyles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1060',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}

const Wrapper = styled.div`
  background: ${colors.grayBackgroundColor};
  width: 90%;
  max-width: 800px;
  margin: 2.5rem auto 1.5rem;
`;

const ModalHeader = styled.h1`
  font-size: ${fontSize.textXlg};
  font-weight: ${fontWeights.headers};
  color: ${colors.grayDark};
  padding-left: ${modalHeaderMetrics.leftPadding};
  padding-top: ${modalHeaderMetrics.topPadding};
  padding-bottom: ${modalHeaderMetrics.bottomPadding};
`

const CloseButtonWrapper = styled.div`
  right: ${modalCloseButtonMetrics.right};
  top: ${modalCloseButtonMetrics.top};
  position: relative;
`

const ModalContent = styled.div`
  padding: 0 25px 25px;
`;

const ModalPartial = ({
  isOpen, children, title, onCloseClick = () => {}, style = {}, wrapperStyle = {}, isModalOpened, closeModal, onAfterOpen = () => {},
}) => (
  <ReactModal isOpen={isOpen || isModalOpened} style={style} onAfterOpen={onAfterOpen}>
    <Wrapper style={wrapperStyle}>
      <CloseButtonWrapper>
        <button
          onClick={() => { onCloseClick(); closeModal() }}
          className="btn btn-close close"
        >&times;
        </button>
      </CloseButtonWrapper>
      {title && <ModalHeader>{title}</ModalHeader>}
      <ModalContent>{children}</ModalContent>
    </Wrapper>
  </ReactModal>
)

ModalPartial.propTypes = {
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  isModalOpened: PropTypes.bool,
  onCloseClick: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
}

ReactModal.setAppElement('body')

const mapStateToProps = (state, props) => ({
  isModalOpened: state.views.modals.modalSetup.openedModal === props.modalId,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalSetupActionCreators.closeModal()),
})

export const Modal = connect(mapStateToProps, mapDispatchToProps)(ModalPartial)
