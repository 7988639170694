/** Lib */
import 'react-dates/initialize';
import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select-next';
import get from 'lodash/get';
import { TextInput } from '../TextInput';
/** Styles */
import './custom_styles.css';
/** Config */
import {
  getConvertedMoment,
  newMoment,
  dateFormatter,
  momentFormatter,
  dateFormatValidator,
} from '../../../../services/dateFormatUtils';
import { prepareTimeFieldOptions } from '../../../../salute_portal/components/fields/auxiliary/prepareTimeFieldOptions';
import { colors } from '../../../../salute_portal/assets/styles/colors';

const MOBILE_TABLET_WIDTH = 1024;

export class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      timeFieldOptions: prepareTimeFieldOptions(),
    };
  }

  selectorStyles = () => {
    return {
      container: provided => ({
        ...provided,
        width: '100%',
        minWidth: '100px',
      }),
      control: (provided, state) => ({
        display: 'flex',
        padding: '0 8px',
        border: state.menuIsOpen
          ? `1px solid ${colors.outline}`
          : `1px solid ${colors.inputBorderColor}`,
        borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
        background: state.isDisabled ? colors.disabled : colors.white,
      }),
      valueContainer: provided => ({
        ...provided,
        padding: 0,
      }),
      placeholder: provided => ({
        ...provided,
        color: colors.inputPlaceholderColor,
      }),
      menu: provided => ({
        ...provided,
        margin: 0,
        borderRadius: '0 0 4px 4px',
        zIndex: 20,
      }),
      menuList: provided => ({
        ...provided,
        padding: 0,
      }),
      option: (provided, state) => ({
        ...provided,
        display: state.data.isHidden ? 'none' : 'block',
        backgroundColor: state.isFocused ? `${colors.optionHoverColor} !important` : 'inherit',
        // eslint-disable-next-line no-nested-ternary
        color: state.isDisabled ? colors.disabled : state.isFocused ? 'white' : 'inherit',
      }),
      singleValue: provided => ({
        ...provided,
        position: 'static',
        transform: 'none',
        margin: '5px 0',
        color: 'inherit',
      }),
      multiValue: provided => ({
        ...provided,
        background: 'none',
        border: `1px solid ${colors.outline}`,
        borderRadius: '4px',
      }),
      indicatorSeparator: provided => ({
        ...provided,
        margin: '7px 0',
      }),
      dropdownIndicator: provided => ({
        ...provided,
        padding: '7px',
      }),
      loadingIndicator: provided => ({
        ...provided,
        padding: '7px',
      }),
      clearIndicator: provided => ({
        ...provided,
        padding: '7px',
      }),
    };
  };

  setValue = date => {
    let newDate = date;
    const timeSuffixMoment =
      getConvertedMoment(this.props.date) ||
      newMoment().set({
        hours: 12,
        minutes: 0,
        seconds: 0,
      });

    if (newDate) {
      newDate = momentFormatter.serverDateTime(
        getConvertedMoment(newDate).set({
          hours: timeSuffixMoment.hours(),
          minutes: timeSuffixMoment.minutes(),
          seconds: 0,
        }),
      );
    }
    this.props.setFieldValue(this.props.name, newDate);
  };

  handleFocusChanged = ({ focused }) => {
    if (!focused) {
      this.props.setFieldTouched(this.props.name);
    }

    this.setState({ focused });
  };

  defaultOnChange = async newValue => {
    const { name } = this.props;
    await this.props.setFieldValue(name, newValue);
    this.props.setFieldTouched(name, true);
  };
  defaultOnBlur = () => {
    const { name } = this.props;
    this.props.setFieldTouched(name, true);
  };

  optionRenderer = labelKey => option =>
      labelKey ? <div>{get(option, labelKey)}</div> : <div>{option}</div>;

  valueRenderer = props => (
    <>
      <div
        className="d-flex align-items-center pr-2"
        style={{ color: colors.inputIcon, paddingBottom: '3px' }}
      >
        <span className="glyphicon glyphicon-time" />
      </div>
      <components.ValueContainer {...props} />
    </>
  );

  timePicker = () => {
    const { date, disabled, onChange, onBlur } = this.props;
    return (
      <Select
        value={{ date, data: dateFormatter.time(date) }}
        options={this.state.timeFieldOptions}
        styles={this.selectorStyles()}
        isDisabled={disabled}
        menuPlacement="auto"
        onInputChange={needle => {
          this.setState({ timeFieldOptions: prepareTimeFieldOptions(needle) });
          return needle;
        }}
        filterOption={() => true}
        onChange={selection => {
          const momentDate = date ? getConvertedMoment(date) : newMoment();
          momentDate.set({ hours: selection.hours, minutes: selection.minutes });
          const newValue = momentFormatter.serverDateTime(momentDate);
          onChange
            ? onChange(newValue, this.defaultOnChange())
            : this.defaultOnChange(newValue);
        }}
        onBlur={e => {
          onBlur ? onBlur(e, this.defaultOnBlur()) : this.defaultOnBlur();
        }}
        formatOptionLabel={option => this.optionRenderer()(option.data)}
        components={{
          ValueContainer: this.valueRenderer,
        }}
      />
    );
  };

  render() {
    const { date, onChange, onBlur, isOutsideRange = false, disabled = false } = this.props;
    const width = window.innerWidth;
    if (!dateFormatValidator.isServerDateTime(date)) {
      throw new Error(
        'Date provided to DatePicker component should match the format YYYY-MM-DD HH:mm:ss ZZ ' +
          '(and hence should be time zone sensitive).',
      );
    }

    const activeDatePicker = () => {
      return (
        <SingleDatePicker
          date={getConvertedMoment(date, true)}
          onDateChange={this.setValue}
          focused={this.state.focused}
          block
          disabled={disabled}
          isOutsideRange={() => isOutsideRange}
          onFocusChange={this.handleFocusChanged}
          id="single-date-picker"
          numberOfMonths={width >= MOBILE_TABLET_WIDTH ? 2 : 1}
        />
      );
    };

    const disabledDatePicker = () => {
      // SingleDatePicker seems difficult to style, this solution makes things easier
      return (
        <TextInput
          value={date && dateFormatter.date(date)}
          disabled={disabled}
          onChange={() => {}}
        />
      );
    };

    return (
      <div className="d-flex w-100 flex-wrap">
        <div className="col-12 col-sm-6 p-0 pr-sm-1">
          {disabled ? disabledDatePicker() : activeDatePicker()}
        </div>
        <div className="col-12 col-sm-6 p-0 pl-sm-1 mt-1 mt-sm-0">
          {/* TimePicker is moved into function, because it is not allowed to type in it when it is declared in render function  */}
          {this.timePicker()}
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  date: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
