/** Lib */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/** Style */
import { sectionMetrics } from '../../Style/metrics';
import { fontSize, fontWeights } from '../../Style/typography';
import InputLabelHint from '../../components/InputLabelHint';

export const SectionHeader = styled.h2`
  padding-top: ${sectionMetrics.underlineOffset};
  font-size: ${fontSize.textLg};
  font-weight: ${fontWeights.headers};
  margin-top: ${sectionMetrics.topOffset};
  margin-bottom: ${sectionMetrics.bottomOffset};
  &:first-child {
    margin-top: 0;
  }
`;

export const FormSection = ({ title, children, hint }) => (
  <>
    <SectionHeader>
      {title}
      {hint && <InputLabelHint hint={hint} />}
    </SectionHeader>
    {children}
  </>
);

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};
