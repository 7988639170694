/** Lib */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/** Components */
import { SaluteSpinner } from '../salute_spinner/index';
import { SaluteOrganizationContext } from '../contexts/SaluteOrganization';
/** Style */
import { colors } from '../../Style/colors';
import { breakpoints } from '../../Style/grid';

export const SIZE = {
  SMALL: 'sm',
  LARGE: 'lg',
};

const StyledButton = styled.button`
  ${props => {
    if (props.secondary) {
      return `background: white;
              border: 1px solid ${colors.inputBorderColor};
              color: black;
              &:focus {
                box-shadow: 0 0 0 0.1rem ${colors.lightGray};
              };
              &:focus, &:hover {
                background: ${colors.lightGray};
                border-color: ${colors.lightGray};
              };`;
    }
  }};
  ${props => (props.size === SIZE.LARGE ? 'padding: 1rem 2rem' : '')}
  ${props => (props.size === SIZE.LARGE ? 'min-width: 200px' : '')}
  ${props => (props.size === SIZE.LARGE ? 'font-size: 14px' : '')}
  ${props => {
    if (props.expandableOnMobile) {
      return ` @media (max-width: ${breakpoints.small}) {
        display: block;
        width: 100%;
      }`;
    }
  }}
`;
export const PrimaryButton = ({
  children,
  disabled = false,
  size = SIZE.LARGE,
  onPress,
  isPending = false,
  expandableOnMobile = true,
  secondary,
  className = '',
  style,
}) => {
  const { Consumer } = SaluteOrganizationContext;

  return (
    <Consumer>
      {_ => (
        <StyledButton
          className={`btn btn-${size} btn-brand ${className}`}
          size={size}
          secondary={secondary}
          expandableOnMobile={expandableOnMobile}
          disabled={disabled || isPending}
          onClick={onPress}
          style={style}
        >
          {!isPending && children}
          {isPending && (
            <div className="d-flex justify-content-center align-items-center">
              <span className="mr-2">Submitting</span>
              <SaluteSpinner />
            </div>
          )}
        </StyledButton>
      )}
    </Consumer>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.values(SIZE)),
  disabled: PropTypes.bool,
  isPending: PropTypes.bool,
  expandableOnMobile: PropTypes.bool,
  secondary: PropTypes.bool,
};
