import React from 'react';
import { components } from 'react-select-next';
import { colors } from '../../../assets/styles/colors';

export const TimeFieldValueContainerRenderer = props => (
  <>
    <div
      className="d-flex align-items-center pr-2"
      style={{ color: colors.inputIcon, paddingBottom: '3px' }}
    >
      <span className="glyphicon glyphicon-time" />
    </div>
    <components.ValueContainer {...props} />
  </>
);
