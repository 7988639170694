/** Lib */
import React, { Component } from 'react';
import styled from 'styled-components';
/** Components */
import { SaluteSpinner } from '../salute_spinner/index';
import { ErrorMessage } from '../inputs/elements/ErrorMessage';
/** Style */
import { colors } from '../../Style/colors';
import { inputStyles } from '../../Style/inputs';

const PageInput = styled.input.attrs({
  type: 'number',
  onChange: props => props.onChange,
  value: props => props.value,
})`
  width: 55px;
  border-radius: 4px;
  border: 1px solid ${colors.inputBorderColor};
  font-size: 14px;
  padding: 4px;
  ${inputStyles.focused}
`;

const PaginationInfoLabel = styled.span`
  margin: 0 8px;
`;

export class PaginationPanel extends Component {
  constructor(props) {
    super(props);

    this.state = { pageInput: 1 };
  }

  componentWillReceiveProps = () => {
    /** @description
     * Since page offset of data being displayed can be changed externally
     * for instance when applying filters we reset pagination to 1 page.
     * The value of page form input should be changed as well. In state 'pageInput' the user's page selection is kept
     * (can be invalid) and page from outside world i.e redux store represents current valid page being requested.
     * */
    if (this.state.pageInput !== this.props.page && typeof this.props.page === 'number') {
      this.setState({ pageInput: parseInt(this.props.page, 10) });
    }
  };

  _handleInputChange = event => {
    const { value } = event.target;
    this.setState({ pageInput: value });
    this._conditionallyChangePage(value);
  };

  _conditionallyChangePage = value => {
    this.setState({ pageInput: value });
    const { onPageSelected, isFetching } = this.props;

    if (this._isWithinValidPages(value) && !isFetching) {
      onPageSelected(value);
    }
  };

  _isWithinValidPages(value) {
    return value >= 1 && value <= this.props.totalPages;
  }

  render() {
    const { totalPages, isFetching, page, pageSize, setPageSize } = this.props;
    const { pageInput } = this.state;

    return (
      <div className="d-flex flex-grow-1 justify-content-between">
        <div className="d-flex flex-row align-items-center justify-content-start">
          <PaginationInfoLabel className="ml-0">Page limit: </PaginationInfoLabel>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[12, 25, 50, 100].map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-end">
          {isFetching && <SaluteSpinner />}

          <span
            className="ml-2"
            onClick={() => {
              this._conditionallyChangePage(pageInput - 1);
            }}
          >
            <i className="fas fa-chevron-left cursor-pointer"></i>
          </span>

          <PaginationInfoLabel>Page</PaginationInfoLabel>
          <PageInput value={pageInput} onChange={this._handleInputChange} />
          <PaginationInfoLabel>{`of ${totalPages}`}</PaginationInfoLabel>

          <span
            onClick={() => {
              this._conditionallyChangePage(pageInput + 1);
            }}
          >
            <i className="fas fa-chevron-right cursor-pointer"></i>
          </span>
        </div>
        {!this._isWithinValidPages(pageInput) && totalPages && (
          <div className={'mt-2'}>
            <ErrorMessage
              size={'12px'}
            >{`Requested page is beyond valid range. Currently viewed page: ${page}`}</ErrorMessage>
          </div>
        )}
      </div>
    );
  }
}
