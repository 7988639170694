import { object } from 'prop-types';
import * as Yup from 'yup';

const countDecimalPlaces = value => {
  if (!isFinite(value)) return true;
  let e = 1;
  let p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p++;
  }
  return p;
};

function precisionValidation(precision = 2, message = 'Error precision') {
  return this.test({
    message,
    name: 'precision',
    exclusive: true,
    params: { precision },
    test(value) {
      return countDecimalPlaces(value) <= precision;
    },
  });
}

Yup.addMethod(Yup.number, 'precision', precisionValidation);

function objectRequiredKeyValidation(key, message = 'Error required') {
  return this.test({
    message,
    name: 'required',
    test(value) {
      return !!(value || {})[key];
    },
  });
}

Yup.addMethod(Yup.object, 'requiredKey', objectRequiredKeyValidation);

export const YupValidator = Yup;
