import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteNumericInput extends Component {
  onChange = event => {
    const value = event.target.value;
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { disabled } = this.props;

    return (
      <div className="input-group-validations" style={{ width: '100%' }}>
        <div className="input-group">
          <input
            name={this.props.resolveInputName(this.props.index, 'answer')}
            type="number"
            className="input-default"
            min={this.props.config && this.props.config.min_value}
            max={this.props.config && this.props.config.max_value}
            disabled={disabled}
            onChange={this.onChange}
            defaultValue={
              this.props.answer || (this.props.config && this.props.config.default_value)
            }
          />
          <div className="input-group-addon input-group-addon-right border-left-1 disabled d-flex justify-content-center align-items-center">
            <span className="integer_question_unit_addon">
              {this.props.config && this.props.config.answer_unit}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

SaluteNumericInput.defaultProps = {
  onChange: () => {},
  answer: '',
};

SaluteNumericInput.propTypes = {
  onChange: PropTypes.func,
  resolveInputName: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.string,
};

export default SaluteNumericInput;
