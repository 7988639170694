import { YupValidator } from '../../validators/YupValidator';

const reopenValidationSchema = YupValidator.object().shape({
  audit_comment: YupValidator.string()
    .nullable(true)
    .required(I18n.t('validations.required')),
  submit: YupValidator.string(),
});

export default reopenValidationSchema;
