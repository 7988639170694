/** Lib */
import PropTypes from 'prop-types'
import styled from 'styled-components'
/** Style */
import { inputMetrics } from '../../../Style/metrics'
import { colors } from '../../../Style/colors'
import { fontSize } from '../../../Style/typography'
import { inputStyles } from '../../../Style/inputs'


export const NumericInput = styled.input.attrs({
  type: 'number',
  name: (props) => props.name,
  value: (props) => props.value,
  onChange: (props) => props.onChange,
  onBlur: (props) => props.handleBlur,
  disabled: (props) => props.disabled,
  min: (props) => props.min || undefined,
})`
  width: 100%;
  padding: ${inputMetrics.padding};
  font-size: ${fontSize.textMd};
  line-height: ${inputMetrics.lineHeight};
  color: ${colors.inputTextColor};
  background: ${(props) => props.disabled ? colors.disabled : colors.white};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: ${inputMetrics.fieldRadius};
  height: ${inputMetrics.fieldHeight};
  ${inputStyles.focused}
`

NumericInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}
