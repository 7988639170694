import { reduxEntitiesManagementBuilder } from './HOR/reduxEntitiesManagementBuilder'

const {
  Creators: wasteManifestItemsActionCreators,
  Reducer: wasteManifestItemsReducer,
  Types: wasteManifestItemsActionTypes,
} = reduxEntitiesManagementBuilder('WASTE_MANIFEST_ITEMS')

const {
  Creators: wasteManifestCollectionActionCreators,
  Reducer: wasteManifestCollectionReducer,
  Types: wasteManifestCollectionActionTypes,
} = reduxEntitiesManagementBuilder('WASTE_MANIFEST_COLLECTION')

export {
  wasteManifestItemsActionCreators,
  wasteManifestItemsReducer,
  wasteManifestItemsActionTypes,
  wasteManifestCollectionActionCreators,
  wasteManifestCollectionReducer,
  wasteManifestCollectionActionTypes,
}

