/** Lib */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
/** Redux */
import { store } from '../../redux/store';
/** Container */
import Form from './Form';

/** == Container ======================================================================= */
export default class WasteManifestForm extends Component {
  render() {
    return (
      <Provider store={store}>
        <Form {...this.props} />
      </Provider>
    );
  }
}
