import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MonitorsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
    this.fetchSelectedOption();
  }

  componentDidMount() {
    const selectorConfig = selectors.find(
      selectorConfig => selectorConfig.selector === this.props.selector,
    );

    const options = {
      allowClear: !$(this.selectRef).prop('required'),
      placeholder: $(this.selectRef).data('placeholder') || 'Please Select',
    };

    handleSelect(this.selectRef, selectorConfig.url, selectorConfig.f, options)
      .on('select2:select', this.onChange)
      .on('select2:selecting', this.confirmChanges)
      .on('select2:unselect', this.onClear);
  }

  componentWillUnmount() {
    $(this.selectRef).select2('destroy');
    $(this.selectRef).off('select2:select');
  }

  fetchSelectedOption = () => {
    if (this.state.value) {
      $.get(
        `/api/fire_drill_location_monitors/${this.state.value}.json?fire_drill_location_id=${this.props.locationID}`,
      ).done(data => {
        const newOption = new Option(data.text, data.id, true, true);
        $(this.selectRef)
          .append(newOption)
          .trigger('change');
      });
    }
  };

  confirmChanges = event => {
    if (this.props.dirtyForm) {
      if (!window.confirm('There are unsaved changes, do you wish to discard them?')) {
        event.preventDefault();
      }
    }
  };

  onChange = event => {
    const value = this.selectRef.value;
    this.setState({ value });
    this.props.onChange(value);
  };

  onClear = event => {
    this.setState({ value: null });
    this.props.onChange(null);
  };

  render() {
    return (
      <div className="row align-items-center">
        <div className="col-lg-3 col-md-4 col-sm-3 col-12 text-lg-right">
          {this.props.label}
        </div>
        <div className="col-lg-6 col-md-8 col-sm-9 col-12">
          <select
            name="MonitorSelector"
            data-fire_drill_location_id={this.props.locationID}
            data-allow-clear={false}
            ref={ref => {
              this.selectRef = ref;
            }}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

MonitorsSelector.defaultProps = {
  onChange: () => {},
  selector: '.ajax-select-fire_drill_location_monitors',
};

MonitorsSelector.propTypes = {
  onChange: PropTypes.func,
  selector: PropTypes.string,
};

export default MonitorsSelector;
