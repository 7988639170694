/** Lib */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
/** Components */
import { Modal } from '../../stateless_components/modals/Modal'
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton'
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink'
/** Selectors */
import { getConfirmationGuardModal } from '../../selectors/modalsSelectors'
/** Action Creators */
import { confirmationGuardActionCreators } from '../../redux/views/Modals/confirmationGuardRedux'
/** Constants */
import { AVAILABLE_MODALS } from '../../redux/views/Modals/setupRedux'
/** Style */
import { colors } from '../../Style/colors'


const IconRow = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  display: flex;
  color: ${colors.grayCross}
  margin-top: 3rem;
`

const MessageRow = styled.div`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

const ConfirmationMessage = styled.p`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`

const ImplicationsMessage = styled.p`
  font-size: 16px;
  font-weight: 100;
  text-align: center;
`

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ConfirmationGuardModalComponent = (props) => {
  const {
    isOpen, onAccept, onReject, confirmationMessage, implicationsMessage,
  } = props

  return (
    <Modal isOpen={isOpen} onCloseClick={onReject}>
      <div>
        <IconRow><i className="saluteicons saluteicons-warning" /></IconRow>
        <MessageRow>
          <ConfirmationMessage>{confirmationMessage}</ConfirmationMessage>
          <ImplicationsMessage>{implicationsMessage}</ImplicationsMessage>
        </MessageRow>
        <ButtonsRow>
          <ButtonLink secondary className="mr-2" onClick={onReject} fontSizeVariant="textMd">
            Cancel
          </ButtonLink>
          <PrimaryButton onPress={onAccept}>Yes</PrimaryButton>
        </ButtonsRow>
      </div>
    </Modal>
  )
}

ConfirmationGuardModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  confirmationMessage: PropTypes.string,
  implicationsMessage: PropTypes.string,
}

const mapStateToProps = (state) => ({
  confirmationMessage: getConfirmationGuardModal(state).confirmationMessage,
  implicationsMessage: getConfirmationGuardModal(state).implicationsMessage,
  isOpen: state.views.modals.modalSetup.openedModal === AVAILABLE_MODALS.CONFIRMATION_GUARD,
})

const mapDispatchToProps = (dispatch) => ({
  onAccept: () => dispatch(confirmationGuardActionCreators.accept()),
  onReject: () => dispatch(confirmationGuardActionCreators.reject()),
})

export const ConfirmationGuardModal = connect(mapStateToProps, mapDispatchToProps)(ConfirmationGuardModalComponent)
