import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ChecklistItemRow from './ChecklistItemRow/ChecklistItemRow';
import CategoryHeader from './CategoryHeader/CategoryHeader';

const renderCategory = categoryName => (
  <CategoryHeader key={`cat-${categoryName}`} name={categoryName} />
);

const renderItem = props => {
  const { item, error, alwaysAllowFindings } = props;

  return (
    <ChecklistItemRow
      {...props}
      key={item.id}
      error={error}
      item={item}
      index={item.id}
      required={item.required}
      alwaysAllowFindings={alwaysAllowFindings}
    />
  );
};

const Checklist = props => {
  const {
    items,
    errors,
    checklistItemRowErrorKey,
    hideItem = () => false,
    commentBtnLabel,
    findingProps,
    disabled,
    resolveInputName,
    setAnswer,
    saveCommentAndFile,
    canOpenCommentFileModal,
    commentFileModalShouldOpenForFindingDefinitionId,
    canOpenFindingsModal,
    findingsModalShouldOpenForFindingDefinitionId,
    requiresExplanationOnNo,
    alwaysAllowFindings,
  } = props;
  const itemCommonProps = {
    disabled,
    commentBtnLabel,
    findingProps,
    resolveInputName,
    setAnswer,
    saveCommentAndFile,
    canOpenCommentFileModal,
    commentFileModalShouldOpenForFindingDefinitionId,
    canOpenFindingsModal,
    findingsModalShouldOpenForFindingDefinitionId,
    requiresExplanationOnNo,
    alwaysAllowFindings,
  };

  const memoizedData = useRef({ categories: {}, items: {} });

  let lastCategoryName = null;

  return (
    <main className="checklist_questions_table">
      {items.map((item, index) => {
        if (hideItem(item)) return <div key={item.id} />;

        const componentsToRender = [];
        const categoryName = item.category_name;
        const error = errors ? errors[`${checklistItemRowErrorKey}[${index}]`] : null;
        const itemProps = { ...itemCommonProps, item, error };

        if (categoryName !== lastCategoryName) {
          let categoryComponent = (memoizedData.current.categories[categoryName] || {}).component;

          if (!categoryComponent) {
            categoryComponent = renderCategory(categoryName);
            memoizedData.current.categories[categoryName] = {
              component: categoryComponent,
            };
          }
          componentsToRender.push(categoryComponent);
          lastCategoryName = categoryName;
        }

        const itemVersion = JSON.stringify(itemProps);
        let itemData = memoizedData.current.items[item.id];

        if (!itemData || itemData.version !== itemVersion) {
          itemData = {
            version: itemVersion,
            component: renderItem(itemProps),
          };
          memoizedData.current.items[item.id] = itemData;
        }
        componentsToRender.push(itemData.component);

        return <div key={item.id}>{componentsToRender}</div>;
      })}
    </main>
  );
};

Checklist.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  resolveInputName: PropTypes.func.isRequired,
  commentBtnLabel: PropTypes.string,
  disabled: PropTypes.bool,
  requiresExplanationOnNo: PropTypes.bool,
  alwaysAllowFindings: PropTypes.bool,
};

Checklist.defaultProps = {
  commentBtnLabel: '+ Comment / File',
  disabled: false,
  requiresExplanationOnNo: false,
  alwaysAllowFindings: false,
};

export default Checklist;
