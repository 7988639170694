import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { validateForm } from '../../services/formHelper';
import Form from './Form';
import validationSchema from './ValidationSchema';
import reopenValidationSchema from './ReopenValidationSchema';
import { API } from '../../services/api';
import { dateFormatter } from '../../services/dateFormatUtils';

const translate = key => I18n.t(`salute_portal.api.violations_controller.${key}`);

const ViolationUtils = {
  apiPaths: {
    create: {
      draft: API.violations.create,
      finalize: API.violations.createAndFinalize,
    },
    update: {
      draft: API.violations.update,
      finalize: API.violations.updateAndFinalize,
      reopen: API.violations.updateAndReopen,
    },
    delete: API.violations.delete,
  },
  displayErrorMessage: (data, deleteError = false) => {
    const { message } = data;
    if (message && message.length > 0) {
      window.flashMessage(message[0], true, 'error');
    } else {
      window.flashMessage(
        translate(`flash_messages.${deleteError ? 'delete_error' : 'error'}`),
        true,
        'error',
      );
    }
  },
  create: (violation, formikProps, submitType) => {
    const { create_similar } = violation;
    return ViolationUtils.apiPaths.create[submitType]({ violation }).then(response => {
      const { data, ok } = response;
      if (ok) {
        window.flashMessage(translate(`flash_messages.success.${snakeCase(submitType)}`));
        ViolationUtils.onSuccessfulChange(
          formikProps,
          create_similar ? ViolationUtils.prepareNewViolationData(violation) : undefined,
        );
      } else {
        ViolationUtils.displayErrorMessage(data);
      }
      formikProps.setSubmitting(false);
    });
  },
  update: (violation, formikProps, submitType) => {
    const { create_similar } = violation;
    return ViolationUtils.apiPaths.update[submitType](violation.id, { violation }).then(
      response => {
        const { data, ok } = response;
        if (ok) {
          window.flashMessage(translate(`flash_messages.success.${snakeCase(submitType)}`));
          ViolationUtils.onSuccessfulChange(
            formikProps,
            create_similar ? ViolationUtils.prepareNewViolationData(violation) : undefined,
          );
        } else {
          ViolationUtils.displayErrorMessage(data);
        }
        formikProps.setSubmitting(false);
      },
    );
  },
  delete: (violation, formikProps) => {
    return ViolationUtils.apiPaths.delete(violation.id).then(response => {
      const { data, ok } = response;
      if (ok) {
        window.flashMessage(translate(`flash_messages.success.delete`));
        ViolationUtils.onSuccessfulChange(formikProps);
      } else {
        ViolationUtils.displayErrorMessage(data, true);
      }
      formikProps.setSubmitting(false);
    });
  },
  prepareNewViolationData: violation => {
    const newViolationData = {};
    const blackList = ['identifier', 'violation_number'];
    Object.keys(ViolationUtils.defaultValues({}, {})).forEach(key => {
      if (!blackList.includes(key)) {
        newViolationData[key] = violation[key];
      }
    });
    return newViolationData;
  },
  onSuccessfulChange: (formikProps, newViolationData = undefined) => {
    const {
      props: { onViolationChange },
    } = formikProps;
    if (onViolationChange) {
      onViolationChange(newViolationData);
    }
  },
  defaultValues: (inspection, current_person) => ({
    identifier: '',
    status: '',
    date_issued: dateFormatter.serverDate(inspection.date_inspected),
    violation_number: '',
    agency_id: inspection.agency_id,
    inspector_name: inspection.agency_inspector_name,
    inspection_type_id: inspection.inspection_type_id,
    location_general: inspection.location_comment,
    violation_type_id: '',
    description: '',
    internal_due_date: '',
    due_date: '',
    violation_comments: '',
    attachments: [],
    links: [],
    responsible_ehs_person_id: inspection.responsible_person,
    responsible_notified_date: '',
    date_closed_official: '',
    cleared_date: '',
    fine_issued: false,
    max_fine: '',
    proposed_fine: '',
    actual_fine: '',
    other_costs: '',
    other_costs_description: '',
    total_cost: '',
    audit_comment: '',
    creator_id: current_person.id,
    inspection_id: inspection.id,
    investigable: inspection.investigable,
    investigable_type: inspection.investigable_type,
    investigable_id: inspection.investigable_id,
    send_email_notifications_for_findings: false,
  }),
};

export const ViolationForm = withFormik({
  validate: values => {
    if (values.submit === 'delete') {
      return {};
    } else if (values.submit === 'reopen') {
      return validateForm(values, reopenValidationSchema);
    }
    return validateForm(values, validationSchema);
  },
  mapPropsToValues: props => {
    return {
      ...ViolationUtils.defaultValues(props.inspection, props.current_person),
      ...props.violation,
    };
  },
  handleSubmit: (values, formikProps) => {
    const { closeModal } = formikProps.props;
    formikProps.setSubmitting(true);
    if (values.submit === 'cancel') {
      formikProps.setSubmitting(false);
      closeModal();
    } else if (values.submit === 'delete') {
      ViolationUtils.delete(values, formikProps);
    } else if (!values.id) {
      ViolationUtils.create(values, formikProps, values.submit);
    } else {
      ViolationUtils.update(values, formikProps, values.submit);
    }
    formikProps.setTouched({});
  },
})(Form);
