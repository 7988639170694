/** Lib */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/** Style */
import { fontSize } from '../../Style/typography';

export const ButtonLink = ({ children, onClick, className, fontSizeVariant = 'textSm' }) => (
  <button
    type="button"
    className={`btn btn-link ${className}`}
    style={{ fontSize: fontSize[fontSizeVariant] }}
    onClick={onClick}
  >
    {children}
  </button>
);

ButtonLink.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
