/** Lib */
import styled from 'styled-components'
/** Style */
import { colors } from '../../Style/colors'

export const GenericBadge = styled.div`
  display: flex;
  width: ${(props) => props.size || '67px'};
  height: ${(props) => props.size || '67px'};
  font-size: ${(props) => props.fontSize || '48px'};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.badge}
`
