/** Lib */
import PropTypes from 'prop-types';
import styled from 'styled-components';
/** Style */
import { inputMetrics } from '../../../Style/metrics';
import { colors } from '../../../Style/colors';
import { fontSize } from '../../../Style/typography';

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
  name: props => props.name,
  checked: props => props.checked,
  onChange: props => props.onChange,
  onBlur: props => props.handleBlur,
  disabled: props => props.disabled,
  placeholder: props => props.placeholder,
})`
  padding: ${inputMetrics.padding};
  font-size: ${fontSize.textMd};
  line-height: ${inputMetrics.lineHeight};
  color: ${colors.inputTextColor};
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: ${inputMetrics.fieldRadius};
  height: ${inputMetrics.fieldHeight};
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

CheckboxInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
