/** Lib */
import { combineReducers } from 'redux'
import { createActions } from 'reduxsauce'
import { reduxListsManagementBuilder } from '../HOR/reduxListsManagementBuilder'


/** Used for dispatching and capturing events by SAGA middleware. Responsible SAGA will delegate side effects to
 * list reducers defined below and entities
 * */
const { Types: wasteManifestActionRequestsTypes, Creators: wasteManifestActionRequestsActionCreators } = createActions({
  fetchTabsDataForWasteManifestForm: ['payload'],
  assignWasteCollectionToCurrentManifestRequest: ['manifestId', 'wasteCollectionRecords'],
  dropWasteCollectionFromCurrentManifestRequest: ['wasteCollectionRecord', 'confirmationMessage', 'implicationsMessage'],
})

/** Redux waste manifest lists */
const {
  Creators: wasteManifestItemsListActionCreators,
  Reducer: wasteManifestItemsListReducer,
  Types: wasteManifestItemsListActionTypes,
} = reduxListsManagementBuilder('WASTE_MANIFEST_ITEMS_LIST')

const {
  Creators: wasteManifestCollectionCoveredListActionCreators,
  Reducer: wasteManifestCollectionCoveredListReducer,
  Types: wasteManifestCollectionCoveredListActionTypes,
} = reduxListsManagementBuilder('WASTE_MANIFEST_COLLECTION_COVERED_LIST')

const {
  Creators: wasteManifestCollectionNotCoveredListActionCreators,
  Reducer: wasteManifestCollectionNotCoveredListReducer,
  Types: wasteManifestCollectionNotCoveredListActionTypes,
} = reduxListsManagementBuilder('WASTE_MANIFEST_COLLECTION_NOT_COVERED_LIST')

/** Waste manifest root reducer */
export const wasteManifestFormReducer = combineReducers({
  wasteManifestItemsList: wasteManifestItemsListReducer,
  wasteManifestCollectionCoveredList: wasteManifestCollectionCoveredListReducer,
  wasteManifestCollectionNotCoveredList: wasteManifestCollectionNotCoveredListReducer,
})

/** Export types and action creators */
export {
  wasteManifestActionRequestsTypes,
  wasteManifestActionRequestsActionCreators,

  wasteManifestItemsListActionCreators,
  wasteManifestItemsListActionTypes,

  wasteManifestCollectionCoveredListActionCreators,
  wasteManifestCollectionCoveredListActionTypes,

  wasteManifestCollectionNotCoveredListActionCreators,
  wasteManifestCollectionNotCoveredListActionTypes,
}
