import React from 'react';
import ReactDOMServer from 'react-dom/server';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import find from 'lodash/find';
import { ErrorMessageContent } from '../components/other/flash/ErrorMessageContent';
import { getUtcMoment, newUtcMoment } from './dateFormatUtils';

const defaultChildrenAttributesAttributeName = 'defaultChildrenAttributes';

export const passDefaultAttributesToChildren = (children, defaultAttributes) => {
  return React.Children.map(children, child => {
    if (!child) {
      return null;
    }
    const newAttributes = {};
    const keys = Object.keys(child.props);
    Object.keys(defaultAttributes).forEach(attributeKey => {
      if (!keys.includes(attributeKey)) {
        newAttributes[attributeKey] = defaultAttributes[attributeKey];
      }
    });
    newAttributes[defaultChildrenAttributesAttributeName] = {
      ...defaultAttributes,
      ...child.props[defaultChildrenAttributesAttributeName],
    };
    return React.cloneElement(child, newAttributes);
  });
};

// returns true if values is not null, undefined or equal to ''
export const isDefined = value => !isNil(value) && value !== '';

// returns true if value represent true -> either as a boolean or as a string
export const isFlagTrue = flag => flag === true || flag === 'true';

export const isCamelCased = str => str === camelCase(str);

export const isSnakeCased = str => str === snakeCase(str);

export const fieldValue = value => (isDefined(value) ? value : '');

export const compareTimes = (time1, time2) => {
  const moments = [getUtcMoment(time1), getUtcMoment(time2)];
  const momentsWithUnifiedDate = moments.map(utcMoment =>
    newUtcMoment().set({
      year: 2000,
      month: 1,
      date: 1,
      hour: utcMoment.hour(),
      minute: utcMoment.minute(),
      second: 0,
      millisecond: 0,
    }),
  );
  return momentsWithUnifiedDate[0].isSameOrBefore(momentsWithUnifiedDate[1]);
};

export const hasKeyStartingWith = (object, path) =>
  Object.keys(object).some(k => k.startsWith(path));

export const filterValues = (values, keys) => {
  const result = {};
  keys.forEach(key => {
    result[key] = values[key];
  });
  return result;
};

export const validateAuxiliaryValues = auxiliaryValues => {
  Object.keys(auxiliaryValues).forEach(key => {
    if (!isCamelCased(key))
      throw new Error(`Erroneous key: '${key}' - auxiliaryValues keys must be camelCased!`);
  });
};

export const validateBackendValuesWhitelist = (backendValuesWhitelist, exceptionsList = []) => {
  backendValuesWhitelist.forEach(value => {
    if (!isSnakeCased(value) && !exceptionsList.includes(value))
      throw new Error(
        `Erroneous value: '${value}' - backendValuesWhitelist values must be snake_cased!`,
      );
  });
};

export const producePreprocessBackendValues = backendValuesWhitelist => backendValues => {
  const result = { ...backendValues };
  if (!result.id) {
    if (backendValuesWhitelist.includes('identifier')) result.identifier = '##########';
  }
  return result;
};

export const producePrepareInitialValues = formFunctions => backendValues => {
  const extract = (key, fallbackValue) =>
    isFunction(formFunctions[key]) ? formFunctions[key](backendValues) : fallbackValue;

  const backendValuesWhitelist = formFunctions.backendValuesWhitelist || [];
  validateBackendValuesWhitelist(
    backendValuesWhitelist,
    formFunctions.purposefullyNotSnakeCasedBackendValues,
  );

  const preprocessedBackendValues = extract(
    'preprocessBackendValues',
    producePreprocessBackendValues(backendValuesWhitelist)(backendValues),
  );

  const auxiliaryValues = extract('auxiliaryValues', formFunctions.auxiliaryValues || {});
  validateAuxiliaryValues(auxiliaryValues);

  return {
    ...filterValues(preprocessedBackendValues, backendValuesWhitelist),
    ...auxiliaryValues,
  };
};

export const prepareFormFunctions = formFunctions => {
  const applyBackendValuesWhitelist = values =>
    filterValues(values, formFunctions.backendValuesWhitelist || []);
  const prepareValuesToSubmit = formikValues => applyBackendValuesWhitelist(formikValues);
  const prepareInitialValues = producePrepareInitialValues(formFunctions);

  return {
    applyBackendValuesWhitelist,
    prepareValuesToSubmit,
    prepareInitialValues,
    ...formFunctions,
  };
};

export const prepareHasManyRelationToSubmit = (initialValues, finalValues) => {
  initialValues = isDefined(initialValues) ? initialValues : [];
  finalValues = isDefined(finalValues) ? finalValues : [];
  const finalValuesIds = finalValues.map(value => value.id);
  const valuesToDelete = initialValues.filter(value => !finalValuesIds.includes(value.id));
  const valuesToDeleteIds = valuesToDelete.map(value => value.id);
  const valuesToAddOrEdit = finalValues.filter(value => !valuesToDeleteIds.includes(value.id));
  return [...valuesToDelete.map(value => ({ id: value.id, _destroy: true })), ...valuesToAddOrEdit];
};

export const updateBreadcrumbs = text => {
  // eslint-disable-next-line no-undef
  const selector = $('.navbar_links a:last-child');
  selector.attr('href', window.location.href);
  selector.text(text);
};

export const showSuccessMessage = message => window.flashMessage(message);

export const showErrorMessage = (mainMessage, additionalMessages = null) => {
  let finalAdditionalMessages = additionalMessages;
  const maximumAdditionalMessagesLength = 2000;
  if (
    typeof finalAdditionalMessages === 'string' &&
    finalAdditionalMessages.length > maximumAdditionalMessagesLength
  ) {
    // unexpected, unhandled errors (caused by 500 on backend), should not be shown at all
    if (finalAdditionalMessages.includes('<!DOCTYPE html>')) finalAdditionalMessages = null;
    else
      finalAdditionalMessages = finalAdditionalMessages
        .substring(0, maximumAdditionalMessagesLength)
        .replace(/\s\S*$/, '...');
  }

  window.flashMessage(
    ReactDOMServer.renderToStaticMarkup(
      <ErrorMessageContent
        mainMessage={mainMessage}
        additionalMessages={finalAdditionalMessages}
      />,
    ),
    true,
    'error',
  );
};

export const showBackendErrorMessage = (mainMessage, backendResponseData = {}) => {
  showErrorMessage(mainMessage, backendResponseData);
};

export const setInitialFlashMessageForNextPage = (message, type) => {
  sessionStorage.setItem('flashMessage', message);
  if (isDefined(type)) {
    sessionStorage.setItem('flashType', type);
  }
};

// eslint-disable-next-line consistent-return
export const produceValidationWithCb = (validationSchema, cb) => values => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    cb(true, {}, values);
    return {};
  } catch (error) {
    const errors = error.inner.reduce((errorsAccumulated, currentError) => {
      return { ...errorsAccumulated, [currentError.path]: currentError.errors[0] };
    }, {});
    cb(false, errors, values);
    return errors;
  }
};

export const produceDefaultValidation = validationSchema => {
  return produceValidationWithCb(validationSchema, (isValid, errors, values) => {
    if (!isValid && values.isBeingSubmitted) {
      showBackendErrorMessage(I18n.t('flash_message.fill_form_first'));
    }
  });
};

export const defaultHandleSubmit = async (setFieldValue, handleSubmit) => {
  await setFieldValue('isBeingSubmitted', true);
  await handleSubmit();
  await setFieldValue('isBeingSubmitted', false);
};

export const handleSubmitWithAfterSave = async (
  setFieldValue,
  handleSubmit,
  afterSave,
  defaultAfterSave = () => {},
  afterSaveKey = 'afterSave',
) => {
  await setFieldValue(afterSaveKey, afterSave);
  await defaultHandleSubmit(setFieldValue, handleSubmit);
  await setFieldValue(afterSaveKey, defaultAfterSave);
};

export const saluteRound = (value, maxDecimalPlaces = window.SALUTE_DEFAULT_FLOAT_SCALE) => {
  if (!isDefined(value)) return '';

  return parseFloat(Number(value).toFixed(maxDecimalPlaces));
};

export const valueWithUnit = (value, unit, emptyPlaceholder = '-') => {
  if (!isDefined(value)) return emptyPlaceholder;
  if (!isDefined(unit)) return saluteRound(value);

  return `${saluteRound(value)} ${unit}`;
};

export const prepareCustomFieldsAnswers = (entityName, definitions, answers = []) => {
  if (Array.isArray(definitions)) {
    return definitions.map(fieldDefinition => {
      const answer = find(
        answers,
        _answer => _answer[`${entityName}_field_definition_id`] === fieldDefinition.id,
      );

      return isDefined(answer)
        ? answer
        : {
            [`${entityName}_field_definition_id`]: fieldDefinition.id,
            answer: '',
          };
    });
  }

  return [];
};
