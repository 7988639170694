/** Lib */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/** Components */
import { FilterPopover } from './Popover'

/** @description
 * Smart table header component supporting popovers for filtering by column and filters indicators, can be also used
 * as a regular header
 * */
export class SaluteTableHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  togglePopoverVisibility = () => {
    this.setState({
      visible: !this.state.visible
    })
  }

  renderLabel = () => {
    const { filterValue, filterBodyInput } = this.props

    if (filterBodyInput) {
      return (
        <ClickableLabel
          id={this._getPopoverTargetId()}
          label={this._getLabel()}
          onClick={this.togglePopoverVisibility}
          toggled={this.state.visible}
          filtered={![null, undefined, ''].includes(filterValue)} />
      )
    }
    return (
      <LabelDefault label={label} />
    )
  }

  _getLabel = () => {
    return I18n.t(`activerecord.attributes.waste_chemical_item.filter_labels.${this.props.filterName}`)
  }

  _getPopoverTargetId = () => {
    return `${this.props.filterName}__label`
  }

  renderPopover = () => {
    const {
      filterBodyInput,
      onSubmit,
      onClear,
      filterValue
    } = this.props

    return filterBodyInput && (
      <FilterPopover
        value={filterValue}
        filterName={this._getLabel()}
        target={this._getPopoverTargetId()}
        popoverOpen={this.state.visible}
        content={filterBodyInput}
        submit={onSubmit}
        clear={onClear}
        close={this.togglePopoverVisibility}
      />
    )
  }

  render() {
    return (
      <HeaderContainer>
        {this.renderLabel()}
        {this.renderPopover()}
      </HeaderContainer>
    )
  }
}

SaluteTableHeader.propTypes = {
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterName: PropTypes.string,
  filterBodyInput: PropTypes.any,
  onClear: PropTypes.func,
  handleBlur: PropTypes.func,
  onSubmit: PropTypes.func,
}


/** Helper components */
const Container = ({ className, children }) => {
  return (<div className={className}>{children}</div>)
}

const FiltredIcon = () => {
  return (
    <span className="glyphicon glyphicon-filter filter-icon" style={{
      color: 'yellow',
      position: 'absolute',
      right: '5%',
      top: '30%',
    }}>
    </span>
  )
}

const Label = ({ label, className, id, onClick, toggled = false, filtered = false }) => {
  className = toggled ? className + ' active' : className

  return (
    <div className={className} id={id} onClick={() => onClick && onClick()}>
      {label}
      { filtered && <FiltredIcon />}
    </div>
  )
}

const HeaderContainer = styled(Container)`
    width: 100%;
    display: flex;
    height: 100%;
`

const LabelDefault = styled(Label)`
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    border-bottom: 4px solid transparent;
    padding-left: 15px;
    padding-right: 15px
    justify-content: center;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  `

const ClickableLabel = styled(LabelDefault)`
    cursor: pointer;
    &.active,
    &:hover {
       border-bottom: 4px solid #75C0CA;
    }
`

