import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inputMetrics } from '../../../Style/metrics';
import { fontSize } from '../../../Style/typography';
import { colors } from '../../../Style/colors';
import { inputStyles } from '../../../Style/inputs';

const CurrencyInputControl = styled.input.attrs({
  name: props => props.name,
  value: props => props.value,
  onChange: props => props.onChange,
  onBlur: props => props.onBlur,
  disabled: props => props.disabled,
})`
  width: 100%;
  padding: ${inputMetrics.padding};
  font-size: ${fontSize.textMd};
  line-height: ${inputMetrics.lineHeight};
  color: ${colors.inputTextColor};
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: ${inputMetrics.fieldRadius};
  height: ${inputMetrics.fieldHeight};
  ${inputStyles.focused}
  text-align: right;
`;

const DollarIcon = styled.i.attrs({
  className: 'fa fa-sm fa-dollar-sign',
})`
  position: absolute;
  left: 25px;
  margin: auto 0;
  top: 0;
  bottom: 0;
`;

const formatCurrency = value => {
  const result = parseFloat(value).toFixed(2);
  return isNaN(result) ? '' : result;
};

export class SaluteCurrencyInput extends Component {
  state = { valueChanged: false, lastValidValue: formatCurrency(this.props.value) };

  produceOnBlur = onChangeFunc => {
    return e => {
      if (!e.target.value) {
        return;
      }
      e.target.value = formatCurrency(e.target.value);
      this.setState({ lastValidValue: e.target.value });
      if (onChangeFunc) {
        onChangeFunc(e);
      }
    };
  };

  onInput = e => {
    const { selectionStart, selectionEnd } = e.target;
    const input = e.target.value;
    const regex = /([^\d\.]|\..*\.|\..{3,}|^\.)/;
    if (Number(input) > 9999999999 || regex.test(input)) {
      e.target.value = this.state.lastValidValue;
      // handle caret
      e.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
    }
    this.setState({ valueChanged: true, lastValidValue: e.target.value });
  };

  render = () => {
    const { onChange, value } = this.props;
    const { valueChanged } = this.state;
    return (
      <div>
        <DollarIcon />
        <CurrencyInputControl
          onInput={this.onInput}
          onBlur={this.produceOnBlur(onChange)}
          onKeyPress={this.onKeyPress}
          {...this.props}
          value={!valueChanged && value ? formatCurrency(value) : value}
        />
      </div>
    );
  };
}
