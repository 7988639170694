import React, { Component } from 'react';
import { FormSection } from '../../stateless_components/form_wrappers/FormSection';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import { getFormikErrors } from '../../services/formHelper';
import { FIELD_WIDTHS, INPUT_TYPES } from '../../stateless_components/inputs/elements/FieldWrapper';
import ObjectSelector from '../Findings/ObjectSelector';
import { ViolationFooter } from './ViolationFooter';
import ViolationFormFindings from './ViolationFormFindings';

const t = key => I18n.t(`activerecord.attributes.violation.${key}`);
const formT = key => I18n.t(`violations.form.${key}`);

class Form extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { isSubmitting, errors, onValidationError } = this.props;
    if (Object.keys(errors).length > 0 && isSubmitting) {
      onValidationError();
    }
  }

  render = () => {
    const {
      handleChange,
      values,
      errors,
      setFieldValue,
      handleBlur,
      touched,
      setFieldTouched,
      enums,
    } = this.props;
    const violationFinalized = values.status === 'finalized';

    return (
      <>
        <FormSection title={formT('overview')}>
          <SaluteInputField
            labelProps={{ label: t('identifier') }}
            inputProps={{
              value: values.identifier,
              name: 'identifier',
              onChange: handleChange,
              disabled: true,
              placeholder: '##############',
              handleBlur,
            }}
            error={getFormikErrors('identifier', touched, errors)}
            type={INPUT_TYPES.TEXT_FIELD}
          />
          <SaluteInputField
            labelProps={{ label: t('status') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.status,
              name: 'status',
              initialSelection: values.status,
              options: () => {
                return {
                  options: violationFinalized
                    ? enums.violation_status
                    : enums.violation_status.filter(({ value }) => value !== 'finalized'),
                  has_more: false,
                };
              },
              handleChange: value => setFieldValue('status', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('status', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: t('date_issued'), required: true }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.date_issued,
              name: 'date_issued',
              setFieldValue,
            }}
            error={getFormikErrors('date_issued', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: t('violation_number'), required: true }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.violation_number,
              name: 'violation_number',
              onChange: handleChange,
            }}
            error={getFormikErrors('violation_number', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.TEXT_FIELD}
          />
          <SaluteInputField
            labelProps={{ label: t('creator_id'), required: true }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.creator_id,
              name: 'creator_id',
              initialSelection: values.creator_id,
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => setFieldValue('creator_id', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('creator_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: t('agency_id') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.agency_id,
              name: 'agency_id',
              initialSelection: values.agency_id,
              apiPath: 'agencies.index',
              intitialSelectionApiPath: 'agencies.show',
              mapInitialSelectionFromApi: e => ({ id: e.id, label: e.text }),
              handleChange: value => setFieldValue('agency_id', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('agency_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: t('inspector_name') }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.inspector_name,
              name: 'inspector_name',
              onChange: handleChange,
            }}
            error={getFormikErrors('inspector_name', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.TEXT_FIELD}
          />
          <SaluteInputField
            labelProps={{ label: t('inspection_type_id') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.inspection_type_id,
              name: 'inspection_type_id',
              initialSelection: values.inspection_type_id,
              apiPath: 'external_inspection_type.index',
              intitialSelectionApiPath: 'external_inspection_type.show',
              handleChange: value => setFieldValue('inspection_type_id', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('inspection_type_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <ObjectSelector required={false} {...this.props} />
          <SaluteInputField
            labelProps={{ label: t('location_general') }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.location_general,
              name: 'location_general',
              onChange: handleChange,
            }}
            error={getFormikErrors('location_general', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.TEXT_FIELD}
          />
        </FormSection>
        <hr />
        <FormSection title={formT('violation')}>
          <SaluteInputField
            labelProps={{ label: t('violation_type_id'), required: true }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.violation_type_id,
              name: 'violation_type_id',
              initialSelection: values.violation_type_id,
              apiPath: 'violation_type.index',
              intitialSelectionApiPath: 'violation_type.show',
              mapInitialSelectionFromApi: item => ({ id: item.id, header: item.text }),
              handleChange: value => setFieldValue('violation_type_id', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('violation_type_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <ViolationFormFindings {...this.props} violationFinalized={violationFinalized} />
          <SaluteInputField
            labelProps={{ label: t('description') }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.description,
              name: 'description',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('description', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: t('internal_due_date') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.internal_due_date,
              name: 'internal_due_date',
              setFieldValue,
            }}
            error={getFormikErrors('internal_due_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: t('due_date') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.due_date,
              name: 'due_date',
              setFieldValue,
            }}
            error={getFormikErrors('due_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: t('violation_comments') }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.violation_comments,
              name: 'violation_comments',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('violation_comments', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: t('attachments') }}
            inputProps={{
              handleBlur,
              value: values.attachments,
              name: 'attachments',
              onChange: handleChange,
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('attachments', touched, errors)}
            type={INPUT_TYPES.FILES_UPLOAD}
          />
          <SaluteInputField
            labelProps={{ label: t('links') }}
            inputProps={{
              handleBlur,
              value: values.links,
              name: 'links',
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('links', touched, errors)}
            type={INPUT_TYPES.LINKS}
          />
        </FormSection>
        <hr />
        <FormSection title={formT('overall_response')}>
          <SaluteInputField
            labelProps={{ label: t('responsible_ehs_person_id'), required: true }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.responsible_ehs_person_id,
              name: 'responsible_ehs_person_id',
              initialSelection: values.responsible_ehs_person_id,
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => setFieldValue('responsible_ehs_person_id', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('responsible_ehs_person_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: t('responsible_notified_date') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.responsible_notified_date,
              name: 'responsible_notified_date',
              setFieldValue,
            }}
            error={getFormikErrors('responsible_notified_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: t('date_closed_official') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.date_closed_official,
              name: 'date_closed_official',
              setFieldValue,
            }}
            error={getFormikErrors('date_closed_official', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: t('cleared_date') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              date: values.cleared_date,
              name: 'cleared_date',
              setFieldValue,
            }}
            error={getFormikErrors('cleared_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
        </FormSection>
        <hr />
        <FormSection title={formT('financial_information')}>
          <SaluteInputField
            labelProps={{ label: t('fine_issued') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.fine_issued,
              name: 'fine_issued',
              initialSelection: values.fine_issued,
              options: () => {
                return {
                  options: [
                    {
                      id: 0,
                      value: false,
                      header: I18n.t('false'),
                    },
                    {
                      id: 1,
                      value: true,
                      header: I18n.t('true'),
                    },
                  ],
                  hasMore: false,
                };
              },
              handleChange: value => setFieldValue('fine_issued', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('fine_issued', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: t('max_fine') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.max_fine,
              name: 'max_fine',
              setFieldValue,
              onChange: handleChange,
            }}
            error={getFormikErrors('max_fine', touched, errors)}
            type={INPUT_TYPES.CURRENCY}
          />
          <SaluteInputField
            labelProps={{ label: t('proposed_fine') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.proposed_fine,
              name: 'proposed_fine',
              setFieldValue,
              onChange: handleChange,
            }}
            error={getFormikErrors('proposed_fine', touched, errors)}
            type={INPUT_TYPES.CURRENCY}
          />
          <SaluteInputField
            labelProps={{ label: t('actual_fine') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.actual_fine,
              name: 'actual_fine',
              setFieldValue,
              onChange: e => {
                handleChange(e);
                setFieldValue('total_cost', Number(e.target.value) + Number(values.other_costs));
              },
            }}
            error={getFormikErrors('actual_fine', touched, errors)}
            type={INPUT_TYPES.CURRENCY}
          />
          <SaluteInputField
            labelProps={{ label: t('other_costs') }}
            inputProps={{
              disabled: violationFinalized,
              setFieldTouched,
              value: values.other_costs,
              name: 'other_costs',
              setFieldValue,
              onChange: e => {
                handleChange(e);
                setFieldValue('total_cost', Number(values.actual_fine) + Number(e.target.value));
              },
            }}
            error={getFormikErrors('other_costs', touched, errors)}
            type={INPUT_TYPES.CURRENCY}
          />
          <SaluteInputField
            labelProps={{ label: t('other_costs_description') }}
            inputProps={{
              disabled: violationFinalized,
              handleBlur,
              value: values.other_costs_description,
              name: 'other_costs_description',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('other_costs_description', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: t('total_cost'), hint: t('total_cost_hint') }}
            inputProps={{
              disabled: true,
              setFieldTouched,
              value: values.total_cost,
              name: 'total_cost',
              setFieldValue,
              onChange: handleChange,
            }}
            error={getFormikErrors('total_cost', touched, errors)}
            type={INPUT_TYPES.CURRENCY}
          />
        </FormSection>
        {violationFinalized ? (
          <>
            <hr />
            <FormSection title="Would you like to reopen this Violation?">
              <SaluteInputField
                labelProps={{ label: t('audit_comment'), required: true }}
                inputProps={{
                  handleBlur,
                  value: values.audit_comment,
                  name: 'audit_comment',
                  onChange: handleChange,
                }}
                fieldWidth={FIELD_WIDTHS.WIDE}
                error={getFormikErrors('audit_comment', touched, errors)}
                type={INPUT_TYPES.TEXT_AREA}
              />
            </FormSection>
          </>
        ) : (
          <>
            <hr />
            <FormSection title={formT('related_findings')}>
              <div className="d-flex row">
                <div className="col-lg-3 col-sm-3 col-md-4 col-xs-12" />
                <div
                  className="col normal-text"
                  dangerouslySetInnerHTML={{ __html: formT('modals.notification_recipients_hint') }}
                />
              </div>
              <SaluteInputField
                labelProps={{ label: formT('send_email_notifications_for_findings') }}
                inputProps={{
                  handleBlur,
                  value: values.send_email_notifications_for_findings,
                  name: 'send_email_notifications_for_findings',
                  onChange: handleChange,
                }}
                error={getFormikErrors('send_email_notifications', touched, errors)}
                fieldWidth={FIELD_WIDTHS.WIDE}
                type={INPUT_TYPES.CHECKBOX}
              />
              <div className="d-flex row">
                <div className="col-lg-3 col-sm-3 col-md-4 col-xs-12" />
                <div className="col form-warning">
                  {values.send_email_notifications_for_findings
                    ? formT('send_email_notifications_for_findings_explanation')
                    : formT('not_send_email_notifications_for_findings_explanation')}
                </div>
              </div>
            </FormSection>
          </>
        )}
        <ViolationFooter {...this.props} />
      </>
    );
  };
}

export default Form;
