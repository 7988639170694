import React, { Component } from 'react';
import { SaluteSpinner } from '../../stateless_components/salute_spinner/index';
import { InspectionFunctions, InspectionForm } from './InspectionForm';
import { SaluteOrganizationContext } from '../../stateless_components/contexts/SaluteOrganization';

class Inspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspection: {},
      fetchInspection: !props.params.id,
    };
  }

  componentDidMount = () => {
    this.fetchInspection();
  };

  fetchInspection = () => {
    this.setState({ fetchInspection: true }, () => {
      const { id } = this.props.params;
      const fetchEndpoint = id
        ? InspectionFunctions.apiPaths[
            this.props.internal_inspection ? 'internal' : 'external'
          ].show(id)
        : InspectionFunctions.apiPaths[
            this.props.internal_inspection ? 'internal' : 'external'
          ].new(this.props.params);
      fetchEndpoint.then(response => {
        this.setState({
          inspection: response.data.data.attributes,
          fetchInspection: false,
        });
      });
    });
  };

  updateInspection = inspection => {
    this.setState({ inspection });
  };

  assessmentQueue = () => {
    const { inspection } = this.state;
    if (inspection.assessment_queue && Object.keys(inspection.assessment_queue.data).length > 0) {
      const assessmentQueue = inspection.assessment_queue.data;
      return (
        <>
          <div className="row mt-3">
            <div className="col-12">
              <div className="container_box no-min-height pl-3">
                <div className="row">
                  <div className="col-12 col-sm-8">
                    <h2>
                      Related Assessment Queue
                      <a href={assessmentQueue.editPath}>{` #${assessmentQueue.identifier}`}</a>
                    </h2>
                  </div>
                  <div className="col-12 col-sm-4 d-flex justify-content-start justify-content-sm-end normal-text">
                    <a href={assessmentQueue.editPath}>View Assessment Queue</a>
                  </div>
                </div>
                <div className="row mt-3 color-gray_dark">
                  <div className="col-6 col-sm-4 col-md-4">
                    <p className="small-text mb-1">
                      {I18n.t('activerecord.attributes.assessment_queue.label')}
                    </p>
                    <h4>{assessmentQueue.label}</h4>
                  </div>
                  <div className="col-6 col-sm-4 col-md-4">
                    <p className="small-text mb-1">
                      {I18n.t('activerecord.attributes.assessment_queue.ehs_inspector_id')}
                    </p>
                    <h4>{assessmentQueue.ehsInspector}</h4>
                  </div>
                  <div className="col-6 col-sm-4 col-md-4">
                    <p className="small-text mb-1">
                      {I18n.t('activerecord.attributes.assessment_queue.due_date')}
                    </p>
                    <h4>{assessmentQueue.dueDate}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return undefined;
  };

  inspectionHeader = () => {
    const { inspection } = this.state;
    const downloadToken = this.props.download_token;
    const title = this.props.internal_inspection ? 'Assessment' : 'External Inspection';
    return (
      <div className="page-header pl-2 pr-2">
        <div className="container mb-3 shared-header form-header">
          <div className="row justify-content-end align-items-center">
            <div className="col pl-0">
              <h1 className="header-title">{`${!inspection.id ? 'New ' : ''}${title}`}</h1>
            </div>
            {inspection.id && (
              <>
                <div className="col-auto">
                  <a
                    href={`/inspections/${inspection.id}/audits`}
                    className="header-link d-flex align-items-center"
                  >
                    <i className="fa fa-list" style={{ marginBottom: '2px' }} />
                    <span className="ml-2"> Audit Log</span>
                  </a>
                </div>
                <div className="col-auto pr-0">
                  <span className="header-link d-flex align-items-center">
                    <span className="mr-2"> Export to:</span>
                    <a
                      href={`/inspections/${inspection.id}.js?download_token=${downloadToken}`}
                      target="_blank"
                      className="report-link header-link"
                      data-download_token={downloadToken}
                      data-remote="true"
                      aria-label={I18n.t('assessment_queues.edit.aria.export_to_pdf')}
                      rel="noreferrer"
                    >
                      <span className="report-download-icon icon icon-pdf report-icon" />
                      <img
                        src="/assets/loading_indicator_110.gif"
                        className="report-download-spinner report-icon-spinner"
                      />
                    </a>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { inspection, fetchInspection } = this.state;

    const { Provider } = SaluteOrganizationContext;
    return (
      <>
        {this.inspectionHeader()}
        {this.assessmentQueue()}
        {!fetchInspection && (
          <Provider value={this.props.current_organization.short_name}>
            <InspectionForm
              initialInspection={inspection}
              setNewInitialInspection={this.updateInspection}
              internal={this.props.internal_inspection}
              {...this.props}
            />
          </Provider>
        )}
        {fetchInspection && <SaluteSpinner style={{ margin: 'auto' }} name="circle" />}
      </>
    );
  }
}

export default Inspection;
