import { transformNaNValue } from '../../services/formHelper';
import { YupValidator } from '../../validators/YupValidator';
import {
  getUtcMomentIgnoringTime,
  dateFormatter,
  newUtcMoment,
} from '../../salute_portal/services/dateFormatUtils';
import { newMoment } from '../../community_portal/assets/helpers/DateFormatHelper';

const t = (key, attributes = {}) => I18n.t(`activerecord.errors.models.${key}`, attributes);

const validationSchema = YupValidator.object().shape(
  {
    creator_id: YupValidator.number()
      .transform(transformNaNValue)
      .required(I18n.t('validations.required'))
      .positive(I18n.t('validations.positive')),
    follow_up_required: YupValidator.string()
      .nullable(true)
      .required(I18n.t('validations.required')),
    people_group_id: YupValidator.number()
      .transform(transformNaNValue)
      .when('internal', {
        is: true,
        then: YupValidator.number()
          .transform(transformNaNValue)
          .required(I18n.t('validations.required')),
      }),
    responsible_person_id: YupValidator.number()
      .transform(transformNaNValue)
      .required(I18n.t('validations.required'))
      .positive(I18n.t('validations.positive')),
    corrected_comments: YupValidator.string()
      .nullable(true)
      .when(['submit', 'follow_up_required'], {
        is: (submit, followUpRequired) => submit === 'finalize' && followUpRequired === 'true',
        then: YupValidator.string()
          .nullable(true)
          .required(I18n.t('validations.required')),
      })
      .when(['status', 'follow_up_required'], {
        is: (status, followUpRequired) => status === 'completed' && followUpRequired === 'true',
        then: YupValidator.string()
          .nullable(true)
          .required(I18n.t('validations.required')),
      }),
    corrected_date: YupValidator.string()
      .nullable(true)
      .when(['submit', 'follow_up_required'], {
        is: (submit, followUpRequired) => submit === 'finalize' && followUpRequired === 'true',
        then: YupValidator.string()
          .nullable(true)
          .required(I18n.t('validations.required')),
      })
      .when(['status', 'follow_up_required'], {
        is: (status, followUpRequired) => status === 'completed' && followUpRequired === 'true',
        then: YupValidator.string()
          .nullable(true)
          .required(I18n.t('validations.required')),
      }),
    initiated_at: YupValidator.string()
      .nullable(true)
      .required(I18n.t('validations.required'))
      .test(
        'initiated_date_cannot_be_later_than_due_date_and_corrected_date',
        t('inspection_finding.attributes.initiated_at.cannot_be_later_than_and', {
          date_1: I18n.t('activerecord.attributes.inspection_finding.due_date'),
          date_2: I18n.t('activerecord.attributes.inspection_finding.corrected_date'),
        }),
        function(initiatedDate) {
          const { corrected_date, due_date } = this.parent;
          if (!initiatedDate) return true;

          const initiatedDateMoment = getUtcMomentIgnoringTime(
            dateFormatter.serverDate(initiatedDate),
          );

          const compliesWithCorrectedDate =
            !corrected_date ||
            initiatedDateMoment.isSameOrBefore(getUtcMomentIgnoringTime(corrected_date));
          const compliesWithDueDate =
            !due_date || initiatedDateMoment.isSameOrBefore(getUtcMomentIgnoringTime(due_date));

          return compliesWithCorrectedDate && compliesWithDueDate;
        },
      )
      .test(
        'initiated_date_cannot_be_later_than_current_date',
        t('inspection_finding.attributes.initiated_at.cannot_be_later_than_current_date', {
          current_date: newMoment().format('YYYY-MM-DD'),
        }),
        initiatedDate => {
          if (!initiatedDate) return true;

          const initiatedDateMoment = getUtcMomentIgnoringTime(
            dateFormatter.serverDate(initiatedDate),
          );
          return initiatedDateMoment.isSameOrBefore(newUtcMoment());
        },
      ),
    status: YupValidator.string()
      .nullable(true)
      .required(I18n.t('validations.required')),
    submit: YupValidator.string(),
    internal: YupValidator.boolean(),
    investigable_id: YupValidator.number()
      .transform(transformNaNValue)
      .required(I18n.t('validations.required'))
      .positive(I18n.t('validations.positive')),
  },
  ['corrected_comments', 'submit', 'follow_up_required'],
  ['corrected_date', 'submit', 'follow_up_required'],
);

export default validationSchema;
