import find from 'lodash/find';
import every from 'lodash/every';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';

export const isQuestionVisible = (question, allQuestions, hiddenDefinitionsIds) => {
  if ((question.findings_count || 0) > 0) return true;

  const config = question.visibility_conditions_config || {};
  const { conditions = [], logical_operator_type = 'and' } = config;
  const predicate = condition => {
    if (hiddenDefinitionsIds.includes(condition.inspection_finding_definition_id)) return false;

    const relatedQuestion = find(
      allQuestions,
      _question =>
        _question.inspection_finding_definition_id === condition.inspection_finding_definition_id,
    );

    return String(relatedQuestion.answer) === String(condition.expected_answer);
  };

  if (logical_operator_type === 'and') return every(conditions, predicate);
  return some(conditions, predicate);
};

export const getHiddenDefinitionsIds = (allQuestions, processingOrder) => {
  const result = [];

  const processingOrderDict = {};
  // add 1 to evade 0 which is falsy
  processingOrder.forEach((id, index) => {
    processingOrderDict[id] = index + 1;
  });
  const sortPredicate = question =>
    processingOrderDict[question.inspection_finding_definition_id] || processingOrder.length + 1;

  sortBy(allQuestions, sortPredicate).forEach(question => {
    if (!isQuestionVisible(question, allQuestions, result))
      result.push(question.inspection_finding_definition_id);
  });

  return result;
};
