export const fontSize = {
  textXsm: '12px',
  textSm: '14px',
  textMd: '16px',
  textLg: '18px',
  textXlg: '24px',
}

export const fontWeights = {
  headings: '600',
  headers: '900',
}
