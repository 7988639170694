import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import fieldDisplayModes from '../../../constants/fieldDisplayModes';
import { DefaultFormRow } from './fieldWrapperVariants/DefaultFormRow';
import { FullWidthFormRow } from './fieldWrapperVariants/FullWidthFormRow';
import { InputFirstFormRow } from './fieldWrapperVariants/InputFirstFormRow';
import { GrowingWidthInput } from './fieldWrapperVariants/GrowingWidthInput';
import { InputFirstGrowingWidthInput } from './fieldWrapperVariants/InputFirstGrowingWidthInput';
import { WideFormRow } from './fieldWrapperVariants/WideFormRow';
import { InputFirstGrowingWidthLabel } from './fieldWrapperVariants/InputFirstGrowingWidthLabel';

export const FieldWrapper = props => {
  const { displayMode = fieldDisplayModes.DEFAULT_FORM_ROW, children, ...otherProps } = props;
  if (displayMode === fieldDisplayModes.DEFAULT_FORM_ROW)
    return <DefaultFormRow {...otherProps}>{children}</DefaultFormRow>;
  if (displayMode === fieldDisplayModes.WIDE_FORM_ROW)
    return <WideFormRow {...otherProps}>{children}</WideFormRow>;
  if (displayMode === fieldDisplayModes.FULL_WIDTH_FORM_ROW)
    return <FullWidthFormRow {...otherProps}>{children}</FullWidthFormRow>;
  if (displayMode === fieldDisplayModes.INPUT_FIRST_FORM_ROW)
    return <InputFirstFormRow {...otherProps}>{children}</InputFirstFormRow>;
  if (displayMode === fieldDisplayModes.GROWING_WIDTH_INPUT)
    return <GrowingWidthInput {...otherProps}>{children}</GrowingWidthInput>;
  if (displayMode === fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_INPUT)
    return <InputFirstGrowingWidthInput {...otherProps}>{children}</InputFirstGrowingWidthInput>;
  if (displayMode === fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_LABEL)
    return <InputFirstGrowingWidthLabel {...otherProps}>{children}</InputFirstGrowingWidthLabel>;
  if (displayMode === fieldDisplayModes.NO_WRAPPER) return children;
  return <></>;
};
