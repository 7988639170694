import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class IndeterminateCheckbox extends Component {
  componentDidMount() {
    this.checkboxRef.indeterminate = this.props.indeterminate
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.checkboxRef.indeterminate = this.props.indeterminate
    }
  }

  bindRef = (input) => {
    if (input) {
      this.checkboxRef = input
    }
  }

  render() {
    const { indeterminate } = this.props
    return (
      <input {...this.props} indeterminate={indeterminate.toString()} type="checkbox" ref={this.bindRef} />
    )
  }
}

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
}

IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
}
