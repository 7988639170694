import React, { PureComponent } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import _ from 'lodash';
import { UploadIcon } from '../../stateless_components/icons/UploadIcon';
import { ErrorMessage } from '../../stateless_components/inputs/elements/ErrorMessage';
import { ProgressBar } from '../../stateless_components/other/ProgressBar';
import { DownloadIcon } from '../../stateless_components/icons/DownloadIcon';
import { bytesToMb } from '../../services/fileSizeHelper';
import InputLabelHint from '../../salute_portal/components/fields/auxiliary/InputLabelHint';
import {
  Error,
  FilesFieldContainer,
  HintWrapper,
  Label,
  Required,
  Row,
  StyledAttachmentLink,
  StyledAttachmentNameSpan,
  StyledAttachmentRow,
  StyledAttachmentsTable,
  StyledMockUploadButton,
  StyledMockUploadButtonTitle,
  StyledRemoveAttachmentButton,
} from '../../stateless_components/inputs/elements/FileUploadInputs/AuxiliaryStyledComponents';

const AttachmentRow = ({ id, pathString, filename, removeAttachment }) => (
  <StyledAttachmentRow>
    <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
    <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
      <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
    </StyledAttachmentLink>
    <StyledRemoveAttachmentButton
      id={`${id}-remove-button`}
      type="button"
      onClick={e => removeAttachment(e)}
    >
      {I18n.t('file_uploader.delete_file')}
    </StyledRemoveAttachmentButton>
  </StyledAttachmentRow>
);

export default class FilesField extends PureComponent {
  // This component is used in haml files so it does not use Formik.
  // The logic is based on the FilesUploadInput component from the
  // stateless_components/inputs/elements/FileUploadInputs directory.
  constructor(props) {
    super(props);
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
      value: _.reject([props.value], _.isEmpty),
    };
  }

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t(
        'file_uploader.errors.too_large_file_unit',
      )}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = signedUrl => {
    const downloadPath = signedUrl.signedUrl.split('?')[0];

    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
      value: [downloadPath],
    });
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const { value } = this.state;

    const initialCollection = _.compact(value);

    return initialCollection.map((pathString, valueIndex) => {
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));
      return { pathString, filename, valueIndex };
    });
  }

  renderAttachments(fieldId) {
    const { field, disabled, sortPredicate, customUploadPath, contentType } = this.props;
    const {
      value,
      progressValue,
      showProgressBar,
      errorOccurred,
      error,
      showUploadButton,
      uploadPending,
      uploadingErrorOccurred,
    } = this.state;

    let attachments = this.prepareAttachmentsData();
    if (sortPredicate) attachments = attachments.sort(sortPredicate);

    return (
      <FilesFieldContainer>
        <StyledAttachmentsTable>
          {attachments.map(({ pathString, filename, valueIndex }) => (
            <>
              <AttachmentRow
                pathString={pathString}
                filename={filename}
                id={`${fieldId}[${valueIndex}]`}
                name={`${field}[${valueIndex}]`}
                key={pathString}
                removeAttachment={e => {
                  e.preventDefault();
                  this.setState({ value: [] });
                }}
              />
              {this.renderHiddenInput(pathString)}
            </>
          ))}
        </StyledAttachmentsTable>
        {!disabled && value.length === 0 && (
          <>
            <ReactS3Uploader
              id={fieldId}
              signingUrl="/file_upload"
              signingUrlMethod="GET"
              accept={contentType}
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={signedUrl => this.onUploadSuccess(signedUrl)}
              autoUpload
              contentDisposition="attachment"
              scrubFilename={filename => encodeURIComponent(filename.trim())}
              hidden
              inputRef={this.setInputRef}
              signingUrlQueryParams={customUploadPath ? { customUploadPath } : {}}
            />
            <ProgressBar
              progressValue={progressValue}
              hidden={!showProgressBar}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={errorOccurred}
            />
            <ErrorMessage hidden={!errorOccurred}>{error}</ErrorMessage>
            <label htmlFor={fieldId} hidden={!showUploadButton}>
              <StyledMockUploadButton>
                <UploadIcon />
                <StyledMockUploadButtonTitle>
                  {I18n.t('file_uploader.upload_file')}
                </StyledMockUploadButtonTitle>
              </StyledMockUploadButton>
            </label>
            <ProgressBar
              progressValue={progressValue}
              hidden={!uploadPending}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={uploadingErrorOccurred}
            />
          </>
        )}
      </FilesFieldContainer>
    );
  }

  renderHiddenInput(pathString) {
    const { hiddenInputName } = this.props;
    return <input type="hidden" name={hiddenInputName} value={pathString} />;
  }

  render() {
    const { label, field: name, required, hint, error, resourceName, id } = this.props;
    const inputId = id || `${resourceName}-${name}`;
    return (
      <>
        <Row className="w-100 row mx-0">
          <Label
            name={name}
            className="col-lg-3 col-sm-3 col-md-4 col-xs-12 col-form-label"
            inputId={inputId}
          >
            {label}
            {hint && (
              <HintWrapper>
                <InputLabelHint hint={hint} />
              </HintWrapper>
            )}
            {required && <Required>*</Required>}
          </Label>
          <div className="col-lg-9 col-md-8 col-xs-12">{this.renderAttachments(inputId)}</div>
        </Row>
        <Row className="w-100 row mx-0">
          <div className="col-12 col-sm-4 col-md-4 col-lg-3 pl-0" />
          <div className="col-12 col-sm-8 col-md-8 col-lg-4 pl-2 pr-0">
            {error && <Error className="w-100 mt-1">{error}</Error>}
          </div>
        </Row>
      </>
    );
  }
}
