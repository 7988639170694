import { transformNaNValue } from '../../services/formHelper'
import { YupValidator } from '../../validators/YupValidator'

const validationSchema = YupValidator.object().shape({
  identifier: YupValidator.string().nullable().required(I18n.t('validations.required')),
  ship_date: YupValidator.string().nullable().required(I18n.t('validations.required')),
  invoice_no: YupValidator.string().nullable(),
  invoice_amount: YupValidator.number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive'))
    .max(I18n.t('validations.precision.max_value_14_2'), I18n.t('validations.out_of_range'))
    .precision(2, I18n.t('validations.precision.wrong_count_of_decimal_numbers')),
  waste_generator_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  waste_tsdf_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required'))
    .positive(I18n.t('validations.positive')),
  attached_invoices: YupValidator.array().nullable(),
})

export default validationSchema
