/** Lib */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import ReactS3Uploader from 'react-s3-uploader';
import _ from 'lodash';
import { ProgressBar } from '../../../other/ProgressBar';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { UploadIcon } from '../../../icons/UploadIcon';
import {
  DescriptionLinkButton,
  FilesFieldContainer,
  StyledAttachmentDescriptionRow,
  StyledAttachmentLink,
  StyledAttachmentNameSpan,
  StyledAttachmentRow,
  StyledAttachmentsTable,
  StyledMockUploadButton,
  StyledMockUploadButtonTitle,
  StyledRemoveAttachmentButton,
} from './AuxiliaryStyledComponents';
import { getUuid } from '../../../../services/uuidHelper';
import { bytesToMb } from '../../../../services/fileSizeHelper';
import { ErrorMessage } from '../ErrorMessage';
import { SaluteModal } from '../../../modals/SaluteModal';
import { DescriptionModalContent } from './DescriptionModalContent';
import { store } from '../../../../redux/store';

const AttachmentRow = props => {
  const {
    attachment: { pathString, description, filename },
    id,
    disabled,
    removeAttachment,
    addDescriptionToAttachment,
    onlyUrl,
    modalOnModal,
  } = props;
  const descriptionIsPresent = !onlyUrl && description && description.length !== 0;
  const describeAction = descriptionIsPresent ? 'edit_description' : 'add_description';

  const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState(false);

  const styleModalOnModal = { maxWidth: '700px', margin: '3rem auto' };

  return (
    <>
      <StyledAttachmentRow descriptionIsPresent={descriptionIsPresent}>
        <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
        {!onlyUrl && !disabled && (
          <DescriptionLinkButton
            id={`${id}-description-button`}
            type="button"
            onClick={() => {
              setDescriptionModalIsOpen(true);
            }}
          >
            {I18n.t(`file_uploader.${describeAction}`)}
          </DescriptionLinkButton>
        )}
        <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
          <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
        </StyledAttachmentLink>
        {!disabled && (
          <StyledRemoveAttachmentButton
            id={`${id}-remove-button`}
            type="button"
            onClick={removeAttachment}
          >
            {I18n.t('file_uploader.delete_file')}
          </StyledRemoveAttachmentButton>
        )}
      </StyledAttachmentRow>
      {descriptionIsPresent && (
        <StyledAttachmentDescriptionRow>{description}</StyledAttachmentDescriptionRow>
      )}
      {!onlyUrl && (
        <SaluteModal
          title={I18n.t(`file_uploader.${describeAction}`)}
          isOpen={descriptionModalIsOpen}
          onClose={() => {
            setDescriptionModalIsOpen(false);
          }}
          // Passing a store object is required to render modal on a modal. See the link below
          // https://stackoverflow.com/questions/36211739/invariant-violation-could-not-find-store-in-either-the-context-or-props-of-c
          store={store}
          wrapperStyle={modalOnModal ? styleModalOnModal : {}}
        >
          <DescriptionModalContent
            id={id}
            description={description}
            closeModal={() => {
              setDescriptionModalIsOpen(false);
            }}
            addDescriptionToAttachment={addDescriptionToAttachment}
          />
        </SaluteModal>
      )}
    </>
  );
};

export class FilesUploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputId = getUuid();
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
    };
  }

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t(
        'file_uploader.errors.too_large_file_unit',
      )}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = (signedUrl, arrayHelpers) => {
    const { onlyUrl } = this.props;

    let downloadPath = signedUrl.signedUrl.split('?')[0];
    if (!onlyUrl) {
      downloadPath = { url: downloadPath };
    }

    arrayHelpers.push(downloadPath);

    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
    });

    this.fileRef.value = null;
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const { value, onlyUrl } = this.props;
    let initialCollection = value;

    if (onlyUrl) {
      initialCollection = initialCollection.map(url => ({
        url,
        description: null,
      }));
    }

    return initialCollection.map((attachment, valueIndex) => {
      const { url: pathString, description } = attachment;
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));

      return { pathString, filename, description, valueIndex };
    });
  }

  render() {
    const { name, disabled, onlyUrl, modalOnModal } = this.props;
    const { progressValue, showProgressBar, errorOccurred, error, showUploadButton } = this.state;

    const attachments = this.prepareAttachmentsData();

    return (
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <FilesFieldContainer>
            <StyledAttachmentsTable>
              {attachments.map(attachment => (
                <AttachmentRow
                  attachment={attachment}
                  id={`${this.fileInputId}[${attachment.valueIndex}]`}
                  key={`${name}[${attachment.valueIndex}]`}
                  disabled={disabled}
                  onlyUrl={onlyUrl}
                  modalOnModal={modalOnModal}
                  removeAttachment={e => {
                    e.preventDefault();
                    arrayHelpers.remove(attachment.valueIndex);
                    this.fileRef.value = null;
                  }}
                  addDescriptionToAttachment={async description => {
                    await arrayHelpers.replace(attachment.valueIndex, {
                      url: attachment.pathString,
                      description,
                    });
                    this.fileRef.value = null;
                  }}
                />
              ))}
            </StyledAttachmentsTable>
            {!disabled && (
              <>
                <ReactS3Uploader
                  disabled={disabled}
                  id={this.fileInputId}
                  signingUrl="/file_upload"
                  signingUrlMethod="GET"
                  preprocess={this.onUploadStart}
                  onProgress={this.onUploadProgress}
                  onError={this.onUploadError}
                  onFinish={signedUrl => this.onUploadSuccess(signedUrl, arrayHelpers)}
                  autoUpload
                  contentDisposition="attachment"
                  scrubFilename={filename => encodeURIComponent(filename.trim())}
                  hidden
                  inputRef={this.setInputRef}
                  multiple
                />
                <ProgressBar
                  progressValue={progressValue}
                  hidden={!showProgressBar}
                  progressText={I18n.t('file_uploader.progress_text')}
                  errorOccurred={errorOccurred}
                />
                <ErrorMessage hidden={!errorOccurred}>{error}</ErrorMessage>
                <label htmlFor={this.fileInputId} hidden={!showUploadButton}>
                  <StyledMockUploadButton disabled={disabled}>
                    <UploadIcon />
                    <StyledMockUploadButtonTitle>
                      {I18n.t('file_uploader.upload_file')}
                    </StyledMockUploadButtonTitle>
                  </StyledMockUploadButton>
                </label>
              </>
            )}
          </FilesFieldContainer>
        )}
      />
    );
  }
}

FilesUploadInput.propTypes = {
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onlyUrl: PropTypes.bool,
};

FilesUploadInput.defaultProps = {
  disabled: false,
  onlyUrl: false,
};
