import isNil from 'lodash/isNil';
import get from 'lodash/get';
import concat from 'lodash/concat';
import { isDefined } from './utils';

export const fieldPropDefaultTransformation = props => ({
  ...props.field,
  onChange: e => {
    // eslint-disable-next-line no-unused-expressions
    props.onChange ? props.onChange(e, props.field.onChange) : props.field.onChange(e);
  },
  onBlur: e => {
    // eslint-disable-next-line no-unused-expressions
    props.onBlur ? props.onBlur(e, props.field.onBlur) : props.field.onBlur(e);
  },
});

export const getError = (field, form) =>
  get(form.touched, field.name) && get(form.errors, field.name);

export const getErrorOutsideField = (name, touched, errors) =>
  get(touched, name) && get(errors, name);

export const fieldWrapperProps = props => {
  const {
    field,
    form,
    field: { name },
    labelI18nKeyPrefix,
    label,
    required,
    hint,
    error,
    warning,
    additionalContent,
    additionalContentInset,
    hidden,
    invisible,
    displayMode,
    inputId,
    resourceName,
    bottomMargin,
  } = props;
  let inputIdValue = inputId;
  if (isNil(inputId)) {
    inputIdValue = resourceName ? `${resourceName}-${name}` : name;
  }
  let labelValue = label;
  if (isNil(labelValue)) {
    labelValue = labelI18nKeyPrefix ? I18n.t(`${labelI18nKeyPrefix}.${field.name}`) : '';
  }
  return {
    name,
    label: labelValue,
    required,
    hint,
    error: error || getError(field, form),
    warning,
    additionalContent,
    additionalContentInset,
    hidden,
    invisible,
    displayMode,
    inputId: inputIdValue,
    bottomMargin: isDefined(bottomMargin) ? bottomMargin : true,
  };
};

export const mapToSelectorOptions = (
  data,
  dataKey,
  valueKey = 'value',
  optionDisabled = () => false,
  optionHidden = () => false,
) =>
  data.map(option => {
    return {
      data: option[dataKey],
      value: option[valueKey],
      isDisabled: optionDisabled(option),
      isHidden: optionHidden(option),
    };
  });

export const defaultShouldUpdate = (newProps, prevProps) => {
  if (newProps.formik.isSubmitting !== prevProps.formik.isSubmitting) {
    return true;
  }
  const relevantAttributes = ['name', 'disabled', 'error', 'hidden', 'invisible'];
  const relevantAttributeChanged = relevantAttributes.some(
    attribute => newProps[attribute] !== prevProps[attribute],
  );
  if (relevantAttributeChanged) {
    return true;
  }
  const { name } = newProps;
  const relevantCollections = ['values', 'errors', 'touched'];
  const relevantCollectionElementChanged = relevantCollections.some(
    collection =>
      get(newProps.formik[collection], name) !== get(prevProps.formik[collection], name),
  );
  return relevantCollectionElementChanged;
};

export const produceShouldUpdate = (
  additionalAttributes = [],
  additionalCollections = [],
  additionalValuesNames = [],
) => (newProps, prevProps) => {
  if (newProps.formik.isSubmitting !== prevProps.formik.isSubmitting) {
    return true;
  }
  const relevantAttributes = concat(
    ['name', 'disabled', 'error', 'hidden', 'invisible'],
    additionalAttributes,
  );
  const relevantAttributeChanged = relevantAttributes.some(
    attribute => newProps[attribute] !== prevProps[attribute],
  );
  if (relevantAttributeChanged) {
    return true;
  }
  const valuesNames = [newProps.name, ...additionalValuesNames];
  const relevantCollections = concat(['values', 'errors', 'touched'], additionalCollections);
  const relevantCollectionElementChanged = valuesNames.some(name =>
    relevantCollections.some(
      collection =>
        get(newProps.formik[collection], name) !== get(prevProps.formik[collection], name),
    ),
  );
  return relevantCollectionElementChanged;
};

export const sortFilesAZ = ({ filename: filenameA }, { filename: filenameB }) => {
  const upperFilenameA = (filenameA || '').toUpperCase();
  const upperFilenameB = (filenameB || '').toUpperCase();
  if (upperFilenameA > upperFilenameB) return 1;
  if (upperFilenameA < upperFilenameB) return -1;
  return 0;
};

export const sortFilesZA = (a, b) => {
  const azResult = sortFilesAZ(a, b);
  if (azResult === 0) return 0; // zero can be negative in JS
  return -azResult;
};
