import React, { Component } from 'react';
import { FindingForm } from '../../Findings/FindingForm';
import { FindingRow } from './FindingRow';
import { API } from '../../../services/api';
import { SaluteSpinner } from '../../../stateless_components/salute_spinner/index';
import { FormContainer } from '../../../stateless_components/form_wrappers/FormContainer';
import { PrimaryButton } from '../../../stateless_components/buttons/PrimaryButton';

class ChecklistFindings extends Component {
  state = {
    findings: [],
    fetching: true,
    showForm: false,
    activeFinding: null,
  };

  componentDidMount = () => {
    this.setTitle();
    this.fetchFindings();
  };

  fetchFindings = () => {
    const relatedEntityAttribute =
      this.props.findingProps.relatedEntityAttribute || 'fire_drill_question_answer_id';
    API.findings
      .index({ [relatedEntityAttribute]: this.props.findingProps.activeRelatedEntity.id })
      .then(response => {
        if (response.ok) {
          this.setState(
            {
              findings: response.data,
              fetching: false,
              showForm: response.data.data.length === 0,
            },
            this.setTitle,
          );
        } else {
          window.flashMessage('Something went wrong, please try again later', true, 'error');
          this.setState({ fetching: false }, this.setTitle);
        }
      });
  };

  hideForm = cb => {
    this.setState({ showForm: false }, cb);
  };

  openForm = (attributes = {}) => {
    this.setState({ showForm: true, activeFinding: attributes }, this.setTitle);
  };

  setTitle = () => {
    const { activeFinding, showForm, fetching } = this.state;
    const { newFindingsDisabled, findingProps, setTitle } = this.props;

    const label = () => {
      if (activeFinding && !activeFinding.id && showForm) {
        return 'Add New Finding';
      }
      if (!activeFinding && !showForm) {
        return 'Findings';
      }
      return 'Finding';
    };

    setTitle(
      <>
        <div className="row p-0">
          <div className="col-8">{label()}</div>
          {!showForm && !newFindingsDisabled && (
            <div className="col-4 text-right pr-5">
              <PrimaryButton size="sm" onPress={this.openForm} disabled={fetching}>
                + Add New Finding
              </PrimaryButton>
            </div>
          )}
        </div>
        <div className="pt-3" style={{ fontSize: '16px' }}>
          <span>For {findingProps.relatedEntityName || 'Checklist Item'}: </span>
          <span className="font-weight-normal">
            {`${findingProps.activeRelatedEntity.entityIdentifier ||
              findingProps.activeRelatedEntity.question}`}
          </span>
        </div>
      </>,
    );
  };

  findingList = () => {
    const { findings } = this.state;
    return (
      <FormContainer>
        <ul className="findings_list">
          {findings.data.map(finding => {
            return (
              <FindingRow
                key={finding.attributes.identifier}
                finding={finding.attributes}
                included={findings.included}
                onClick={() => this.openForm(finding.attributes)}
              />
            );
          })}
        </ul>
      </FormContainer>
    );
  };

  form = () => {
    const {
      findingProps,
      findingWasCreated = () => {},
      findingWasUpdated = () => {},
      closeModal = () => {},
      onValidationError = () => {},
    } = this.props;
    return (
      <FindingForm
        findingProps={findingProps}
        closeModal={closeModal}
        finding={this.state.activeFinding}
        onValidationError={onValidationError}
        findingWasCreated={findingWasCreated}
        findingWasUpdated={findingWasUpdated}
      />
    );
  };

  list = () => {
    if (this.state.fetching) {
      return <SaluteSpinner style={{ margin: '50px auto' }} />;
    }
    return this.findingList();
  };

  render() {
    if (this.state.showForm) {
      return this.form();
    }
    return this.list();
  }
}

export default ChecklistFindings;
