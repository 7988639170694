/** Lib */
import React from 'react';
import { TrixEditor } from 'react-trix';
import styled from 'styled-components';
/** Style */
import { fontSize } from '../../Style/typography';
import { colors } from '../../Style/colors';

const findBoxColorsByType = type => {
  switch (type) {
    case 'light':
      return { background: colors.newLightGray, color: colors.grayDark };
    case 'dark':
      return { background: colors.grayDark, color: colors.white };
    case 'warning':
      return { background: colors.brand, color: colors.white };
    default:
      return null;
  }
};

const Box = styled.div`
  background: ${props => props.background};
  border-radius: 4px;
  padding: 16px;
  margin-top: 20px;
  color: ${props => props.color};
  position: relative;
  font-size: ${fontSize.textSm};
`;

export const InfoBox = props => {
  const { infoBox } = props;

  const infoBoxColors = findBoxColorsByType(infoBox.type);

  return (
    <Box background={infoBoxColors.background} color={infoBoxColors.color}>
      <div className="sp-portal-pack-trix-show-wrapper">
        <TrixEditor value={infoBox.content} />
      </div>
    </Box>
  );
};
