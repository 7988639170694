import React from 'react';
import { API } from '../../services/api';
import { DeletionLink } from '../../stateless_components/buttons/DeletionLink';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';

const translate = key => {
  return I18n.t(`waste_management.waste_manifests.form.${key}`);
};

const deleteWasteManifest = async id => {
  const { ok } = await API.wasteManagement.wasteManifests.destroy(id);
  if (ok) {
    sessionStorage.setItem('flashMessage', translate('flash_message.destroy.success'));
    window.location.href = '/waste_management/waste_manifests';
  } else {
    window.flashMessage(translate('flash_message.destroy.error'), true, 'error');
  }
};

export const Footer = props => {
  const { id, adminAccess, handleSubmit, isSubmitting } = props;

  return (
    <div className="d-flex flex-wrap justify-content-end align-items-center mt-4 mb-3">
      {id && adminAccess && (
        <div className="d-flex flex-wrap flex-grow-1 justify-content-start align-items-center">
          <DeletionLink
            onDelete={() => deleteWasteManifest(id)}
            modelName={I18n.t('activerecord.models.waste_manifest')}
            disabled={!adminAccess}
          >
            {translate('footer.delete')}
          </DeletionLink>
        </div>
      )}
      <div className="d-flex flex-wrap justify-content-end align-items-center">
        <PrimaryButton onPress={handleSubmit} isPending={isSubmitting}>
          {translate('footer.save')}
        </PrimaryButton>
      </div>
    </div>
  );
};
