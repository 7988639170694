/** Lib */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
/** Components */
import { InfoBadge } from '../badges/InfoBadge'


const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const PlaceholderMessage = styled.p`
  margin-top: 16px;
  text-align: center;
`
export const ListEmptyPlaceholder = ({ message, children }) => (
  <PlaceholderContainer>
    <InfoBadge />
    <PlaceholderMessage>{message}</PlaceholderMessage>
    {children}
  </PlaceholderContainer>
)

ListEmptyPlaceholder.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.any,
}
