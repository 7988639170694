/** Lib */
import React, { Component } from 'react';
import _ from 'lodash';
/** Components */
import { Button } from '../../buttons/Button';

/** Constants */
const DEBOUNCE_DELAY = 250;

/** @description
 *  Abstract component to be inherited by containers that displays collection of data i.e in table format.
 *  It provides following functionality:
 *  1. Managing state of applied filters and filterDetails (can be used to store details about selected option)
 *  2. Keeps track of when to trigger request data function
 *  3. Keeps track of when to buttons for clearing filters
 *  It can be easily reimplemented to be a HOC in the future when needed.
 *  */
export class Filterable extends Component {
  constructor(props, initialValues, defaultValues) {
    super(props);

    this.filtersDefaultValues = defaultValues;
    this.filtersInitialValues = initialValues;
    this.defaultFiltersDetails = _.mapValues(defaultValues, element => null);

    this.state = {
      smartSearchQuery: '',
      filters: _.cloneDeep(this.filtersInitialValues),
      filterDetails: _.cloneDeep(this.filtersInitialValues),
      pageSize: 12,
    };

    this.search = _.debounce(this.search, DEBOUNCE_DELAY, {
      leading: false,
      trailing: true,
    });

    this.search();
  }

  onSmartSearchInputChange = event => {
    this.setState(
      {
        smartSearchQuery: event.target.value,
        filters: _.cloneDeep(this.filtersDefaultValues),
        filterDetails: _.cloneDeep(this.defaultFiltersDetails),
      },
      this.search,
    );
  };

  search = () => {
    this.props.toggleItemsSelected([]);
    this.props.fetchWasteNotCoveredCollection(
      1,
      this.state.smartSearchQuery,
      this.state.filters,
      this.state.pageSize,
    );
  };

  setFilterableState = key => (filterName, multiSelect = false) => value => {
    this.setState(
      prevState => {
        const newState = _.cloneDeep(prevState);
        if (multiSelect) {
          newState[key][filterName] = [value];
        } else {
          newState[key][filterName] = value;
        }
        newState['smartSearchQuery'] = '';
        return newState;
      },
      () => {
        if (key === 'filters') this.search();
      },
    );
  };

  clearFilter = name => {
    this.setFilterableState('filters')(name)(this.filtersDefaultValues[name]);
    this.setFilterableState('filterDetails')(name)('');
    this.search();
  };

  clearAllFilters = () => {
    this.setState(
      {
        smartSearchQuery: '',
        filters: _.cloneDeep(this.filtersDefaultValues),
        filterDetails: _.cloneDeep(this.defaultFiltersDetails),
      },
      this.search,
    );
  };

  shouldShowClearAllButton = () => {
    const index = _.findIndex(Object.values(this.state.filters), filterValue => !!filterValue);

    return index >= 0;
  };

  clearAllFiltersButton = () => {
    return (
      this.shouldShowClearAllButton() && (
        <Button onClick={this.clearAllFilters.bind(this)} className="btn btn-sm btn-link">
          {' '}
          Clear All Filters
        </Button>
      )
    );
  };
}
