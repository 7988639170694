export const fieldDisplayModes = {
  DEFAULT_FORM_ROW: 0,
  WIDE_FORM_ROW: 1,
  FULL_WIDTH_FORM_ROW: 2,
  INPUT_FIRST_FORM_ROW: 3,
  GROWING_WIDTH_INPUT: 4,
  INPUT_FIRST_GROWING_WIDTH_INPUT: 5,
  INPUT_FIRST_GROWING_WIDTH_LABEL: 6,
  NO_WRAPPER: 7,
};
export default fieldDisplayModes;
