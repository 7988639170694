/** Lib */
import styled from 'styled-components'
/** Style */
import { colors } from '../../../Style/colors'
import { errorMetrics } from '../../../Style/metrics'


export const ErrorMessage = styled.p`
  margin: ${errorMetrics.margin};
  font-size: ${ (props) => props.size || '16px'}
  color: ${colors.error};
`
