import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteDynamicSelect extends Component {
  componentDidMount() {
    const selectorConfig = selectors.find(
      selectorConfig => selectorConfig.selector === this.props.selector,
    );

    const options = {
      allowClear: !$(this.selectRef).prop('required'),
      placeholder: $(this.selectRef).data('placeholder') || 'Please Select',
    };

    handleSelect(this.selectRef, selectorConfig.url, selectorConfig.f, options)
      .on('select2:select', this.onChange)
      .on('select2:unselect', this.onClear);

    if (this.props.answer) {
      $.get(`/api/people/${this.props.answer}.json`).done(data => {
        const newOption = new Option(data.text, data.id, true, true);
        $(this.selectRef)
          .append(newOption)
          .trigger('change');
      });
    }
  }

  componentWillUnmount() {
    $(this.selectRef).select2('destroy');
    $(this.selectRef).off('select2:select');
  }

  onChange = event => {
    const value = this.selectRef.value;
    this.props.onChange(value);
  };

  onClear = event => {
    this.props.onChange(null);
  };

  render() {
    return (
      <select
        name={this.props.resolveInputName(this.props.index, 'answer')}
        ref={ref => {
          this.selectRef = ref;
        }}
      />
    );
  }
}

SaluteDynamicSelect.defaultProps = {
  onChange: () => {},
  selector: '.ajax-select_people_presenter',
};

SaluteDynamicSelect.propTypes = {
  onChange: PropTypes.func,
  resolveInputName: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selector: PropTypes.string.isRequired,
};

export default SaluteDynamicSelect;
