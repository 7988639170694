/** Lib */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/** Components */
import { SaluteSpinner } from '../salute_spinner/index'
/** Style */
import { colors } from '../../Style/colors'

const ListItemContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.sectionSeparatorColor}
  ${(props) => { if (props.onClick) { return 'cursor: pointer' } }}
`
const GeneralInfoPanel = styled.div`
  display: flex;
  width: 265px;
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => props.onClick ? 'pointer' : 'auto'};
`
const GeneralInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`
const GeneralInfoSubTitle = styled.div`
  font-size: 14px;
`
const DetailsPanel = styled.div`
  background: ${colors.grayBg};
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  cursor: ${(props) => props.onClick ? 'pointer' : 'auto'};
`
const DetailCell = styled.div`
  background: transparent;
  margin: 0 5px;
  flex: 1;
`
const DeatailHeader = styled.h5`
  margin: 0;
  font-size: 14px;
`
const DetailValue = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: bold;
`
const CrossBulletWrapper = styled.div`
  width: 14px;
  height: 14px;
`

const CrossBullet = styled.div`
  color: ${colors.grayCross};
  display: flex;
  flex: 1;
  cursor: pointer;
  background: white;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  text-align: 'center'
`

const renderDetailCells = (details) => details.map((detail, index) => (
  <DetailCell key={`details-${index}`}>
    <DeatailHeader>{detail.header}</DeatailHeader>
    <DetailValue>{detail.value}</DetailValue>
  </DetailCell>
))

export const SaluteListItem = ({
  title, subtitle, detailsList, onListItemClick, isRemoving, onRemoveItemClick,
}) => (
  <ListItemContainer>
    <GeneralInfoPanel onClick={onListItemClick}>
      <GeneralInfoTitle>{title}</GeneralInfoTitle>
      <GeneralInfoSubTitle>{subtitle}</GeneralInfoSubTitle>
    </GeneralInfoPanel>
    <DetailsPanel onClick={onListItemClick}>
      {renderDetailCells(detailsList)}
    </DetailsPanel>
    <CrossBulletWrapper>
      { isRemoving && <SaluteSpinner name="circle" /> }
      { !isRemoving &&
        <CrossBullet onClick={(event) => { event.stopPropagation(); onRemoveItemClick() }}>
          <i className="fas fa-times-circle"></i>
        </CrossBullet>
      }
    </CrossBulletWrapper>
  </ListItemContainer>
)

SaluteListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  detailsList: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    value: PropTypes.string,
  })),
  onListItemClick: PropTypes.func,
  onRemoveItemClick: PropTypes.func,
  isRemoving: PropTypes.bool,
}
