/** Lib */
import React, { Component } from 'react'
import PropTypes from 'prop-types'


export const RadioButton = (props) => {
  const {
    checked = false, value, label, onChange, name,
  } = props

  return (
    <label>
      <input type="radio" className="mr-2" checked={checked} value={value} onChange={onChange} name={name} />
      {label}
    </label>
  )
}

RadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
}
