/** Lib */
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
/** Styles */
import { modalFooterMetrics } from '../../Style/metrics'

const ModalFooterWrapper = styled.div`
  padding: ${modalFooterMetrics.topPadding} ${modalFooterMetrics.sidePadding} ${modalFooterMetrics.bottomPadding} ${modalFooterMetrics.sidePadding};
  justify-content: flex-end;
`

export const ModalFooter = ({ children }) => (
  <ModalFooterWrapper
    className="d-flex flex-column-reverse flex-sm-column-reverse flex-md-row flex-lg-row flex-xl-row"
  >
    {children}
  </ModalFooterWrapper>
)

ModalFooter.propTypes = {
  children: PropTypes.any,
}
