import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SaluteModal } from '../../../stateless_components/modals/SaluteModal';
import Findings from '../Findings/index';
import { colors } from '../../../Style/colors';

const StyledButton = styled.button`
  cursor: pointer;
  &:hover {
    color: ${colors.grayDark} !important;
    background-color: ${colors.grayBg} !important;
  }
`;

class FindingsBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalTitle: 'Findings',
    };
    this.modalRef = React.createRef();
    this.findingsRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.shouldOpenModal) {
      this.openModal();
    }
  };

  addNewFindingBtn = () => {
    const { disabled } = this.props;

    return (
      <StyledButton
        type="button"
        data-toggle={disabled ? '' : 'tooltip'}
        disabled={disabled}
        placement="top"
        title="Add new Findings"
        className="btn issue_button btn-view-finding inspection-question-action-btn border-0 no-outline"
        onClick={() => this.openModal('Add Finding')}
      >
        Add Finding +
      </StyledButton>
    );
  };

  viewFindingsButton = () => {
    const { findingsCount } = this.props;
    return (
      <StyledButton
        type="button"
        data-toggle="tooltip"
        placement="top"
        title="View Findings"
        className="btn issue_button btn-view-finding inspection-question-action-btn border-0 no-outline"
        onClick={() => this.openModal('Findings')}
      >
        Findings
        <span className="badge badge-warning" style={{ marginLeft: '5px' }}>
          {findingsCount}
        </span>
      </StyledButton>
    );
  };

  openModal = async () => {
    const { canOpenModal, findingDefinitionId } = this.props;
    if (canOpenModal) {
      if (await canOpenModal(findingDefinitionId, 'FindingsBtn')) {
        this.setState({ modalOpen: true }, () => {
          this.props.findingProps.setActiveRelatedEntity(this.props.relatedEntity);
        });
      }
    } else {
      this.setState({ modalOpen: true }, () => {
        this.props.findingProps.setActiveRelatedEntity(this.props.relatedEntity);
      });
    }
  };

  setTitle = title => {
    this.setState({ modalTitle: title });
  };

  closeModal = () => {
    this.setState({ modalOpen: false }, () => {
      this.props.findingProps.setActiveRelatedEntity('');
    });
  };

  findingWasCreated = (newFindingData = undefined) => {
    const { findingProps } = this.props;
    findingProps.refreshList();
    if (newFindingData) {
      // form must be hidden in the first place to reset values
      this.findingsRef.current.hideForm(() => {
        this.findingsRef.current.openForm(newFindingData);
        this.modalRef.current.scrollTop();
      });
    } else {
      this.closeModal();
    }
  };

  findingWasUpdated = (newFindingData = undefined) => {
    if (newFindingData) {
      // form must be hidden in the first place to reset values
      this.findingsRef.current.hideForm(() => {
        this.findingsRef.current.openForm(newFindingData);
        this.modalRef.current.scrollTop();
      });
    } else {
      this.closeModal();
    }
  };

  scrollToModalTop = () => {
    this.modalRef.current.scrollTop();
  };

  scrollToModalTop = () => {
    this.modalRef.current.scrollTop();
  };

  modal = () => {
    const { findingsCount, findingProps, disabled } = this.props;
    const { modalOpen, modalTitle } = this.state;

    return (
      <SaluteModal
        isOpen={modalOpen}
        onCloseClick={this.closeModal}
        title={modalTitle}
        ref={this.modalRef}
      >
        <Findings
          findingsCount={findingsCount}
          findingProps={findingProps}
          newFindingsDisabled={disabled}
          closeModal={this.closeModal}
          findingWasCreated={this.findingWasCreated}
          findingWasUpdated={this.findingWasUpdated}
          setTitle={this.setTitle}
          onValidationError={this.scrollToModalTop}
          ref={this.findingsRef}
        />
      </SaluteModal>
    );
  };

  render() {
    const { findingsCount } = this.props;
    return (
      <>
        {findingsCount > 0 ? this.viewFindingsButton() : this.addNewFindingBtn()}
        {this.modal()}
      </>
    );
  }
}

FindingsBtn.propTypes = {
  findingsCount: PropTypes.number,
};

FindingsBtn.defaultProps = {
  findingsCount: 0,
};

export default FindingsBtn;
