import React, { PureComponent } from 'react';
import { withFormik } from 'formik';
import yup from '../../salute_portal/components/form/CustomYupValidator';
import { SaluteInputField } from '../inputs/fields/SaluteInputField';
import { getFormikErrors, validateForm } from '../../services/formHelper';
import { FIELD_WIDTHS, INPUT_TYPES } from '../inputs/elements/FieldWrapper';
import { ModalFooter } from '../modals/ModalFooter';
import { ButtonLink } from '../buttons/ButtonLink';
import { PrimaryButton } from '../buttons/PrimaryButton';

const t = key => I18n.t(`links_field.add_modal.${key}`);

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  url: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

const defaultValues = { name: '', url: '' };

class Form extends PureComponent {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      handleChange,
      handleBlur,
      hide,
    } = this.props;

    return (
      <>
        <SaluteInputField
          labelProps={{ label: t('name'), required: true }}
          inputProps={{
            name: 'name',
            value: values.name,
            onChange: handleChange,
            handleBlur,
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('name', touched, errors)}
          type={INPUT_TYPES.TEXT_FIELD}
        />
        <SaluteInputField
          labelProps={{ label: t('url'), required: true }}
          inputProps={{
            name: 'url',
            value: values.url,
            onChange: handleChange,
            handleBlur,
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('url', touched, errors)}
          type={INPUT_TYPES.TEXT_FIELD}
        />
        <ModalFooter>
          <ButtonLink fontSizeVariant="textMd" secondary onClick={hide}>
            {t('cancel')}
          </ButtonLink>
          <div className="ml-md-3 mb-sm-3" />
          <PrimaryButton onPress={handleSubmit} isPending={isSubmitting}>
            {t('save')}
          </PrimaryButton>
        </ModalFooter>
      </>
    );
  }
}

export const AddLinksModalContent = withFormik({
  mapPropsToValues: () => defaultValues,
  handleSubmit: (values, formikProps) => {
    const { onPush, hide } = formikProps.props;
    const valuesToPush = {};
    Object.keys(defaultValues).forEach(key => {
      valuesToPush[key] = values[key];
    });
    onPush(valuesToPush);
    hide();
  },
  validate: values => validateForm(values, validationSchema),
})(Form);
