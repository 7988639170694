/** Lib */
import React, { Component, Children, Fragment } from 'react';
import PropTypes from 'prop-types';

export const Tab = ({ title, children }) => children;

export class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: props.activeTabIndex || 0,
    };
  }

  navigateToTab = activeTabIndex => () => {
    this.setState({ activeTabIndex });
  };

  isActive = index => index === this.state.activeTabIndex;

  renderTabsButtons = () => {
    return Children.map(this.props.children, (child, index) => {
      const activeClass = this.isActive(index) ? 'active' : '';

      return (
        <li className="nav-item cursor-pointer" onClick={this.navigateToTab(index)}>
          <span className={`nav-link ${activeClass}`}>
            <span>
              {child.props.title}
              {child.props.bulletNumber > 0 && (
                <span className="ml-2 badge badge-danger badge-circle">
                  {child.props.bulletNumber}
                </span>
              )}
              {child.props.errors && (
                <i className="ml-2 text-danger saluteicons saluteicons-warning" />
              )}
            </span>
          </span>
        </li>
      );
    });
  };

  renderContent = () => {
    return Children.map(this.props.children, (child, index) => {
      const activeClass = this.isActive(index) ? '' : 'hide';
      return <div className={activeClass}>{child}</div>;
    });
  };

  render() {
    const { additionalButton } = this.props;
    return (
      <Fragment>
        <ul className="nav nav-tabs mt-5">
          {this.renderTabsButtons()}
          {additionalButton && <div className="order-md-last ml-auto">{additionalButton}</div>}
        </ul>
        <div className="tab-content pt-0">{this.renderContent()}</div>
      </Fragment>
    );
  }
}

Tabs.propTypes = {
  activeTabIndex: PropTypes.number,
  children: PropTypes.any,
  additionalButton: PropTypes.node,
};

Tab.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};
