import { colors } from '../../../assets/styles/colors';

export const selectorCustomStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    minWidth: '100px',
  }),
  control: (provided, state) => ({
    display: 'flex',
    padding: '0 8px',
    border: state.menuIsOpen
      ? `1px solid ${colors.outline}`
      : `1px solid ${colors.inputBorderColor}`,
    borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
    background: state.isDisabled ? colors.disabled : colors.white,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.inputPlaceholderColor,
  }),
  menu: provided => ({
    ...provided,
    margin: 0,
    borderRadius: '0 0 4px 4px',
    zIndex: 20,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    display: state.data.isHidden ? 'none' : 'block',
    backgroundColor: state.isFocused ? `${colors.optionHoverColor} !important` : 'inherit',
    // eslint-disable-next-line no-nested-ternary
    color: state.isDisabled ? colors.disabled : state.isFocused ? 'white' : 'inherit',
  }),
  singleValue: provided => ({
    ...provided,
    position: 'static',
    transform: 'none',
    margin: '5px 0',
    color: 'inherit',
  }),
  multiValue: provided => ({
    ...provided,
    background: 'none',
    border: `1px solid ${colors.outline}`,
    borderRadius: '4px',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    margin: '7px 0',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '7px',
  }),
  loadingIndicator: provided => ({
    ...provided,
    padding: '7px',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '7px',
  }),
};
