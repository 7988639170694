export const colors = {
  error: '#DC3545',
  delete: '#DC3545',
  inputBorderColor: 'rgba(0, 0, 0, 0.15)',
  inputPlaceholderColor: '#A9A9A9',
  inputIcon: 'hsl(0,0%,80%)',
  sectionSeparatorColor: 'rgba(0, 0, 0, 0.1)',
  optionHoverColor: '#75C0CA',
  white: '#FFF',
  listButtonFontColor: '#9A9FA8',
  listButtonHoverBackground: '#F6F6F8',
  listButtonHoverFontColor: '#354052',
  commentFileButtonHoverBackground: '#CED0DA',
  hoverBackground: '#F6F7FB',
  lightGray: '#CAD0E3',
  gray: '#A2A1A8',
  inputTextColor: '#495057',
  grayBackgroundColor: '#F6F7FB',
  hoverGrayBackgroundColor: '#CED0DA',
  grayDark: '#354052',
  required: '#E32',
  disabled: '#EDEFF7',
  disabledDarker: '#CDCDD5',
  outline: 'rgba(117, 192, 202, 1)',
  tableHeader: '#57799C',
  grayCross: '#BDBDBD',
  badge: '#DFE3E9',
  success: '#00B16A',
  successHover: '#00A764',
  download: '#17A2B3',
  link: '#007bff',
  selectedLink: '#212529',
  yellow: '#FFFF00',
  warning: '#DF3600',
};
