import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const InputLabelHint = ({ hint }) => (
  <Tooltip arrow title={hint}>
    <span>
      <i className="fa far fa-question-circle fa-sm" />
    </span>
  </Tooltip>
);

InputLabelHint.propTypes = {
  hint: PropTypes.string.isRequired,
};

export default InputLabelHint;
