/** Lib */
import React from 'react';
import PropTypes from 'prop-types';

export const ProgressBar = function(props) {
  const { hidden, errorOccurred, progressText, progressValue } = props;

  let progressBarClasses = 'bg-info progress-bar progress-bar-striped';
  let actualProgressValue = progressValue;
  let currentProgressBarText = progressText.concat(' ', progressValue, '%');

  if (errorOccurred) {
    actualProgressValue = 100;
    progressBarClasses = progressBarClasses.concat(' bg-danger');
    currentProgressBarText = I18n.t('file_uploader.errors.fail');
  }

  return (
    <div className="progress" hidden={hidden}>
      <div
        className={progressBarClasses}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={actualProgressValue}
        style={{
          width: actualProgressValue.toString().concat('%')
        }}
      >
        {currentProgressBarText}
      </div>
    </div>
  );
};

ProgressBar.defaultProps = {
  hidden: true,
  errorOccurred: false,
  progressText: '',
};

ProgressBar.propTypes = {
  hidden: PropTypes.bool,
  errorOccurred: PropTypes.bool,
  progressText: PropTypes.string,
  progressValue: PropTypes.number.isRequired,
};
