import { YupValidator } from '../../validators/YupValidator';
import { transformNaNValue } from '../../services/formHelper';

const validationSchema = YupValidator.object().shape({
  date_issued: YupValidator.string()
    .nullable(true)
    .required(I18n.t('validations.required')),
  violation_number: YupValidator.string()
    .nullable(true)
    .required(I18n.t('validations.required')),
  violation_type_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  responsible_ehs_person_id: YupValidator.number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  submit: YupValidator.string(),
});

export default validationSchema;
