/** == WasteManifestLineItem ==================================== */
const wasteManifestLineItemsRoot = (state) => state.views
  .wasteManagement
  .wasteManifestForm
  .wasteManifestItemsList

const toWasteManifestLineItemsRecord = (state) => (id) => state.entities.wasteManifestItems[id]

export const wasteManifestLineItemsSelector = {
  allRecords: (state) => wasteManifestLineItemsRoot(state).ids.map(toWasteManifestLineItemsRecord(state)),
  idsOfRecordsBeingRemoved: (state) => wasteManifestLineItemsRoot(state).itemsCurrentlyBeingRemoved,
  isFetching: (state) => wasteManifestLineItemsRoot(state).isPending,
}
/** == WasteChemicalItems aka WasteCollection - Covered ==================================== */
const wasteManifestCollectionCoveredRoot = (state) => state.views
  .wasteManagement
  .wasteManifestForm
  .wasteManifestCollectionCoveredList

const toWasteChemicalItem = (state) => (id) => state.entities.wasteManifestCollection[id]

export const wasteManifestCollectionCoveredSelector = {
  idsOfRecordsBeingRemoved: (state) => wasteManifestCollectionCoveredRoot(state).itemsCurrentlyBeingRemoved,
  allRecords: (state) => wasteManifestCollectionCoveredRoot(state).ids.map(toWasteChemicalItem(state)),
  isFetching: (state) => wasteManifestCollectionCoveredRoot(state).isPending,
}

/** == WasteChemicalItems aka WasteCollection - NOT Covered ==================================== */
const wasteManifestCollectionNotCoveredRoot = (state) => state.views
  .wasteManagement
  .wasteManifestForm
  .wasteManifestCollectionNotCoveredList

export const wasteManifestCollectionNotCoveredSelector = {
  allRecords: (state) => wasteManifestCollectionNotCoveredRoot(state).ids.map(toWasteChemicalItem(state)),
  isSubmitting: (state) => wasteManifestCollectionNotCoveredRoot(state).isSubmitting,
  selectedIds: (state) => wasteManifestCollectionNotCoveredRoot(state).itemsSelected,
  paginationInfo: (state) => {
    const { page, totalPagesCount } = wasteManifestCollectionNotCoveredRoot(state)

    return { page, totalPagesCount }
  },
  isFetching: (state) => wasteManifestCollectionNotCoveredRoot(state).isPending,
}
