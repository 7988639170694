/** Lib */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
/** Style */
import { colors } from '../../Style/colors';
import { fontSize, fontWeights } from '../../Style/typography';
import { modalCloseButtonMetrics, modalHeaderMetrics } from '../../Style/metrics';
import * as ReactDOM from "react-dom";

ReactModal.defaultStyles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1060',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

const Wrapper = styled.div`
  background: ${colors.grayBackgroundColor};
  width: 90%;
  max-width: 800px;
  margin: 1.75rem auto;
`;

const ModalHeader = styled.h1`
  font-size: ${fontSize.textXlg};
  font-weight: ${fontWeights.headers};
  color: ${colors.grayDark};
  padding-left: ${modalHeaderMetrics.leftPadding};
  padding-top: ${modalHeaderMetrics.topPadding};
  padding-bottom: ${modalHeaderMetrics.bottomPadding};
`;

const CloseButtonWrapper = styled.div`
  right: ${modalCloseButtonMetrics.right};
  top: ${modalCloseButtonMetrics.top};
  position: relative;
`;

const ModalContent = styled.div`
  padding: 0 25px 25px;
`;

export class SaluteModal extends Component {
  scrollTop = () => {
    ReactDOM.findDOMNode(this).scrollTo({ top: 0, behavior: 'smooth' });
  };

  render = () => {
    const { isOpen, children, title, onCloseClick, style, onAfterOpen, wrapperStyle } = this.props;

    return (
      <ReactModal isOpen={isOpen} style={style} closeTimeoutMS={200} onAfterOpen={onAfterOpen}>
        <Wrapper style={wrapperStyle}>
          <CloseButtonWrapper>
            <button onClick={onCloseClick} className="btn btn-close close">
              &times;
            </button>
          </CloseButtonWrapper>
          {title && <ModalHeader>{title}</ModalHeader>}
          <ModalContent>{children}</ModalContent>
        </Wrapper>
      </ReactModal>
    );
  };
}

SaluteModal.propTypes = {
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  onCloseClick: PropTypes.func,
  onAfterOpen: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
};

SaluteModal.defaultProps = {
  style: {},
  onCloseClick: () => {},
  onAfterOpen: () => {},
};

ReactModal.setAppElement('body');
