import React, { Component } from 'react';
import styled from 'styled-components';
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper';
import { getFormikErrors } from '../../services/formHelper';
import { FormContainer } from '../../stateless_components/form_wrappers/FormContainer';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import ObjectSelector from '../Findings/ObjectSelector';
import { InspectionFooter } from './Footer';
import { Tabs, Tab } from '../../stateless_components/tabs/Tabs';
import InspectionChecklist from './Checklist';
import ViolationsList from './ViolationsList';
import { fontSize } from '../../Style/typography';
import { colors } from '../../Style/colors';
import {
  newAlsoNotifiedPeopleAttributes,
  alsoNotifiedPeople,
} from '../../services/alsoNotifiedPeopleHelper';
import { FormSection } from '../../stateless_components/form_wrappers/FormSection';
import { InfoBox } from '../../stateless_components/other/InfoBox';

const ViolationsCounter = styled.div`
  display: inline-block;
  position: relative;
  bottom: 1px;
  left: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background: ${colors.brand};
  color: ${colors.white};
  font-size: ${fontSize.textXsm};
`;

class Form extends Component {
  state = {
    violationsCount: undefined,
    isLoadingQuestions: false,
    sendEmailNotifications: !this.props.values.id,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSubmitting &&
      !this.props.isSubmitting &&
      Object.keys(this.props.errors).length > 0
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async componentDidMount() {
    const {
      values: { inspection_type },
    } = this.props;

    await this.setInfoBox(inspection_type.data);
  }

  ModelI18nPath = () => {
    const translation_scope = this.props.internal ? 'internal' : 'external';
    return `activerecord.attributes.inspection.${translation_scope}`;
  };

  FormI18nPath = () => 'inspections.inspections.form';

  finalized = () => {
    return this.props.values.status === 'finalized';
  };

  setIsLoadingQuestions = value => this.setState({ ...this.state, isLoadingQuestions: value });

  setFieldValue = this.props.setFieldValue;

  updateInspectionsAlsoNotifiedPeopleAttributes = newPeople => {
    const { values } = this.props;
    const newAttributes = newAlsoNotifiedPeopleAttributes(
      values.inspections_also_notified_people_attributes,
      newPeople,
    );
    this.setFieldValue('inspections_also_notified_people_attributes', newAttributes);
  };

  setValuesForObjectType = objectType => {
    if (['Equipment', 'Location'].includes(objectType)) {
      this.setFieldValue('object_type', objectType);
      this.setFieldValue(`show${objectType}Selector`, true);
    } else {
      this.setFieldValue('object_type', objectType);
      this.setFieldValue('investigable_type', objectType);
    }
  };

  updateObjectTypes = objectTypes => {
    const { values } = this.props;
    this.setFieldValue('available_object_types', objectTypes);

    if (objectTypes && objectTypes.length === 1) {
      this.setValuesForObjectType(objectTypes[0]);
      if (!values.investigable_id) {
        this.setFieldValue('investigable_id', null);
      }
    }
  };

  setInfoBox = async inspectionType => {
    const { info_box_show, info_box_type, info_box_content } = inspectionType;

    if (inspectionType) {
      this.setFieldValue('info_box', {
        show: info_box_show,
        type: info_box_type,
        content: info_box_content,
      });
    } else {
      await this.setFieldValue('info_box', {});
    }
  };

  externalInspectionPart = () => {
    const { values, errors, handleBlur, touched, setFieldTouched } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-md-4 col-xs-12 col-form-label" />
          <div className="col-lg-6 col-sm-9 col-md-8 col-xs-12">
            <input
              disabled={this.finalized()}
              type="radio"
              value
              id="scheduled"
              name="scheduled"
              className="mr-2"
              checked={values.scheduled === true}
              onChange={() => {
                this.setFieldValue('scheduled', true);
              }}
            />
            <label htmlFor="scheduled" value="true" className="mr-3">
              Scheduled
            </label>
            <input
              disabled={this.finalized()}
              type="radio"
              id="unscheduled"
              value={false}
              name="scheduled"
              className="mr-2"
              checked={values.scheduled === false}
              onChange={() => {
                this.setFieldValue('scheduled', false);
              }}
            />
            <label htmlFor="unscheduled" value="false">
              Unscheduled
            </label>
          </div>
        </div>
        <SaluteInputField
          labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.agency_id`) }}
          inputProps={{
            disabled: this.finalized(),
            setFieldTouched,
            value: values.agency_id,
            name: 'agency_id',
            initialSelection: values.agency_id,
            apiPath: 'agencies.index',
            intitialSelectionApiPath: 'agencies.show',
            mapInitialSelectionFromApi: e => ({ id: e.id, label: e.text }),
            handleChange: value => this.setFieldValue('agency_id', value),
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('agency_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.agency_inspector_name`) }}
          inputProps={{
            disabled: this.finalized(),
            handleBlur,
            value: values.agency_inspector_name,
            name: 'agency_inspector_name',
            onChange: e => this.setFieldValue('agency_inspector_name', e.target.value),
          }}
          error={getFormikErrors('agency_inspector_name', touched, errors)}
          fieldWidth={FIELD_WIDTHS.WIDE}
          type={INPUT_TYPES.TEXT_FIELD}
        />
        <SaluteInputField
          labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.greeting_person_id`) }}
          inputProps={{
            disabled: this.finalized(),
            setFieldTouched,
            value: values.greeting_person_id,
            name: 'greeting_person_id',
            initialSelection: values.greeting_person_id,
            apiPath: 'people.index',
            intitialSelectionApiPath: 'people.show',
            handleChange: value => this.setFieldValue('greeting_person_id', value),
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('greeting_person_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.supervising_person_id`) }}
          inputProps={{
            disabled: this.finalized(),
            setFieldTouched,
            value: values.supervising_person_id,
            name: 'supervising_person_id',
            initialSelection: values.supervising_person_id,
            apiPath: 'people.index',
            intitialSelectionApiPath: 'people.show',
            handleChange: value => this.setFieldValue('supervising_person_id', value),
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('supervising_person_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <hr />
      </>
    );
  };

  form = () => {
    const {
      handleChange,
      values,
      errors,
      handleBlur,
      touched,
      setFieldTouched,
      people_groups_count,
      customInvestigableTypesNames,
    } = this.props;
    const typeApi = this.props.internal ? 'internal_inspection_type' : 'external_inspection_type';

    return (
      <>
        <FormSection title={I18n.t('inspections.inspections.form.overview')}>
          <SaluteInputField
            labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.identifier`) }}
            inputProps={{
              value: values.identifier,
              name: 'identifier',
              onChange: handleChange,
              disabled: true,
              placeholder: '##############',
              handleBlur,
            }}
            error={getFormikErrors('identifier', touched, errors)}
            type={INPUT_TYPES.TEXT_FIELD}
            fieldWidth={FIELD_WIDTHS.WIDE}
          />
          {this.props.internal && people_groups_count > 1 && (
            <SaluteInputField
              labelProps={{
                label: I18n.t(`${this.ModelI18nPath()}.people_group_id`),
                required: true,
              }}
              inputProps={{
                disabled: this.finalized() || values.assessment_queue_id || !!values.id,
                setFieldTouched,
                value: values.people_group_id,
                name: 'people_group_id',
                initialSelection: values.people_group_id,
                apiPath: 'peopleGroups.index',
                intitialSelectionApiPath: 'peopleGroups.show',
                handleChange: async value => {
                  await this.setFieldValue('people_group_id', value);
                  this.setFieldValue('inspection_type_id', null);
                },
              }}
              error={getFormikErrors('people_group_id', touched, errors)}
              type={INPUT_TYPES.ASYNC_SELECT}
              fieldWidth={FIELD_WIDTHS.WIDE}
            />
          )}
          <SaluteInputField
            labelProps={{
              label: I18n.t(`${this.ModelI18nPath()}.date_inspected`),
              required: true,
            }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              date: values.date_inspected,
              name: 'date_inspected',
              setFieldValue: this.setFieldValue,
            }}
            error={getFormikErrors('date_inspected', touched, errors)}
            type={INPUT_TYPES.DATETIME_PICKER}
            fieldWidth={FIELD_WIDTHS.WIDE}
          />
          <SaluteInputField
            labelProps={{
              label: I18n.t(`${this.ModelI18nPath()}.inspection_type_id`),
              required: true,
            }}
            inputProps={{
              disabled:
                this.finalized() || !!values.id || (values.internal && !values.people_group_id),
              setFieldTouched,
              value: values.inspection_type_id,
              name: 'inspection_type_id',
              initialSelection: values.inspection_type_id,
              apiPath: `${typeApi}.index`,
              requestParams: {
                with_people_group_id: values.people_group_id,
                with_object_type: values.object_type,
              },
              intitialSelectionApiPath: `${typeApi}.show`,
              mapResult: item => {
                const option = item.resetting_compliance_timeline ? (
                  <>
                    <i className="glyphicon glyphicon-time mr-2" />
                    {item.header}
                  </>
                ) : (
                  item.header
                );
                return {
                  value: item.id,
                  id: item.id,
                  label: item.header,
                  header: option,
                  details: item.details,
                  resetting_compliance_timeline: item.resetting_compliance_timeline,
                  available_object_types: item.available_object_types,
                  info_box_show: item.info_box_show,
                  info_box_type: item.info_box_type,
                  info_box_content: item.info_box_content,
                };
              },
              handleChange: async value => {
                await this.setFieldValue('inspection_type_id', value);
                if (!value) {
                  await this.setFieldValue('resetting_timeline', null);
                  await this.setInfoBox({});
                  this.updateObjectTypes(null);
                }
              },
              setSelectedRecord: async value => {
                const { resetting_compliance_timeline, available_object_types } = value || {};

                await this.setFieldValue('resetting_timeline', resetting_compliance_timeline);
                await this.setInfoBox(value || {});
                this.updateObjectTypes(available_object_types);
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('inspection_type_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          {values.inspection_type_id && (
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-4 col-xs-12 col-form-label" />
              <div className="col-lg-6 col-sm-9 col-md-8 col-xs-12 small-text">
                {values.resetting_timeline && (
                  <div className="info is_resetting text-success">
                    This inspection will reset the compliance timeline for equipment.
                  </div>
                )}
                {!values.resetting_timeline && (
                  <div className="info is_not_resetting text-muted">
                    This inspection will not reset the compliance timeline for equipment.
                  </div>
                )}
              </div>
            </div>
          )}
          <hr />
          {!this.props.internal && this.externalInspectionPart()}
          <ObjectSelector
            values={values}
            errors={errors}
            setFieldValue={this.setFieldValue}
            touched={touched}
            setFieldTouched={setFieldTouched}
            customInvestigableTypesNames={customInvestigableTypesNames}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.location_comment`) }}
            inputProps={{
              disabled: this.finalized() || values.has_investigable_snapshot,
              handleBlur,
              value: values.location_comment,
              name: 'location_comment',
              onChange: e => this.setFieldValue('location_comment', e.target.value),
              placeholder: '(Optional) Provide additional detail about Assessment Object',
            }}
            error={getFormikErrors('location_comment', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.TEXT_FIELD}
          />
        </FormSection>
      </>
    );
  };

  assignForm = () => {
    const { values, errors, touched, setFieldTouched } = this.props;

    return (
      <FormSection
        title={I18n.t('inspections.inspections.form.assign')}
        hint={I18n.t('inspections.inspections.form.hints.assigned')}
      >
        <SaluteInputField
          labelProps={{ label: I18n.t(`${this.ModelI18nPath()}.ehs_inspector_id`) }}
          inputProps={{
            disabled: this.finalized(),
            setFieldTouched,
            value: values.ehs_inspector_id,
            name: 'ehs_inspector_id',
            initialSelection: values.ehs_inspector_id,
            apiPath: 'people.index',
            intitialSelectionApiPath: 'people.show',
            handleChange: value => this.setFieldValue('ehs_inspector_id', value),
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('ehs_inspector_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        <SaluteInputField
          labelProps={{
            label: I18n.t(`${this.ModelI18nPath()}.responsible_person_id`),
            required: true,
            hint: I18n.t('inspections.inspections.form.hints.responsible_person'),
          }}
          inputProps={{
            disabled: this.finalized(),
            setFieldTouched,
            value: values.responsible_person_id,
            name: 'responsible_person_id',
            initialSelection: values.responsible_person_id,
            apiPath: 'people.index',
            intitialSelectionApiPath: 'people.show',
            handleChange: value => {
              this.setFieldValue('responsible_person_id', value);
            },
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('responsible_person_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        />
        {this.props.internal && (
          <>
            <SaluteInputField
              labelProps={{
                label: I18n.t(
                  `${this.ModelI18nPath()}.inspections_also_notified_people_attributes`,
                ),
              }}
              inputProps={{
                disabled: this.finalized(),
                setFieldTouched,
                value: alsoNotifiedPeople(values.inspections_also_notified_people_attributes),
                name: 'inspections_also_notified_people_attributes',
                initialSelection: alsoNotifiedPeople(
                  values.inspections_also_notified_people_attributes,
                ),
                apiPath: 'people.index',
                intitialSelectionApiPath: 'people.show',
                handleChange: value => {
                  this.updateInspectionsAlsoNotifiedPeopleAttributes(value);
                },
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('also_notified_people_ids', touched, errors)}
              type={INPUT_TYPES.MULTIPLE_ASYNC_SELECT}
            />
          </>
        )}
      </FormSection>
    );
  };

  renderInfoBox = () => {
    const {
      values: { info_box },
    } = this.props;
    if (info_box && info_box.show) {
      return <InfoBox infoBox={info_box} />;
    }

    return null;
  };

  checklistTab = () => {
    return (
      <Tab key="checklist" title="Checklist">
        <InspectionChecklist
          {...this.props}
          items={this.props.values.inspection_checklist_questions_attributes}
          updateQuestions={async (items, cb) => {
            await this.setFieldValue('inspection_checklist_questions_attributes', items);
            if (cb) cb();
          }}
          setIsLoadingQuestions={this.setIsLoadingQuestions}
        />
      </Tab>
    );
  };

  updateViolationsCount = newCount => {
    this.setState({ ...this.state, violationsCount: newCount });
  };

  violationsCounter = violationsCount => <ViolationsCounter>{violationsCount}</ViolationsCounter>;

  violationTabTitle = () => {
    const { violationsCount } = this.state;
    return (
      <div>
        Violations
        {violationsCount && violationsCount > 0 ? this.violationsCounter(violationsCount) : ''}
      </div>
    );
  };

  violationTab = () => {
    const { internal_inspection, customInvestigableTypesNames } = this.props;
    if (!internal_inspection) {
      return (
        <Tab key="violation" title={this.violationTabTitle('a')}>
          <ViolationsList {...this.props} violationsListUpdatedCb={this.updateViolationsCount} />
        </Tab>
      );
    }
    return false;
  };

  createViewAllFindingsButton = () => {
    const {
      internal_inspection,
      values: { id, identifier },
    } = this.props;
    const findingType = internal_inspection ? 'internal' : 'external';
    if (id) {
      return (
        <a
          className="text-default f_stop_click_propagation"
          href={`/${findingType}_findings?filterrific[with_inspection_id]=${identifier}&sub_type=${findingType}`}
        >
          <div className="mt-2 mb-3 mb-md-0">
            <svg className="fas fa-file" />
            <span className="normal-text p-2">
              {I18n.t(`${this.FormI18nPath()}.buttons.view_all_findings`)}
            </span>
          </div>
        </a>
      );
    }
    return false;
  };

  render() {
    const { isLoadingQuestions } = this.state;
    return (
      <>
        <FormContainer>{this.form()}</FormContainer>
        <FormContainer>{this.assignForm()}</FormContainer>
        {this.renderInfoBox()}
        <Tabs activeTabIndex={0} additionalButton={this.createViewAllFindingsButton()}>
          {[this.violationTab(), this.checklistTab()].filter(Boolean)}
        </Tabs>
        <div className="mb-3">
          <InspectionFooter {...this.props} isLoadingQuestions={isLoadingQuestions} />
        </div>
      </>
    );
  }
}

export default Form;
