/** Lib */
import React, { Component } from 'react';
import HtdAsyncPaginate from 'htd-react-select-async-paginate';
import PropTypes from 'prop-types';
import _ from 'lodash';
/** Component */
import { OptionDetails } from './OptionDetails';
import { OptionHeader } from './OptionHeader';
import { OptionContainer } from './OptionContainer';
/** Style */
import { colors } from '../../../../Style/colors';
import 'react-select/dist/react-select.css';
/** Services */
import { API } from '../../../../services/api';

/** @Note: This should match PAGE_SIZE defined in Api::ApiHelper */
const PAGE_SIZE = 25;

/**@description Async selector for fetching and rendering options for select.
 * It uses HtdAsyncPaginate component which is a tiny fork of react-select-async-paginate with quick bugfix for this lib
 * It depends on react-select and thus it accepts all props for this library.
 * For the sake of backward compatibility with option being a value returned by some presenter on the server -
 * when rendering an option it tries to render markup in text key which was required by select2
 * Nevertheless, one can arbitrary customize rendering options.
 * */
export class SaluteMultipleAsyncSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      selection: [],
    };

    if (this.state.selection.length === 0) {
      const requestDataFunction = _.get(API, props.intitialSelectionApiPath);
      this.fetchSelectedObjects(props.initialSelection, requestDataFunction).then(response =>
        this.setState({ selection: response.map(this._defaultMapResult) }),
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.propsValueDidUpdate();
    }
  }

  propsValueDidUpdate() {
    const { intitialSelectionApiPath, initialSelection } = this.props;
    if (this.state.selection.length === 0) {
      const requestDataFunction = _.get(API, intitialSelectionApiPath);
      this.fetchSelectedObjects(initialSelection, requestDataFunction).then(response =>
        this.setState({ selection: response.map(this._defaultMapResult) }),
      );
    }
  }
  async fetchSelectedObjects(ids, requestDataFunction) {
    const selectedObjects = [];
    for (let i = 0; i < ids.length; i++) {
      const rawObject = await requestDataFunction(ids[i]);
      selectedObjects.push(rawObject.data);
    }
    return selectedObjects;
  }

  blurField = e => {
    this.props.setFieldTouched && this.props.setFieldTouched(this.props.name);
    this.setState({
      isFocused: false,
    });
  };

  focusField = () => {
    this.setState({
      isFocused: true,
    });
  };

  blurField = () => {
    this.props.setFieldTouched && this.props.setFieldTouched(this.props.name);
    this.setState({
      isFocused: false,
    });
  };

  _defaultMapResult = item => ({
    value: item.id,
    id: item.id,
    label: item.text || item.header,
    header: item.header,
    details: item.details,
  });

  loadOptions = (search, loadedOptions) => {
    const defaultParams = {
      q: search,
      page: parseInt(loadedOptions.length / PAGE_SIZE) + 1,
    };
    const { apiPath, requestParams = {}, mapResult } = this.props;
    const params = _.merge(defaultParams, requestParams);
    const requestDataFunction = _.get(API, apiPath);
    const transformResponse = mapResult || this._defaultMapResult;

    return requestDataFunction(params).then(response => {
      return {
        options: response.data.results.map(transformResponse),
        hasMore: response.data.pagination.more,
      };
    });
  };

  setStyle = () => {
    let styles = {};

    if (this.props.disabled) {
      styles = {
        ...styles,
        backgroundColor: colors.disabled,
      };
    }

    if (this.state.isFocused) {
      styles = {
        ...styles,
        borderColor: colors.outline,
        borderWidth: 1,
        boxShadow: `0.005em 0.005em 0.005em ${colors.outline} inset, 0.005em 0.005em 0.005em ${colors.outline}`,
        outline: 0,
      };
    }

    if (this.state.selection && this.state.selection.details) {
      styles = {
        ...styles,
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      };
    }

    return styles;
  };

  renderOption = option => (
    <OptionContainer>
      <OptionHeader>{option.header || option.label}</OptionHeader>
      {option.details && <OptionDetails details={option.details} inDropdown />}
    </OptionContainer>
  );

  renderValue = option => <OptionHeader>{option.header || option.label}</OptionHeader>;

  onChange = value => {
    const { handleChange } = this.props;
    this.setState({ selection: value });
    handleChange(value === null ? null : value.map(value => value.id));
  };

  render() {
    const containerStyle = {
      background: 'white',
    };
    return (
      <div style={containerStyle}>
        <HtdAsyncPaginate
          multi
          key={this.props.apiPath}
          valueRenderer={this.renderValue}
          openOnFocus={true}
          autoBlur={true}
          optionRenderer={this.renderOption}
          onChange={this.onChange}
          value={this.state.selection}
          onFocus={this.focusField}
          onBlur={this.blurField}
          style={this.setStyle()}
          name={this.props.name}
          disabled={this.props.disabled || false}
          loadOptions={this.props.options || this.loadOptions}
          closeMenuOnSelect={false}
        />
      </div>
    );
  }
}

SaluteMultipleAsyncSelector.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  apiPath: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  mapResult: PropTypes.func,
  mapInitialSelectionFromApi: PropTypes.func,
  customOptionComponent: PropTypes.func,
  customValueComponent: PropTypes.func,
  requestParams: PropTypes.object,
  options: PropTypes.func,
  disabled: PropTypes.bool,
  setSelectedRecord: PropTypes.func,
  shouldTriggerSetSelectedRecordOnInit: PropTypes.bool,
};
/** + all props react-select accepts*/
