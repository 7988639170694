/** Lib */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
/** Components */
import { RadioButton } from './RadioButton'

const DEFAULT_LIST_CLASSES = 'list-unstyled row'
const DEFAULT_LIST_ITEM_CLASSES = 'col-sm-auto'


export const RadioButtonsGroup = (props) => {
  const {
    options,
    value,
    listClass = DEFAULT_LIST_CLASSES,
    itemClass = DEFAULT_LIST_ITEM_CLASSES,
  } = props

  const renderButtons = (radioOptions) => (
    radioOptions.map((option, index) => (
      <li key={`salute-radio-button-${index}`} className={itemClass}>
        <RadioButton
          {...option}
          value={option.value}
          name={props.name}
          checked={value.toString() === option.value.toString()}
          onChange={props.onChange}
        />
      </li>
    ))
  )

  return (
    <ul className={listClass}>
      {renderButtons(options)}
    </ul>
  )
}

RadioButtonsGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  listClass: PropTypes.string,
  itemClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
