import React, { PureComponent, useState } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import _ from 'lodash';
import cloneDeep from 'lodash/fp/cloneDeep';
import { UploadIcon } from '../../stateless_components/icons/UploadIcon';
import { ErrorMessage } from '../../stateless_components/inputs/elements/ErrorMessage';
import { ProgressBar } from '../../stateless_components/other/ProgressBar';
import { DownloadIcon } from '../../stateless_components/icons/DownloadIcon';
import { bytesToMb } from '../../services/fileSizeHelper';
import InputLabelHint from '../../salute_portal/components/fields/auxiliary/InputLabelHint';
import { SaluteModal } from '../../stateless_components/modals/SaluteModal';
import { DescriptionModalContent } from '../../stateless_components/inputs/elements/FileUploadInputs/DescriptionModalContent';
import {
  DescriptionLinkButton,
  Error,
  FilesFieldContainer,
  HintWrapper,
  Label,
  Required,
  Row,
  StyledAttachmentDescriptionRow,
  StyledAttachmentLink,
  StyledAttachmentNameSpan,
  StyledAttachmentRow,
  StyledAttachmentsTable,
  StyledMockUploadButton,
  StyledMockUploadButtonTitle,
  StyledRemoveAttachmentButton,
} from '../../stateless_components/inputs/elements/FileUploadInputs/AuxiliaryStyledComponents';

const AttachmentRow = props => {
  const {
    id,
    pathString,
    description,
    filename,
    disabled,
    removeAttachment,
    addDescriptionToAttachment,
  } = props;
  const descriptionIsPresent = description && description.length !== 0;
  const describeAction = descriptionIsPresent ? 'edit_description' : 'add_description';

  const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState(false);

  return (
    <>
      <StyledAttachmentRow descriptionIsPresent={descriptionIsPresent}>
        <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
        {!disabled && (
          <DescriptionLinkButton
            id={`${id}-description-button`}
            type="button"
            onClick={() => {
              setDescriptionModalIsOpen(true);
            }}
          >
            {I18n.t(`file_uploader.${describeAction}`)}
          </DescriptionLinkButton>
        )}
        <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
          <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
        </StyledAttachmentLink>
        {!disabled && (
          <StyledRemoveAttachmentButton
            id={`${id}-remove-button`}
            type="button"
            onClick={e => removeAttachment(e)}
          >
            {I18n.t('file_uploader.delete_file')}
          </StyledRemoveAttachmentButton>
        )}
      </StyledAttachmentRow>
      {descriptionIsPresent && (
        <StyledAttachmentDescriptionRow>{description}</StyledAttachmentDescriptionRow>
      )}
      <SaluteModal
        title={I18n.t(`file_uploader.${describeAction}`)}
        isOpen={descriptionModalIsOpen}
        onCloseClick={() => {
          setDescriptionModalIsOpen(false);
        }}
      >
        <DescriptionModalContent
          id={id}
          description={description}
          closeModal={() => {
            setDescriptionModalIsOpen(false);
          }}
          addDescriptionToAttachment={addDescriptionToAttachment}
        />
      </SaluteModal>
    </>
  );
};

export default class FilesField extends PureComponent {
  // This component is used in haml files so it does not use Formik.
  // The logic is based on the FilesUploadInput component from the
  // stateless_components/inputs/elements/FileUploadInputs directory.
  constructor(props) {
    super(props);
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
      value: props.value || [],
    };
  }

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t(
        'file_uploader.errors.too_large_file_unit',
      )}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = signedUrl => {
    const { value } = this.state;
    const downloadPath = { url: signedUrl.signedUrl.split('?')[0] };

    const newValue = _.union(value, [downloadPath]);

    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
      value: newValue,
    });
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const { value: initialCollection } = this.state;

    return initialCollection.map((attachment, valueIndex) => {
      const { url: pathString, description } = attachment;
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));

      return { pathString, filename, description, valueIndex };
    });
  }

  renderAttachments(fieldId) {
    const { field, disabled, sortPredicate, customUploadPath } = this.props;
    const {
      value,
      progressValue,
      showProgressBar,
      errorOccurred,
      error,
      showUploadButton,
      uploadPending,
      uploadingErrorOccurred,
    } = this.state;

    let attachments = this.prepareAttachmentsData();
    if (sortPredicate) attachments = attachments.sort(sortPredicate);

    return (
      <FilesFieldContainer>
        <StyledAttachmentsTable>
          {attachments.map(({ pathString, filename, description, valueIndex }) => (
            <>
              <AttachmentRow
                pathString={pathString}
                description={description}
                filename={filename}
                id={`${fieldId}[${valueIndex}]`}
                name={`${field}[${valueIndex}]`}
                key={pathString}
                disabled={disabled}
                removeAttachment={e => {
                  e.preventDefault();
                  const newValue = _.filter(value, el => el.url !== pathString);
                  this.setState({ value: newValue });
                }}
                addDescriptionToAttachment={async _description => {
                  const newValue = cloneDeep(value);
                  newValue[valueIndex] = { url: pathString, description: _description };
                  this.setState({ value: newValue });
                }}
              />
              {this.renderHiddenInput(pathString, description)}
            </>
          ))}
        </StyledAttachmentsTable>
        {!disabled && (
          <>
            <ReactS3Uploader
              id={fieldId}
              signingUrl="/file_upload"
              signingUrlMethod="GET"
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={signedUrl => this.onUploadSuccess(signedUrl)}
              autoUpload
              contentDisposition="attachment"
              scrubFilename={filename => encodeURIComponent(filename.trim())}
              hidden
              inputRef={this.setInputRef}
              signingUrlQueryParams={customUploadPath ? { customUploadPath } : {}}
              multiple
            />
            <ProgressBar
              progressValue={progressValue}
              hidden={!showProgressBar}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={errorOccurred}
            />
            <ErrorMessage hidden={!errorOccurred}>{error}</ErrorMessage>
            <label htmlFor={fieldId} hidden={!showUploadButton}>
              <StyledMockUploadButton>
                <UploadIcon />
                <StyledMockUploadButtonTitle>
                  {I18n.t('file_uploader.upload_file')}
                </StyledMockUploadButtonTitle>
              </StyledMockUploadButton>
            </label>
            <ProgressBar
              progressValue={progressValue}
              hidden={!uploadPending}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={uploadingErrorOccurred}
            />
          </>
        )}
      </FilesFieldContainer>
    );
  }

  renderHiddenInput(pathString, description) {
    const { hiddenUrlInputName, hiddenDescriptionInputName } = this.props;
    return (
      <>
        <input type="hidden" name={hiddenUrlInputName} value={pathString} />
        <input type="hidden" name={hiddenDescriptionInputName} value={description} />
      </>
    );
  }

  render() {
    const { label, field: name, required, hint, error, resourceName, id } = this.props;
    const inputId = id || `${resourceName}-${name}`;
    return (
      <>
        <Row className="w-100 row mx-0">
          <Label
            name={name}
            className="col-lg-3 col-sm-3 col-md-4 col-xs-12 col-form-label"
            inputId={inputId}
          >
            {label}
            {hint && (
              <HintWrapper>
                <InputLabelHint hint={hint} />
              </HintWrapper>
            )}
            {required && <Required>*</Required>}
          </Label>
          <div className="col-lg-9 col-md-8 col-xs-12">{this.renderAttachments(inputId)}</div>
        </Row>
        <Row className="w-100 row mx-0">
          <div className="col-12 col-sm-4 col-md-4 col-lg-3 pl-0" />
          <div className="col-12 col-sm-8 col-md-8 col-lg-4 pl-2 pr-0">
            {error && <Error className="w-100 mt-1">{error}</Error>}
          </div>
        </Row>
      </>
    );
  }
}
