/* eslint-disable no-undef,no-use-before-define,no-plusplus */
import moment from 'moment-timezone';

// One should not use dates without parsing them with getConvertedMoment
// function - this way we want to avoid time zone handling issues.

const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
const SERVER_DATE_RANGE_PART_FORMAT = 'L';
const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';
const SERVER_DATE_TIME_FORMAT_2 = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ';

const WORDY_FORMAT = 'll';
const DATE_FORMAT = 'L';
const DATE_TIME_FORMAT = 'L LTS';
const TIME_FORMAT = 'hh:mm A';
const TIME_SECONDS_FORMAT = 'hh:mm:ss A';

const formatMatches = (inputDate, formats) => moment(inputDate, formats, true).isValid();

// allowTimeZoneInsensitive flag is here to ensure that one is perfectly
// aware that he should not rely on nor display time from a time zone
// insensitive date.
export const getConvertedMoment = (
  inputDate,
  allowTimeZoneInsensitive = false,
  inFixedTimeZone = false,
) => {
  if (!inputDate) {
    return undefined;
  }
  let whitelist = [SERVER_DATE_TIME_FORMAT, SERVER_DATE_TIME_FORMAT_2];
  if (allowTimeZoneInsensitive) {
    whitelist = [...whitelist, ...[SERVER_DATE_FORMAT, SERVER_DATE_RANGE_PART_FORMAT]];
  }
  const timeZone = Cookies.get('user.time_zone');

  for (let i = 0; i < whitelist.length; i++) {
    const format = whitelist[i];
    if (formatMatches(inputDate, format)) {
      if (inFixedTimeZone) {
        return moment.tz(inputDate, format, inFixedTimeZone);
      }
      return moment.tz(inputDate, format, timeZone);
    }
  }
  throw new Error('Tried to convert a date with the unknown/inappropriate format.');
};

export const getUtcMomentIgnoringTime = inputDate => {
  const whitelist = [SERVER_DATE_FORMAT, SERVER_DATE_RANGE_PART_FORMAT, WORDY_FORMAT, DATE_FORMAT];
  for (let i = 0; i < whitelist.length; i += 1) {
    const format = whitelist[i];
    if (formatMatches(inputDate, format)) {
      return moment.utc(inputDate);
    }
  }
  throw new Error('Tried to convert a date with the unknown/inappropriate format.');
};

export const getUtcMoment = (inputDate, allowTimeZoneInsensitive = false) => {
  const convertedMoment = getConvertedMoment(inputDate, allowTimeZoneInsensitive);
  if (!convertedMoment) {
    return undefined;
  }
  return convertedMoment.tz('UTC');
};

const getFormattedDate = (
  inputDate,
  outputFormat,
  allowTimeZoneInsensitive = false,
  inFixedTimezone = false,
) => {
  const convertedMoment = getConvertedMoment(inputDate, allowTimeZoneInsensitive);
  if (!convertedMoment) {
    return undefined;
  }
  if (inFixedTimezone) {
    return convertedMoment.tz(inFixedTimezone).format(outputFormat);
  }
  return convertedMoment.format(outputFormat);
};

export const newMoment = () => {
  const timeZone = Cookies.get('user.time_zone');
  return moment().tz(timeZone);
};

export const newUtcMoment = () => {
  return moment().tz('UTC');
};

export const currentDate = () => momentFormatter.serverDate(newMoment());

export const currentUtcDate = () => momentFormatter.serverDate(newUtcMoment());

export const currentDateTime = () => momentFormatter.serverDateTime(newMoment());

export const currentUtcDateTime = () => momentFormatter.serverDateTime(newUtcMoment());

export const currentTime = () => currentDateTime();

export const currentUtcTime = () => currentUtcDateTime();

export const date = (year, month, day) =>
  momentFormatter.serverDate(newMoment().set({ year, month, day }));

export const utcDate = (year, month, day) =>
  momentFormatter.serverDate(newUtcMoment().set({ year, month, day }));

export const dateTime = (year, month, day, hour = 12, minute = 0, second = 0) =>
  momentFormatter.serverDateTime(newMoment().set({ hour, minute, second }));

export const utcDateTime = (year, month, day, hour = 12, minute = 0, second = 0) =>
  momentFormatter.serverDateTime(newUtcMoment().set({ hour, minute, second }));

export const time = (hour, minute = 0, second = 0) =>
  momentFormatter.serverDateTime(newMoment().set({ hour, minute, second }));

export const utcTime = (hour, minute = 0, second = 0) =>
  momentFormatter.serverDateTime(newUtcMoment().set({ hour, minute, second }));

export const dateFormatValidator = {
  isServerDate: (inputDate, allowFalsy = true) =>
    !inputDate ? allowFalsy : formatMatches(inputDate, SERVER_DATE_FORMAT),
  isServerDateTime: (inputDate, allowFalsy = true) =>
    !inputDate
      ? allowFalsy
      : formatMatches(inputDate, SERVER_DATE_TIME_FORMAT) ||
        formatMatches(inputDate, SERVER_DATE_TIME_FORMAT_2),
  isDate: (inputDate, allowFalsy = true) =>
    !inputDate ? allowFalsy : formatMatches(inputDate, DATE_FORMAT),
};

export const dateFormatter = {
  serverDate: inputDate => getFormattedDate(inputDate, SERVER_DATE_FORMAT, true),
  serverDateRangePart: inputDate =>
    getFormattedDate(inputDate, SERVER_DATE_RANGE_PART_FORMAT, true),
  serverDateTime: inputDate => getFormattedDate(inputDate, SERVER_DATE_TIME_FORMAT),
  wordy: inputDate => getFormattedDate(inputDate, WORDY_FORMAT, true),
  date: inputDate => getFormattedDate(inputDate, DATE_FORMAT, true),
  dateTime: inputDate => getFormattedDate(inputDate, DATE_TIME_FORMAT),
  dateTimeInFixedTimeZone: (inputDate, fixedTimeZone) =>
    getFormattedDate(inputDate, DATE_TIME_FORMAT, false, fixedTimeZone),
  time: inputDate => getFormattedDate(inputDate, TIME_FORMAT),
  timeInFixedTimeZone: (inputDate, fixedTimeZone) =>
    getFormattedDate(inputDate, TIME_FORMAT, true, fixedTimeZone),
  timeSeconds: inputDate => getFormattedDate(inputDate, TIME_SECONDS_FORMAT),
  fromNow: inputDate => getConvertedMoment(inputDate, false).fromNow(),
};

// Please note that this helper do not guarantee time zone compliance
// and should be used with extra caution.
export const momentFormatter = {
  serverDate: m => m.format(SERVER_DATE_FORMAT),
  serverDateRangePart: m => m.format(SERVER_DATE_RANGE_PART_FORMAT),
  serverDateTime: m => m.format(SERVER_DATE_TIME_FORMAT),
  wordy: m => m.format(WORDY_FORMAT),
  date: m => m.format(DATE_FORMAT),
  dateTime: m => m.format(DATE_TIME_FORMAT),
  time: m => m.format(TIME_FORMAT),
  timeSeconds: m => m.format(TIME_SECONDS_FORMAT),
};
