import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteStaticSelect extends Component {
  componentDidMount() {
    const options = {
      allowClear: !$(this.selectRef).prop('required'),
      placeholder: $(this.selectRef).data('placeholder') || 'Please Select',
    };

    $(this.selectRef)
      .select2(options)
      .on('select2:select', this.onChange)
      .on('select2:unselect', this.onClear);
  }

  onChange = event => {
    const value = this.selectRef.value;
    this.props.onChange && this.props.onChange(value);
  };

  onClear = event => {
    this.props.onChange(null);
  };

  render() {
    const { resolveInputName, index, options, answer, disabled } = this.props;

    return (
      <select
        disabled={disabled}
        name={resolveInputName(index, 'answer')}
        defaultValue={answer}
        value={answer}
        ref={ref => {
          this.selectRef = ref;
        }}
      >
        <option />
        {options.map(option => (
          <option key={option.text + option.id} value={option.id}>
            {option.text}
          </option>
        ))}
      </select>
    );
  }
}

SaluteStaticSelect.defaultProps = {
  onChange: () => {},
  answer: '',
};

SaluteStaticSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  answer: PropTypes.string,
};

export default SaluteStaticSelect;
