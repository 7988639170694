import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
/** Input components */
import SaluteDynamicSelect from '../Inputs/SaluteDynamicSelect';
import SaluteNumericInput from '../Inputs/SaluteNumericInput';
import SaluteRangeSelect from '../Inputs/SaluteRangeSelect';
import SaluteStaticSelect from '../Inputs/SaluteStaticSelect';
import SaluteTextArea from '../Inputs/SaluteTextArea';
import SaluteYesNoNaInput from '../Inputs/SaluteYesNoNaInput';
/** Buttons */
import { TimeField } from '../../../salute_portal/components/fields/TimeField';
import fieldDisplayModes from '../../../salute_portal/constants/fieldDisplayModes';
import { isValidHttpLink } from '../../../salute_portal/services/linksUtils';
import { DatePicker } from '../../../stateless_components/inputs/elements/DateInputs/DatePicker';
import { DateTimePicker } from '../../../stateless_components/inputs/elements/DateInputs/DateTimePicker';
import CommentFileBtn from '../Buttons/CommentFileBtn';
import FindingsBtn from '../Buttons/FindingsBtn';

export const StyledLink = withStyles({
  root: {
    '&&': { color: '#007bff' },
  },
})(Link);

const ChecklistItemRow = ({
  index,
  resolveInputName,
  setAnswer,
  saveCommentAndFile,
  commentBtnLabel,
  findingProps,
  disabled,
  required,
  error,
  canOpenCommentFileModal,
  commentFileModalShouldOpenForFindingDefinitionId,
  canOpenFindingsModal,
  findingsModalShouldOpenForFindingDefinitionId,
  item,
  requiresExplanationOnNo,
  alwaysAllowFindings,
  item: {
    id,
    answer_type,
    preferred_order,
    answer_config,
    question,
    answer,
    findings_count,
    attachments,
    comment,
    links,
    inspection_finding_definition_id,
    website_info,
    allow_findings,
  },
}) => {
  const explanationRequired = answerValue => {
    const noAnswerCode = 2;
    return parseInt(answerValue, 10) === noAnswerCode && answer_type === 'yes_no_na';
  };

  const allowFindings = alwaysAllowFindings || allow_findings;

  const renderInputComponent = () => {
    if (answer_type === 'free_text') {
      return (
        <SaluteTextArea
          config={answer_config}
          disabled={disabled}
          answer={answer}
          index={index}
          resolveInputName={resolveInputName}
          onChange={value => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'integer') {
      return (
        <SaluteNumericInput
          index={index}
          resolveInputName={resolveInputName}
          config={answer_config}
          disabled={disabled}
          answer={answer}
          onChange={value => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'range_select') {
      return (
        <SaluteRangeSelect
          answer={answer}
          config={answer_config}
          disabled={disabled}
          index={index}
          resolveInputName={resolveInputName}
          onChange={value => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'select' && answer_config.options_source === 'people') {
      return (
        <SaluteDynamicSelect
          answer={answer}
          index={index}
          config={answer_config}
          resolveInputName={resolveInputName}
          onChange={value => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'select') {
      return (
        <SaluteStaticSelect
          index={index}
          answer={answer}
          config={answer_config}
          resolveInputName={resolveInputName}
          disabled={disabled}
          options={answer_config.options_for_select.map(option => ({
            id: option,
            text: option,
          }))}
          onChange={value => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (
      answer_type === 'date_and_time' &&
      answer_config.date_time_option === 'date_and_time'
    ) {
      return (
        <DateTimePicker
          disabled={disabled}
          setFieldTouched={() => {}}
          date={answer}
          name={resolveInputName(index, 'answer')}
          setFieldValue={(fieldName, value) => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'date_and_time' && answer_config.date_time_option === 'date') {
      return (
        <DatePicker
          disabled={disabled}
          setFieldTouched={() => {}}
          date={answer}
          name={resolveInputName(index, 'answer')}
          setFieldValue={(fieldName, value) => {
            setAnswer(id, value);
          }}
        />
      );
    } else if (answer_type === 'date_and_time' && answer_config.date_time_option === 'time') {
      return (
        <TimeField
          field={{ value: answer, name: resolveInputName(index, 'answer') }}
          form={{ setFieldValue: () => {}, setFieldTouched: () => {} }}
          disabled={disabled}
          onChange={e => {
            setAnswer(id, e);
          }}
          displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
          bottomMargin={false}
        />
      );
    }
    return (
      <SaluteYesNoNaInput
        answer={answer}
        index={index}
        config={answer_config}
        disabled={disabled}
        resolveInputName={resolveInputName}
        onChange={value => {
          setAnswer(id, value);
        }}
      />
    );
  };

  const renderQuestion = (questionContent, websiteInfo) => {
    if (websiteInfo && isValidHttpLink(websiteInfo)) {
      return (
        <StyledLink href={websiteInfo} target="_blank">
          {questionContent}
        </StyledLink>
      );
    }
    return questionContent;
  };

  return (
    <div
      className="row row-no-margin checklist-question-row"
      data-question-definition-id={inspection_finding_definition_id}
    >
      <div className={`col question-col ${error ? 'question-col-with-error' : ''}`}>
        <div className="container">
          <div className="row">
            <span className={required ? 'with-asterisk' : ''}>
              {renderQuestion(question, website_info)}
            </span>
          </div>
          {error && <div className="row answer-error">{error}</div>}
        </div>
      </div>
      <div className="col answer-col">{renderInputComponent()}</div>
      <input type="hidden" name={resolveInputName(index, 'id')} value={id} />
      <input type="hidden" name={resolveInputName(index, 'question')} value={question} />
      <input
        type="hidden"
        name={resolveInputName(index, 'preferred_order')}
        value={preferred_order}
      />
      <div className="col col-buttons">
        <div className="row">
          <div className="col comment-col">
            <CommentFileBtn
              attachments={attachments}
              comment={comment}
              links={links}
              saveCommentAndFile={saveCommentAndFile}
              questionID={id}
              disabled={disabled}
              commentBtnLabel={commentBtnLabel}
              canOpenModal={canOpenCommentFileModal}
              findingDefinitionId={inspection_finding_definition_id}
              shouldOpenModal={
                inspection_finding_definition_id &&
                commentFileModalShouldOpenForFindingDefinitionId ===
                  inspection_finding_definition_id
              }
              explanationRequired={requiresExplanationOnNo ? explanationRequired(answer) : false}
            />
          </div>
          <div
            className={`col issue-col ${allowFindings || findings_count > 0 ? '' : 'hidden-col'}`}
          >
            <FindingsBtn
              findingsCount={findings_count}
              findingProps={findingProps}
              relatedEntity={{ ...item }}
              disabled={!allowFindings}
              findingDefinitionId={inspection_finding_definition_id}
              canOpenModal={canOpenFindingsModal}
              shouldOpenModal={
                inspection_finding_definition_id &&
                findingsModalShouldOpenForFindingDefinitionId === inspection_finding_definition_id
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ChecklistItemRow.propTypes = {
  index: PropTypes.number.isRequired,
  resolveInputName: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  commentBtnLabel: PropTypes.string.isRequired,
  requiresExplanationOnNo: PropTypes.bool.isRequired,
  alwaysAllowFindings: PropTypes.bool.isRequired,
};

export default ChecklistItemRow;
