import { take, put, race } from 'redux-saga/effects'
import {
  confirmationGuardActionCreators,
  confirmationGuardActionTypes,
} from '../../redux/views/Modals/confirmationGuardRedux'
import { modalSetupActionCreators, AVAILABLE_MODALS } from '../../redux/views/Modals/setupRedux'

export function* confirmationGuardSaga(confirmationMessage, implicationsMessage) {
  yield put(modalSetupActionCreators.openModal(AVAILABLE_MODALS.CONFIRMATION_GUARD))
  yield put(confirmationGuardActionCreators.setupConfirmationInfo(confirmationMessage, implicationsMessage))

  const { confirmed } = yield race({
    confirmed: take(confirmationGuardActionTypes.ACCEPT),
    rejected: take(confirmationGuardActionTypes.REJECT),
  })

  yield put(modalSetupActionCreators.closeModal())
  yield put(confirmationGuardActionCreators.resetConfirmationInfo())

  return !!confirmed
}
