/** Lib */
import React, { Component } from 'react'
import styled from 'styled-components'
/** Style */
import { colors } from '../../../../Style/colors'

export const OptionContainer = styled.div`
  background: transparent;
  &:hover {
    background: transparent;
  }
`
