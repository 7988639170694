export const httpRegex = /^https?:\/\//;

export const isValidHttpLink = link => {
  try {
    // eslint-disable-next-line no-new
    new URL(link);
  } catch (_) {
    return false;
  }
  // Validate values allowed by the first check to reject ones like e.g. 'javascript:void(0)'
  return link.match(httpRegex);
};

export const httpPrefixedLink = link => {
  if (!link.match(httpRegex)) return `https://${link}`;
  return link;
};

export const validHttpPrefixedLink = link => {
  const result = httpPrefixedLink(link);
  if (isValidHttpLink(result)) return result;
  return null;
};

export const httpUnprefixedLink = link => {
  if (link.match(httpRegex)) {
    return link.replace(httpRegex, '');
  }
  return link;
};
