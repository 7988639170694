import get from 'lodash/get';
import concat from 'lodash/concat'

export const getFormikErrors = (name, touched, errors) =>
  touched && touched[name] && errors && errors[name];

export const parseErrors = validationErrors => {
  return validationErrors.inner.reduce((errorsAccumulated, currentError) => {
    return { ...errorsAccumulated, [currentError.path]: currentError.errors[0] };
  }, {});
};

export const transformNaNValue = value => (Number.isNaN(value) ? undefined : value);

export const validateForm = (values, validationSchema) => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return parseErrors(error);
  }
};

export const defaultShouldUpdate = (newProps, prevProps) => {
  if (newProps.formik.isSubmitting !== prevProps.formik.isSubmitting) {
    return true;
  }
  const relevantAttributes = ['name', 'disabled', 'error', 'hidden', 'invisible'];
  const relevantAttributeChanged = relevantAttributes.some(
    attribute => newProps[attribute] !== prevProps[attribute],
  );
  if (relevantAttributeChanged) {
    return true;
  }
  const { name } = newProps;
  const relevantCollections = ['values', 'errors', 'touched'];
  const relevantCollectionElementChanged = relevantCollections.some(
    collection =>
      get(newProps.formik[collection], name) !== get(prevProps.formik[collection], name),
  );
  return relevantCollectionElementChanged;
};

export const produceShouldUpdate = (
  additionalAttributes = [],
  additionalCollections = [],
  additionalValuesNames = [],
) => (newProps, prevProps) => {
  if (newProps.formik.isSubmitting !== prevProps.formik.isSubmitting) {
    return true;
  }
  const relevantAttributes = concat(
    ['name', 'disabled', 'error', 'hidden', 'invisible'],
    additionalAttributes,
  );
  const relevantAttributeChanged = relevantAttributes.some(
    attribute => newProps[attribute] !== prevProps[attribute],
  );
  if (relevantAttributeChanged) {
    return true;
  }
  const valuesNames = [newProps.name, ...additionalValuesNames];
  const relevantCollections = concat(['values', 'errors', 'touched'], additionalCollections);
  const relevantCollectionElementChanged = valuesNames.some(name =>
    relevantCollections.some(
      collection =>
        get(newProps.formik[collection], name) !== get(prevProps.formik[collection], name),
    ),
  );
  return relevantCollectionElementChanged;
};
