/** Lib */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
/** Components */
import InputLabelHint from '../../../components/InputLabelHint'
/** Style */
import { colors } from '../../../Style/colors'
import { fontSize } from '../../../Style/typography'
import { inputMetrics } from '../../../Style/metrics'


const Label = styled.label.attrs({ htmlFor: (props) => props.name })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  margin-bottom: 0;
  line-height: 36px;
  ${(props) => props.required &&
  `::after {
    color: ${colors.required};
    font-size: ${fontSize.textSm};
    margin-left: ${inputMetrics.labelElementsOffset};
    margin-right: ${inputMetrics.labelElementsOffset};
    content: '*';
    display: inline;
    }`
}
`

const LabelHintContainer = styled.span`
  margin-left: ${inputMetrics.labelElementsOffset};
  margin-right: ${inputMetrics.labelElementsOffset};
`

export const InputLabel = (props) => {
  const {
    label, name, required = false, hint,
  } = props
  return (
    <span>
      <Label required={required} name={name}>{label}</Label>
      {hint &&
      <LabelHintContainer>
        <InputLabelHint hint={hint} />
      </LabelHintContainer>
      }
    </span>
  )
}

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
}
