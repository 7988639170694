import { colors } from './colors'
import { css } from 'styled-components'

export const inputStyles = {
  focused: css`
    &:focus {
      border-color: ${colors.outline};
      border-width: 1;
      box-shadow: 0.005em 0.005em 0.005em ${colors.outline} inset, 0.005em 0.005em 0.005em ${colors.outline};
      outline: 0;
    }
  `,
}
