import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteYesNoNaInput extends Component {
  onClick = value => {
    if (this.props.disabled) return false;
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { index, resolveInputName, disabled } = this.props;
    const answer = `${this.props.answer}`;
    return (
      <div className="row row-yes-no row-no-margin">
        <div
          className="col yes-no-container btn-group-toggle"
          data-toggle={disabled ? '' : 'buttons'}
        >
          <button
            type="button"
            disabled={disabled}
            onClick={() => this.onClick(1)}
            className={`btn btn-sm yes-no-btn yes-btn ${answer === '1' ? 'active' : ''}`}
          >
            <input
              autoComplete="off"
              className="yes-no-checkbox"
              type="radio"
              name={resolveInputName(index, 'answer')}
              defaultValue="1"
              disabled={disabled}
              defaultChecked={answer === '1'}
            />
            <span className="glyphicon glyphicon-ok" />
          </button>
          <button
            type="button"
            disabled={disabled}
            onClick={() => this.onClick(2)}
            className={`btn btn-sm yes-no-btn no-btn ${answer === '2' ? 'active' : ''}`}
          >
            <input
              autoComplete="off"
              className="yes-no-checkbox"
              type="radio"
              name={resolveInputName(index, 'answer')}
              defaultValue="2"
              disabled={disabled}
              defaultChecked={answer === '2'}
            />
            <span className="glyphicon glyphicon-remove" />
          </button>
          <button
            type="button"
            onClick={() => this.onClick(3)}
            className={`btn btn-sm yes-no-btn NA-btn ${answer === '3' ? 'active' : ''}`}
            disabled={disabled}
          >
            <input
              autoComplete="off"
              className="yes-no-checkbox"
              type="radio"
              name={resolveInputName(index, 'answer')}
              defaultValue="3"
              disabled={disabled}
              defaultChecked={answer === '3'}
            />
            <span className="txt">N/A</span>
          </button>
        </div>
      </div>
    );
  }
}

SaluteYesNoNaInput.defaultProps = {
  onChange: () => {},
  answer: '',
};

SaluteYesNoNaInput.propTypes = {
  onChange: PropTypes.func,
  resolveInputName: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default SaluteYesNoNaInput;
