/** Lib */
import 'react-dates/initialize';
import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import { TextInput } from '../TextInput';
/** Styles */
import './custom_styles.css';
/** Config */
import {
  dateFormatter,
  getConvertedMoment,
  dateFormatValidator,
  momentFormatter,
} from '../../../../services/dateFormatUtils';

const MOBILE_TABLET_WIDTH = 1024;

export class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  setValue = date => {
    let newDate = date;
    if (newDate) {
      newDate = momentFormatter.serverDate(newDate);
    }
    this.props.setFieldValue(this.props.name, newDate);
  };

  handleFocusChanged = ({ focused }) => {
    if (!focused) {
      this.props.setFieldTouched(this.props.name);
    }

    this.setState({ focused });
  };

  render() {
    const { date, isOutsideRange = false, disabled = false } = this.props;
    const width = window.innerWidth;
    if (!dateFormatValidator.isServerDate(date)) {
      throw new Error(
        'Date provided to DatePicker component should match the format YYYY-MM-DD ' +
          '(and hence should be time zone insensitive).',
      );
    }

    // SingleDatePicker seems difficult to style, this solution makes things easier
    if (disabled) {
      return (
        <TextInput
          value={date && dateFormatter.date(date)}
          disabled={disabled}
          onChange={() => {}}
        />
      );
    } else {
      return (
        <SingleDatePicker
          date={getConvertedMoment(date, true)}
          onDateChange={this.setValue}
          focused={this.state.focused}
          block
          disabled={disabled}
          isOutsideRange={() => isOutsideRange}
          onFocusChange={this.handleFocusChanged}
          id="single-date-picker"
          numberOfMonths={width >= MOBILE_TABLET_WIDTH ? 2 : 1}
        />
      );
    }
  }
}

DatePicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  date: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
