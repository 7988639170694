/** Lib */
import React from 'react'
import styled from 'styled-components'
import { Button } from '../../buttons/Button'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

const Title = ({ close, className }) => (
  <div className={className}>
    <h3 className="mb-0 pl-1">
        Filter
      <span className="float-right cursor-pointer" onClick={close}>
        <span className="icon icon-close"></span>
      </span>
    </h3>
  </div>
)

const SubTitle = ({ label, className }) => (
  <div className={className}>
    <span className="icon icon-search_red"></span>
    {label}
  </div>
)

// One pixel less them body because of border.
const FilterPopoverHeader = styled(PopoverHeader)`
    width: 274px;
`
const FilterPopoverBody = styled(PopoverBody)`
    width: 274px;
`

const PopoverTitle = styled(Title)`
    margin-bottom: 0;
    font-size: 1rem!important;
    color: inherit;
    font-weight: 600;
`


const PopoverSubTitle = styled(SubTitle)`
    font-weight: 400;
    height: 30px;
    line-height: 22px;
    font-size: 14px;
    margin-top: 1rem;
`


export const FilterPopover = ({
  content, className, close, submit, clear, popoverOpen, target, filterName, value,
}) => (
  <Popover placement="bottom" isOpen={popoverOpen} target={target} toggle={close}>
    <FilterPopoverHeader>
      <PopoverTitle close={close} />
    </FilterPopoverHeader>

    <FilterPopoverBody>
      <div>
        <PopoverSubTitle label={filterName} />
      </div>
      <div>
        {content}
      </div>
      <div className="text-right mt-4">
        { value &&
          <Button onClick={() => { clear(); close() }} className="btn btn-sm btn-link">Clear Filter</Button>
        }
      </div>
    </FilterPopoverBody>
  </Popover>
)
