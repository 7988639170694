import React from 'react';
import { dateFormatter } from '../../../services/dateFormatUtils';

const ResponsiblePersonFullName = ({ included, finding }) => {
  const person = included.find(
    row => row.id === `${finding.responsible_person_id}` && row.type === 'person',
  );

  return (person && person.attributes.full_name) || 'N/A';
};

const ExpiredDueDate = ({ finding }) => {
  if (!finding.due_date) {
    return 'N/A';
  }
  if (finding.is_overdue && !finding.is_corrected) {
    return (
      <span className="show-danger">
        <span className="saluteicons saluteicons-warning mr-2" />
        {dateFormatter.date(finding.due_date)}
      </span>
    );
  }
  return dateFormatter.date(finding.due_date);
};

const CorrectedDate = ({ finding }) => {
  if (finding.is_corrected) {
    return (
      <div className="show-success corrected_date">
        <span className="saluteicons saluteicons-check mr-1" />
        {dateFormatter.date(finding.corrected_date)}
      </div>
    );
  }
  return 'N/A';
};

export const FindingRow = ({ finding, included, onClick }) => {
  return (
    <li className="finding" onClick={onClick}>
      <div className="container">
        <div className="row">
          <div className="col-11 list_header">
            <div className="title_with_badge">
              <div className="row">
                {finding.is_overdue && !finding.is_corrected && (
                  <div className="col icon-col mr-2">
                    <span className="saluteicons show-danger saluteicons-warning" />
                  </div>
                )}
                <div className="col pl-0 title-col">{finding.finding_short}</div>
              </div>
            </div>
          </div>
          <div className="col-1 list_edit">
            <div className="row m-0">
              <span className="col p-0 saluteicons saluteicons-pencil mr-2" />
              <div className="col p-0">Edit</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {finding.finding}{' '}
            {finding.regulatory_description && (
              <>
                <span className="text-muted">Regulatory: </span>
                {finding.regulatory_description}
              </>
            )}
          </div>
        </div>
        <div className="row boxed_info">
          <div className="col-3">
            <p>Follow-Up Required</p>
            {finding.follow_up_required ? I18n.t(`${finding.follow_up_required}`) : 'N/A'}
          </div>
          <div className="col-3">
            <p>Responsible Person</p>
            <ResponsiblePersonFullName finding={finding} included={included} />
          </div>
          <div className="col-3">
            <p>Due Date</p>
            <ExpiredDueDate finding={finding} />
          </div>
          <div className="col-3">
            <p>Corrected Date</p>
            <CorrectedDate finding={finding} />
          </div>
        </div>
      </div>
    </li>
  );
};
